import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useModal } from "@Roo/Common/Modals/hooks/useModal";
import { BlockedModal, PendingModal } from "@RooBeta/components";

import { useAccountStatus } from "./useAccountStatus";
import { useRequiredAuthorizedUser } from "./useAuthorizedUser";

export const useAccountStatusModal = () => {
  const { isPendingActivation, isBlocked } = useAccountStatus();
  const { vetId } = useRequiredAuthorizedUser();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const [showModalType, setShowModalType] = useState<"blocked" | "pendingActivation" | null>(null);

  useEffect(() => {
    if (isBlocked) {
      openModal(BlockedModal, {
        isVet: !!vetId,
      });
      setShowModalType("blocked");
    } else if (isPendingActivation) {
      openModal(PendingModal, {
        isVet: !!vetId,
      });
      setShowModalType("pendingActivation");
    }
  }, [isBlocked, isPendingActivation, openModal, vetId]);

  useEffect(() => {
    return () => closeModal();
  }, [location.pathname, closeModal]);

  return {
    showModalType,
  };
};
