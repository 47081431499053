import { rooFetch } from "./common/rooFetch";

export const fetchTechShiftDetails = async ({
  shiftGroupId,
  techId,
  isGetRatingDetails,
  latitude,
  longitude,
}: {
  shiftGroupId: string | number;
  techId?: string | number;
  isGetRatingDetails?: number;
  latitude?: string | number;
  longitude?: string | number;
}): Promise<any> => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/hospital/tech_shift/getShiftDetailsById?shiftGroupId=${shiftGroupId}&techId=${techId}&isGetRatingDetails=${isGetRatingDetails}&latitude=${latitude}&longitude=${longitude}`
  );

  const { data } = await response.json();
  return data.data?.[0] || {};
};

export const fetchHospitalAvailableTechShifts = async (
  hospitalId: string | number,
  techId: string | number,
  latitude: string | number,
  longitude: string | number
): Promise<any> => {
  const response = await rooFetch(
    `${
      window.RooConfig.API_URL
    }api/tech/shift/getShiftsByHospitalId?hospitalId=${hospitalId}&techId=${techId}&maxTier=${localStorage.getItem(
      "tier"
    )}&isExcludeBlockedShifts=true&latitude=${latitude}&longitude=${longitude}`
  );

  const { data } = await response.json();
  return data?.data || [];
};

export const requestTechShift = async (params: any): Promise<any> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/tech/shift/requestShift`, {
    method: "POST",
    body: JSON.stringify(params),
  });

  const resData = await response.json();
  return resData?.data || {};
};
