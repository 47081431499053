import { Link, SmallText } from "@RooUI";
import React from "react";

import { HStack, RooIcon } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";
import { HospitalRatings } from "@RooBeta/types";

type HospitalRatingProps = {
  rating: HospitalRatings;
};

export const HospitalRating = ({ rating: { average, summary } }: HospitalRatingProps) => {
  const { setTab } = useShiftDetailsStore();

  return (
    <HStack $alignItems="center" $gap="xs">
      {average ? (
        <>
          <HStack>
            {[...Array(5)].map((_, index) => (
              <RooIcon
                key={index}
                icon={index < Math.round(parseFloat(average)) ? "star_filled" : "star"}
              />
            ))}
          </HStack>
          <SmallText>{parseFloat(average).toFixed(1)}</SmallText>
        </>
      ) : (
        <HStack>
          {[...Array(5)].map((_, index) => (
            <RooIcon key={index} icon="star" />
          ))}
        </HStack>
      )}
      <SmallText>
        (
        <Link onClick={() => setTab("reviews")} linkSize="small">
          {summary.length} {summary.length === 1 ? "review" : "reviews"}
        </Link>
        )
      </SmallText>
    </HStack>
  );
};
