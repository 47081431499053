import { Alert, SmallText } from "@RooUI";
import { Skeleton } from "antd";
import React, { useMemo } from "react";

import { ShiftCard } from "@RooBeta/components";
import { useHospitalShifts } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";

import { TabContentContainer } from "./styles";

export const HospitalShifts = () => {
  const { hospitalId } = useShiftDetailsStore();
  const { data: hospitalShifts, isLoading, error } = useHospitalShifts({ hospitalId });
  const count = useMemo(() => hospitalShifts?.length ?? 0, [hospitalShifts]);

  return hospitalShifts ? (
    <>
      {hospitalShifts.map((shift, index) => (
        <ShiftCard key={shift.shiftId} shift={{ ...shift, index }} count={count} variant="drawer" />
      ))}
    </>
  ) : isLoading ? (
    <TabContentContainer>
      <Skeleton active />
    </TabContentContainer>
  ) : error ? (
    <TabContentContainer>
      <Alert $alertStatus="error" alertBody={<SmallText>Oops, something went wrong.</SmallText>} />
    </TabContentContainer>
  ) : null;
};
