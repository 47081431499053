import { type UserInfo } from "@Roo/api/user/useGetUserInfo";

import { type Provider } from "./searchShifts";

export type AuthenticatedUser = UserInfo & { provider: Provider; isSuperUser: boolean };

export enum UserStatus {
  Loading = "Loading",
  LoggedOut = "LoggedOut",
  LoggedIn = "LoggedIn",
}

export type AuthorizedUserContextValue = {
  status: UserStatus;
  isLoading: boolean;
  isLoggedIn: boolean;
  user: AuthenticatedUser | null | undefined;
  resetUser: () => void;
};
