import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";

import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";

import { useSelector } from "react-redux";
import { RootState } from "@Roo/store";

export const isNewDesignExperimentEnabled = () => {
  return isRooFeatureEnabled(FEATURE_FLAGS.NEW_DESIGN_EXPERIMENT_RELEASE);
};
export const useNewDesignExperiment = () => {
  const { approvedOnDate } = useSelector((state: RootState) => state.login);
  const isApproved = approvedOnDate != null;
  const isEnabled =
    useRooFeatureFlagEnabled(FEATURE_FLAGS.NEW_DESIGN_EXPERIMENT_RELEASE) && isApproved;
  return {
    isEnabled,
  };
};
