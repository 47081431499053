import {
  BodyText,
  Checkbox,
  FlexLayout,
  Icon,
  Tooltip,
  grayBackground,
  mediumMediaBreakpoint,
  spacingL,
  spacingS,
} from "@RooUI";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ToastContext } from "@Roo/Common/Toasts/context/ToastContext";
import { NotificationSettingRow as NotificationSettingRowType } from "@Roo/constants/notificationConstants";
import { RootState } from "@Roo/store";

import { useUpdateNotificationSettings } from "../../../hooks/api/useUpdateNotificationSettings";

interface NotificationSettingRowProps {
  notificationSetting: NotificationSettingRowType;
}

interface ChannelState {
  email: boolean;
  sms: boolean;
  push: boolean;
}

export const CHANNELS: Array<keyof ChannelState> = ["email", "sms", "push"];

export function NotificationSettingRow({ notificationSetting }: NotificationSettingRowProps) {
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { userId } = useSelector((state: RootState) => state.login);
  const { mutate: updateSettings } = useUpdateNotificationSettings({
    userId,
    onSuccess: () => {
      showSuccessToast({
        description: "Notification settings were successfully updated.",
        message: "Settings updated",
      });
    },
    onError: (error) => {
      showErrorToast({
        description: error?.message || "Error updating notification settings",
      });
    },
  });

  const getInitialState = useCallback(
    () => ({
      email: notificationSetting.email ?? false,
      sms: notificationSetting.sms ?? false,
      push: notificationSetting.push ?? false,
    }),
    [notificationSetting]
  );

  const [channelState, setChannelState] = useState<ChannelState>(getInitialState());

  useEffect(() => {
    setChannelState(getInitialState());
  }, [getInitialState]);

  const handleToggle = (channel: keyof ChannelState, currentValue: boolean) => {
    const newValue = !currentValue;

    setChannelState((prev) => ({
      ...prev,
      [channel]: newValue,
    }));

    const getEnabledValue = (ch: keyof ChannelState) =>
      ch === channel ? (newValue ? 1 : 0) : channelState[ch] ? 1 : 0;

    updateSettings({
      notificationTypeId: notificationSetting.notiticationTypeId,
      userId,
      hospitalId: undefined,
      emailEnabled: getEnabledValue("email"),
      smsEnabled: getEnabledValue("sms"),
      pnEnabled: getEnabledValue("push"),
    });
  };

  return (
    <Container>
      <LabelIconWrapper>
        <Body>{notificationSetting.label}</Body>
        {notificationSetting.tooltip && (
          <Tooltip
            title={notificationSetting.tooltip}
            data-testid={`${notificationSetting.label}Tooltip`}
          >
            <Icon name="InfoOutline" size="s" data-testid="infoOutlineIcon" />
          </Tooltip>
        )}
      </LabelIconWrapper>
      <CheckboxContainer>
        {CHANNELS.map((channel) => (
          <CheckboxWrapper key={channel}>
            <Checkbox
              checked={channelState[channel]}
              onChange={() => handleToggle(channel, channelState[channel])}
              data-testid={`${channel}Checkbox`}
            />
          </CheckboxWrapper>
        ))}
      </CheckboxContainer>
    </Container>
  );
}

const LabelIconWrapper = styled(FlexLayout)`
  align-items: center;
  width: 100%;
  min-width: 151px;
  max-width: 623px;
  gap: ${spacingS};

  @media (max-width: ${mediumMediaBreakpoint}) {
    gap: 0px;
  }
`;

const Container = styled(FlexLayout)`
  flex-direction: row;
  border-bottom: 1px solid ${grayBackground};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${spacingL};
`;

const Body = styled(FlexLayout)`
  ${BodyText}
`;

const CheckboxContainer = styled(FlexLayout)`
  flex-direction: row;
  max-width: 348px;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
