import { Link, Text } from "@RooUI";
import React from "react";

export const EventSignUpFooter = () => {
  return (
    <>
      <Text>
        By clicking "Create My Account", you agree to Roo's{" "}
        <Link href="/terms-of-use" target="_blank" testId="termsOfUseLink">
          Terms & Conditions
        </Link>{" "}
        and acknowledge that you have read our{" "}
        <Link href="/privacy-policy" target="_blank" testId="privacyPolicyLink">
          Privacy Policy
        </Link>
        .
      </Text>
      <Text>
        By providing your phone number and clicking "Create My Account", you consent to receive text
        messages from Roo. Text messages may be autodialed, and data rates may apply. The frequency
        of text messages varies. You may text STOP to cancel any time.
      </Text>
    </>
  );
};
