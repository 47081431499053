import styled from "styled-components";

import {
  BodyText,
  FormLabelText,
  SmallBodyText,
  blueDark600,
  blueLight100,
  blueLighter,
  checkboxBorderRadius,
  disabledTextColor,
  grayBackground,
  grayBase,
  grayWhite,
  inputBorderRadius,
  redBase,
  spacingM,
  spacingS,
  spacingXS,
  spacingXXL,
} from "../../styles/constants";
import { smallMediaBreakpoint } from "../../styles/constants/mediaBreakpoints";
import { TwoColumnGrid } from "../Grid";

export interface CustomCheckLabelProps {
  disabled?: boolean;
  error?: boolean;
}

export interface InformationalDivProps {
  $disabled?: boolean;
  $checked?: boolean;
  $error?: boolean;
  $subtextShown?: boolean;
  $size?: "small" | "large";
}

export const CustomCheckLabel = styled.label<{
  $disabled?: boolean;
  $error?: boolean;
  $topAlignedLabel?: boolean;
}>`
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  align-items: ${({ $topAlignedLabel }) => ($topAlignedLabel ? "start" : "center")};
  height: min-content;
  cursor: ${({ $disabled }) => `${$disabled ? "not-allowed" : "pointer"}`};
  ${BodyText};
  color: ${({ $error }) => `${$error ? redBase : grayBase}`};
  margin-bottom: 0;
  span:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div {
    grid-area: 2 / span 2;
  }
  p {
    margin: 0;
  }
`;

export const InputContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  -moz-appearance: none; /* Remove default firefox styling */
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  &[type="radio"] + span {
    border-radius: 50%;
    border: 2px solid ${blueDark600};
  }
  &:checked + span {
    background: ${blueDark600};
    border: 2px solid ${blueDark600};
  }
  &:disabled + span {
    background: ${grayWhite};
    border: 2px solid ${blueLighter};
  }
  &:checked:disabled + span {
    background: ${blueLighter};
    border: 2px solid ${blueLighter};
  }
`;

export const InformationalInput = styled.input<InformationalDivProps>`
  -moz-appearance: none; /* Remove default Firefox styling */
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;

  &[type="radio"] + span {
    border-radius: 50%;
    border: 2px solid
      ${({ $disabled, $error }) => ($disabled ? blueLight100 : $error ? redBase : blueDark600)};
    transition: all 0.3s ease;
    background: ${({ $disabled }) => ($disabled ? grayWhite : "transparent")};
  }

  &:checked + span {
    background: ${({ $disabled, $error }) =>
      $disabled ? blueLight100 : $error ? redBase : blueDark600};
    border: 2px solid
      ${({ $disabled, $error }) => ($disabled ? blueLight100 : $error ? redBase : blueDark600)};
  }

  &:disabled + span {
    background: ${grayWhite};
    border: 2px solid ${blueLighter};
  }
`;

export const Control = styled.span<{ $error: boolean; $topAlignedLabel?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: ${({ $topAlignedLabel }) => ($topAlignedLabel ? spacingXS : "0")};
  background: ${grayWhite};
  border: 2px solid ${blueDark600};
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: ${checkboxBorderRadius};
  border-color: ${({ $error }) => ($error ? redBase : blueDark600)};
`;

export const InformationalDiv = styled.div<InformationalDivProps>`
  display: inline-flex;
  height: ${({ $subtextShown }) => ($subtextShown ? "auto" : "56px")};
  min-width: 124px;
  padding: ${spacingM} ${spacingM} ${spacingM} ${spacingS};
  max-width: ${({ $subtextShown }) => ($subtextShown ? "768px" : "264px")};
  align-items: ${({ $subtextShown }) => ($subtextShown ? "start" : "center")};
  gap: ${spacingM};
  flex-shrink: 0;
  border-radius: ${inputBorderRadius};
  cursor: ${({ $disabled }) => `${$disabled ? "not-allowed" : "pointer"}`};

  .content {
    display: flex;
    flex-direction: row;
    align-items: ${({ $subtextShown }) => ($subtextShown ? "start" : "center")};
    gap: ${spacingS};
  }

  .text-column {
    display: flex;
    flex-direction: column;
  }

  &.checked {
    ${({ $disabled, $error }) => {
      if ($disabled) {
        return `
          background: ${grayBackground};
          border: 2px solid ${blueLight100};
        `;
      } else if ($error) {
        return `
          background: ${grayWhite};
          border: 2px solid ${redBase};
        `;
      } else {
        return `
          border: 2px solid ${blueDark600};
        `;
      }
    }}
    div {
      ${({ $disabled, $error }) => {
        if ($disabled) {
          return `
            color: ${disabledTextColor};
          `;
        } else if ($error) {
          return `
          color: ${redBase};
        `;
        } else {
          return `
            color: ${blueDark600};
          `;
        }
      }}
    }
  }
  &.unchecked {
    ${({ $disabled, $error }) => {
      if ($disabled) {
        return `
          background: ${grayBackground};
          border: 2px solid ${blueLight100};
        `;
      } else if ($error) {
        return `
          border: 2px solid ${redBase};
        `;
      } else {
        return `
          border: 2px solid ${blueLight100};
        `;
      }
    }}
    div {
      ${({ $disabled, $error }) => {
        if ($disabled) {
          return `
          color: ${disabledTextColor};
        `;
        } else if ($error) {
          return `
          color: ${redBase};
        `;
        } else {
          return `
          color: ${blueDark600};
        `;
        }
      }}
    }
  }
`;

export const IconLabel = styled.div<InformationalDivProps>`
  ${FormLabelText};
  color: ${({ $disabled }) => ($disabled ? disabledTextColor : grayBase)};
`;

export const CheckboxGroupFlex = styled.div<{ $singleColumn?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacingM};
  align-items: center;
  flex-direction: ${({ $singleColumn }) => ($singleColumn ? "column" : "row")};
  @media (max-width: ${smallMediaBreakpoint}) {
    align-items: unset;
  }
  ${({ $singleColumn }) =>
    $singleColumn &&
    `
    > * {
      width: 100%;
    }
  `}
`;

export const CheckboxGroupContainer = styled.div<{ $error: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacingM};
  ${({ $error }) =>
    $error
      ? `
          span span {
            border-color: ${redBase}
          }
          label {
            color: ${redBase}
          }
        `
      : ""}
`;

export const CheckboxGroupGridLayout = styled(TwoColumnGrid)`
  @media (max-width: ${smallMediaBreakpoint}) {
    grid-template-columns: 1fr;
    gap: ${spacingS};
  }
`;

export const CheckboxSubText = styled.div<InformationalDivProps>`
  ${SmallBodyText};
  padding-left: ${spacingXXL};
  word-wrap: break-word;
  white-space: normal;
  color: ${({ $disabled }) => ($disabled ? disabledTextColor : grayBase)};
`;
