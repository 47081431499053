import {
  BonusLog,
  PromoLog,
  ReferralLog,
  ShiftBonusLog,
  VetShiftHistoryDetails,
} from "../../../api/shiftHistory";
import * as C from "../../../constants/vetConstants";
import { VetShiftHistoryActions } from "../actions/vetShiftHistoryActions";

type initialStateType = {
  shiftHistory: VetShiftHistoryDetails[];
  totalCount: number;
  totalShiftEarning: number;
  totalReferralBonus: number;
  totalBonus: number;
  totalOvertime: number;
  totalEarnings: number;
  yearToDateEarning: number;
  isActionCompleted: boolean;
  unratedShiftDate: string;
  bonusLog: BonusLog[];
  shiftBonusLog: ShiftBonusLog[];
  referralLog: ReferralLog[];
  promoLog: PromoLog[];
};

const initialState: initialStateType = {
  shiftHistory: [],
  totalCount: 0,
  totalShiftEarning: 0,
  totalReferralBonus: 0,
  totalBonus: 0,
  totalOvertime: 0,
  totalEarnings: 0,
  yearToDateEarning: 0,
  isActionCompleted: false,
  unratedShiftDate: "",
  bonusLog: [],
  shiftBonusLog: [],
  referralLog: [],
  promoLog: [],
};

// eslint-disable-next-line default-param-last
const vetShiftHistoryReducer = (state = initialState, action: VetShiftHistoryActions) => {
  switch (action.type) {
    case C.VET_SHIFT_HISTORY_SUCCESS:
      const shifts = action.payload.shiftHistory;
      if (action.payload.isOvertime) {
        // this is used for overtime confirmation page
        shifts[0].overtime = JSON.parse(shifts[0].overtime);
        if (shifts[0].overtime.status == "accepted" || shifts[0].overtime.status == "rejected") {
          shifts[0].overtime.processed = true;
        }
      }
      return {
        ...state,
        shiftHistory: shifts,
        totalCount: action.payload.totalCount,
        totalShiftEarning: action.payload.totalShiftEarning,
        totalReferralBonus: action.payload.totalReferralBonus,
        totalBonus: action.payload.totalBonus,
        yearToDateEarning: action.payload.yearToDateEarning,
        unratedShiftDate: "",
        isActionCompleted: true,
        bonusLog: action.payload.bonusLog,
        referralLog: action.payload.referralLog,
        shiftBonusLog: action.payload.shiftBonusLog,
        promoLog: action.payload.promoLog,
        totalOvertime: action.payload.totalOvertime,
        totalEarnings: action.payload.totalEarnings,
      };
    case C.CLEAR_SHIFT_HISTORY:
    case C.VET_SHIFT_HISTORY_REQUEST:
    case C.VET_SHIFT_HISTORY_FAIL:
      return {
        ...state,
        shiftHistory: [] as VetShiftHistoryDetails[],
        totalCount: 0,
        totalShiftEarning: 0,
        totalReferralBonus: 0,
        totalBonus: 0,
        yearToDateEarning: 0,
        totalEarnings: 0,
        isActionCompleted: false,
      };

    case C.SET_UNRATED_VET_SHIFT_DATE:
      return {
        ...state,
        unratedShiftDate: action.payload.shiftDate,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default vetShiftHistoryReducer;
