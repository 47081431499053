import { useQuery } from "@tanstack/react-query";
import { format, startOfMonth } from "date-fns";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptions, MyShift } from "@RooBeta/types";

type UseMyShiftsOptions = CustomQueryOptions<{ data: MyShift[] }>;

export const useMyShiftsQuery = (options: UseMyShiftsOptions = {}) => {
  const {
    provider: { providerType, providerId },
  } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.MyShifts],
    queryFn: () => {
      const isVet = providerType === "VET";
      const endpoint = isVet ? "getAllShiftsByVetId" : "getAllShiftsByTechId";
      const idParam = isVet ? "vetId" : "techId";
      const minStartDate = format(startOfMonth(new Date()), "yyyy-MM-dd");

      return get(
        `api/${providerType.toLowerCase()}/shift/${endpoint}/?${idParam}=${providerId}&isIncludeCompleted=1&minStartDate=${minStartDate}`
      );
    },
    retry: false,
    ...options,
  });
};
