export const getTimeBefore = (epoch: number) => {
  const now = Date.now();
  const future = epoch * 1000;

  const diffInMinutes = Math.max(0, Math.floor((future - now) / (1000 * 60)));

  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);

  if (diffInHours < 24) {
    return `${diffInHours}h`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays}d`;
};
