import React from "react";

import { greenBase } from "../../styles/constants";
import { Icon, IconProps } from "../Icon";

import {
  DangerLink,
  DangerTextLink,
  LinkContainer,
  MutedLink,
  MutedTextLink,
  StandardLink,
  TextLink,
} from "./LinkStyles";

export interface LinkTextProps {
  icon?: IconProps["name"] | IconProps;
  onClick?: (event: any) => void;
  onMouseDown?: (event: any) => void;
  iconPosition?: "left" | "right";
  href?: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
  linkStyle?: "standard" | "danger" | "text" | "dangerText" | "muted" | "mutedText";
  linkSize?: "small";
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "onClick" | "onMouseDown">;
  download?: string | boolean;
  testId?: string;
  children?: React.ReactNode;
  className?: string;
}

export const Link = ({
  onClick,
  onMouseDown,
  containerProps,
  testId,
  className,
  ...props
}: LinkTextProps) => {
  const { icon, iconPosition = "left", href, children } = props;
  let { linkStyle = "standard" } = props;

  if (!href) {
    switch (linkStyle) {
      case "danger":
        linkStyle = "dangerText";
        break;
      case "muted":
        linkStyle = "mutedText";
        break;
      default:
        linkStyle = "text";
    }
  }

  const LinkComponent = {
    standard: StandardLink,
    danger: DangerLink,
    text: TextLink,
    dangerText: DangerTextLink,
    muted: MutedLink,
    mutedText: MutedTextLink,
  }[linkStyle] as React.ElementType;

  let iconComponent: React.ReactElement | null = null;

  if (icon) {
    iconComponent =
      typeof icon === "object" ? (
        <Icon {...(icon as IconProps)} />
      ) : (
        <Icon name={icon as IconProps["name"]} color={greenBase} />
      );
  }

  return (
    <LinkContainer
      onClick={onClick}
      onMouseDown={onMouseDown}
      {...containerProps}
      className={className}
    >
      <LinkComponent
        {...props}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          if (onClick && !href) {
            e.preventDefault();
          }
        }}
        onMouseDown={(e: React.MouseEvent<HTMLAnchorElement>) =>
          !!onMouseDown && e.preventDefault()
        }
        data-testid={testId}
      >
        {icon && iconPosition === "left" && iconComponent}
        {children}
        {icon && iconPosition === "right" && iconComponent}
      </LinkComponent>
    </LinkContainer>
  );
};
