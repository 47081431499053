import {
  CheckboxGroup,
  CustomCheckboxProps,
  FlexLayout,
  RadioGroup,
  Select,
  Toggle,
  mediumMediaBreakpoint,
  spacingXXL,
} from "@RooUI";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ToastContext } from "@Roo/Common/Toasts/context/ToastContext";

import { useNotificationAttributes } from "../../../api/notifications/notifications";
import { distanceFromHome } from "../../../constants/dropDownConstants";
import { ALERT_FREQUENCY_RADIOS } from "../../../constants/radiobuttonConstants";
import { useUpdateShiftPreferences } from "../../../hooks/api/useUpdateShiftPreferences";
import { RootState } from "../../../store";

interface ShiftPreferences {
  frequency: number;
  distance: string;
  shiftsOnCertainDays: CustomCheckboxProps[];
  shiftsWithPriceIncrease: boolean;
  emergencyShifts: boolean;
  shiftsWithin24Hour: boolean;
}

interface NewShiftPreferencesFormProps {
  shiftPreferencesId: number;
  frequencyTypeCode: number;
}

interface NotificationAttribute {
  id: number;
  notification_settings_id: number;
  attribute: string;
  value: string | number | boolean;
}

interface PreferenceValue {
  value: string | number | boolean;
  id: number;
  notification_settings_id: number;
}

interface MappedPreferences {
  [key: string]: PreferenceValue;
}

interface UpdatePreferencePayload {
  attribute: string;
  value: string;
}

const ResponsiveFlexLayout = styled(FlexLayout)`
  padding: 32px;
  @media (max-width: ${mediumMediaBreakpoint}) {
    gap: ${spacingXXL};
    flex-direction: column !important;
    padding: 16px !important;

    > * {
      max-width: 100% !important;
    }
  }
`;

const mapPreferencesToObject = (settings: NotificationAttribute[]) => {
  return settings.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.attribute]: {
        value: setting.value,
        id: setting.id,
        notification_settings_id: setting.notification_settings_id,
      },
    }),
    {} as Record<
      string,
      {
        value: string | number | boolean;
        id: number;
        notification_settings_id: number;
      }
    >
  );
};

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;

export const NewShiftPreferencesForm = ({
  shiftPreferencesId,
  frequencyTypeCode,
}: NewShiftPreferencesFormProps) => {
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { userId } = useSelector((state: RootState) => state.login);
  const { isSuccess, data: shiftPreferenceSettings } = useNotificationAttributes({
    notificationSettingsId: shiftPreferencesId,
  });
  const { mutate: updatePreferences } = useUpdateShiftPreferences({
    userId,
    onSuccess: () => {
      showSuccessToast({
        description: "Notification settings were successfully updated.",
        message: "Settings updated",
      });
    },
    onError: (error) => {
      showErrorToast({
        description: error?.message || "Error updating notification settings",
      });
    },
  });

  const [shiftDays, setShiftDays] = useState<CustomCheckboxProps[]>([]);
  const [frequency, setFrequency] = useState<number>();
  const [distance, setDistance] = useState<string>();
  const [priceIncrease, setPriceIncrease] = useState(false);
  const [emergency, setEmergency] = useState(false);
  const [within24Hours, setWithin24Hours] = useState(false);
  const [mappedPreferences, setMappedPreferences] = useState<MappedPreferences>({});

  useEffect(() => {
    if (isSuccess && shiftPreferenceSettings) {
      const mappedPrefs = mapPreferencesToObject(shiftPreferenceSettings);
      setMappedPreferences(mappedPrefs);

      const checkedDays = DAYS_OF_WEEK.map((day, index) => ({
        id: String(index),
        label: day,
        value: day.toLowerCase(),
        checked: mappedPrefs[day.toLowerCase()]?.value === "true" || false,
      }));

      setShiftDays(checkedDays);
      setDistance(String(mappedPrefs.miles?.value || ""));
      setPriceIncrease(mappedPrefs.shift_price_increase?.value === "true");
      setEmergency(mappedPrefs.emergency_shifts?.value === "true");
      setWithin24Hours(mappedPrefs.shift_within_24h?.value === "true");
    }
    if (frequencyTypeCode !== undefined) {
      setFrequency(frequencyTypeCode);
    }
  }, [shiftPreferenceSettings, isSuccess, frequencyTypeCode]);

  const updatePreference = (payload: UpdatePreferencePayload | UpdatePreferencePayload[]) => {
    const settings = _.castArray(payload);
    const preference = mappedPreferences[settings[0].attribute];

    updatePreferences({
      frequencyId: frequency || frequencyTypeCode,
      notificationId: shiftPreferencesId,
      shiftPreferenceSettings: settings.map((setting) => ({
        id: preference?.id || 0,
        notification_settings_id: preference?.notification_settings_id || shiftPreferencesId,
        attribute: setting.attribute,
        value: setting.value,
      })),
    });
  };

  const handleChange = <K extends keyof ShiftPreferences>(key: K, value: ShiftPreferences[K]) => {
    switch (key) {
      case "frequency":
        setFrequency(value as number);
        updatePreferences({
          frequencyId: value as number,
          notificationId: shiftPreferencesId,
          shiftPreferenceSettings: [],
        });
        break;

      case "distance":
        setDistance(value as string);
        updatePreference({ attribute: "miles", value: value as string });
        break;

      case "shiftsOnCertainDays":
        const updatedCheckboxes = value as CustomCheckboxProps[];
        const previousCheckboxes = shiftDays;

        const changedCheckboxes = updatedCheckboxes.filter(
          (checkbox, index) => checkbox.checked !== previousCheckboxes[index].checked
        );

        setShiftDays(updatedCheckboxes);

        if (changedCheckboxes.length > 0) {
          updatePreference(
            changedCheckboxes.map((checkbox) => ({
              attribute: (checkbox.value as string).toLowerCase(),
              value: String(checkbox.checked),
            }))
          );
        }
        break;

      case "shiftsWithPriceIncrease":
        setPriceIncrease(value as boolean);
        updatePreference({
          attribute: "shift_price_increase",
          value: String(value),
        });
        break;

      case "emergencyShifts":
        setEmergency(value as boolean);
        updatePreference({
          attribute: "emergency_shifts",
          value: String(value),
        });
        break;

      case "shiftsWithin24Hour":
        setWithin24Hours(value as boolean);
        updatePreference({
          attribute: "shift_within_24h",
          value: String(value),
        });
        break;
    }
  };

  return (
    <>
      <ResponsiveFlexLayout>
        <FlexLayout
          style={{ flexDirection: "column", maxWidth: "494px", width: "100%", gap: "32px" }}
          data-testid="newShiftPreferencesFlexLayout"
        >
          <RadioGroup
            label="Alert frequency"
            value={frequency}
            radios={ALERT_FREQUENCY_RADIOS}
            onChange={(value) => handleChange("frequency", parseInt(value))}
            flex
            data-testid="newShiftPreferencesRadioGroup"
          />

          <CheckboxGroup
            label="Shifts on certain days"
            onChange={(_e, updated) => handleChange("shiftsOnCertainDays", updated)}
            checkboxes={shiftDays}
            name="isPreference"
            flex
            singleColumn
            data-testid="newShiftPreferencesCheckboxGroup"
          />
        </FlexLayout>
        <FlexLayout style={{ flexDirection: "column", width: "100%", gap: spacingXXL }}>
          <div style={{ maxWidth: "272px", width: "100%" }}>
            <Select
              label="Distance from home"
              options={distanceFromHome}
              value={distance}
              onChange={({ value }) => handleChange("distance", value)}
              searchable
              data-testid="notificationsSelectDistanceFromHome"
            />
          </div>

          <Toggle
            label="Shifts with price increases"
            subLabel="Shifts with higher pay rates, often due to urgency or special needs."
            onChange={() => handleChange("shiftsWithPriceIncrease", !priceIncrease)}
            checked={priceIncrease}
            data-testid="notificationsTogglePriceIncrease"
            isFullWidth
          />
          <Toggle
            label="Emergency shifts"
            subLabel="Stay informed about fast-paced, exciting emergency shifts—day or overnight—where your expertise in navigating the unpredictable is key."
            onChange={() => handleChange("emergencyShifts", !emergency)}
            checked={emergency}
            data-testid="notificationsToggleEmergencyShifts"
            isFullWidth
          />
          <Toggle
            label="Shifts within 24 hours"
            subLabel="Shifts starting within the next day—perfect for filling last-minute gaps in your schedule."
            onChange={() => handleChange("shiftsWithin24Hour", !within24Hours)}
            checked={within24Hours}
            data-testid="notificationsToggleShiftsWithin24Hours"
            isFullWidth
          />
        </FlexLayout>
      </ResponsiveFlexLayout>
    </>
  );
};
