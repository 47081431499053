import * as Sentry from "@sentry/react";
import React from "react";

import { Navigation } from "@RooBeta/components";
import { useAuthorizedUser, useNewDesignExperiment } from "@RooBeta/hooks";

import OldHeader from "./HeaderLoggedIn";

const ErrorFallback = () => <OldHeader />;

export const LoadHeader = () => {
  const { isEnabled } = useNewDesignExperiment();
  const { user } = useAuthorizedUser();
  return isEnabled && (!!user?.vetId || !!user?.techId) ? <Navigation /> : <OldHeader />;
};

export const HeaderNavigation = () => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <LoadHeader />
  </Sentry.ErrorBoundary>
);
