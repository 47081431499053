import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { AntInputProps, Input } from "@RooUI";

import { ControlledComponentProps } from "./types";

interface ControlledInputProps<T extends FieldValues>
  extends Omit<AntInputProps, "onChange" | "value" | "name" | "defaultValue">,
    ControlledComponentProps<T> {}

export const ControlledInput = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  rules,
  ...inputProps
}: ControlledInputProps<T>) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field }) => (
      <Input name={field.name} value={field.value} onChange={field.onChange} {...inputProps} />
    )}
  />
);
