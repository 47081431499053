import { useQuery } from "@tanstack/react-query";

import { CacheKey, CustomQueryOptions } from "@RooBeta/types";

interface GeoLocation {
  latitude: number;
  longitude: number;
  timestamp: number;
}

type GetGeoLocationOptions = {
  enableHighAccuracy?: boolean;
  maximumAge?: number;
  timeout?: number;
};

export const getGeolocation = (options: GetGeoLocationOptions = {}) =>
  new Promise<GeoLocation>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude }, timestamp }) => {
          resolve({ latitude, longitude, timestamp });
        },
        (error) => {
          reject(error);
        },
        options
      );
    } else {
      reject(new Error("Geolocation is not supported"));
    }
  });

type UseGeoLocationOptions = CustomQueryOptions<GeoLocation> & GetGeoLocationOptions;

export const useGeolocation = (options: UseGeoLocationOptions = {}) =>
  useQuery({
    queryKey: [CacheKey.GeoLocation],
    queryFn: () => getGeolocation(options),
  });
