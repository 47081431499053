import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

import { CacheKey } from "@RooBeta/types";

import { APIError, post } from "../../api/common/rooFetch";
import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";
import { ToastContext } from "../../Common/Toasts/context/ToastContext";
import { addBookmark } from "../../constants/apiRoutes";

export const useAddBookmark = (onSuccess?: () => void) => {
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  return useMutation<void, APIError<{ message: string }>, { shiftGroupId: number }>(
    (params) => {
      return post(addBookmark(), params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [CACHE.BOOKMARKS] });
        queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
        onSuccess?.();
        showSuccessToast({
          description: "Shift was successfully added to share list.",
          message: "Shift shared",
        });
      },
      onError: (error) => {
        showErrorToast({ description: error?.message || "Error adding bookmark" });
      },
    }
  );
};
