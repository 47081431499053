import { z } from "zod";

export const iconSchema = z.enum([
  "calendar_month",
  "time",
  "wellness",
  "cardiology",
  "emergency",
  "urgent_care",
  "vaccine",
  "surgery",
  "car",
  "license",
  "instant_filled",
  "general_practice",
  "account_approved",
  "account_balance",
  "account",
  "add",
  "alarm_add",
  "alarm_off",
  "analytics",
  "announcement",
  "app_search",
  "approve",
  "arrow_back_ios",
  "arrow_back",
  "arrow_circle_down",
  "arrow_circle_up",
  "arrow_down",
  "arrow_forward_ios",
  "arrow_forward",
  "arrow_up",
  "attachment",
  "av_timer",
  "backup",
  "behavior",
  "block",
  "bookmark_filled",
  "bookmark",
  "bug",
  "build",
  "business",
  "calendar_day",
  "call",
  "campaign",
  "cancel",
  "card_membership",
  "card_travel",
  "chat_bubble",
  "chat",
  "check",
  "chevron_left",
  "chevron_right",
  "clear",
  "cloud_download",
  "cloud_off",
  "cloud",
  "copy",
  "credit_card",
  "data",
  "delete",
  "dentistry",
  "dermatology",
  "document",
  "dog_filled",
  "dog",
  "donut",
  "download_done",
  "download",
  "drafts",
  "dress_code",
  "edit",
  "error_filled",
  "error",
  "event_repeat",
  "event",
  "exit",
  "exotic_animals",
  "expand_less",
  "expand_more",
  "favorite_filled",
  "favorite",
  "filter_alt",
  "filter_list",
  "fingerprint",
  "fire",
  "flare",
  "flight",
  "folder",
  "forum",
  "help",
  "history",
  "home",
  "hourglass",
  "info",
  "instant",
  "large_animal",
  "launch",
  "lightbulb",
  "link_off",
  "link",
  "list",
  "lock",
  "map",
  "mark_chat_read",
  "mark_chat_unread",
  "mark_email_read",
  "mark_email_unread",
  "menu_open",
  "menu",
  "more_horiz",
  "more_vert",
  "my_location",
  "navigation",
  "network",
  "neurology",
  "nonprofit",
  "notifications_off",
  "notifications",
  "nutrition",
  "ortho",
  "paragraph",
  "paw",
  "payment",
  "pin",
  "power",
  "promo",
  "radiology",
  "referral",
  "refresh",
  "remove",
  "rocket",
  "roo",
  "savings",
  "school",
  "search",
  "send",
  "settings",
  "shelter",
  "sort",
  "star_filled",
  "star_half",
  "star",
  "stethoscope",
  "thermometer",
  "timeline",
  "trending_up",
  "ultrasound",
  "unlock",
  "upload",
  "verified",
  "visibility_off",
  "visibility",
  "walkin",
  "wallet",
  "work",
]);

export type Icon = z.infer<typeof iconSchema>;

export type RooIconSize = "xs" | "s" | "m" | "l" | "xl" | "input";

export const rooIconSizes: Record<RooIconSize, number> = {
  xs: 10,
  s: 12,
  m: 16,
  l: 24,
  xl: 32,
  input: 20,
};
