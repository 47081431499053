import React, { ReactNode } from "react";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

type ProviderType = "VET" | "TECH";

interface ProviderRestrictedProps {
  children: ReactNode;
  providerType: ProviderType;
}

export const ProviderRestricted = ({ children, providerType }: ProviderRestrictedProps) => {
  const { provider } = useRequiredAuthorizedUser();

  if (providerType !== provider.providerType) {
    return null;
  }

  return <>{children}</>;
};
