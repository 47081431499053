/**
 * Ensures a URL has a proper protocol (https:// by default).
 * @param url - The URL string to format.
 * @param protocol - Optional protocol to use (defaults to 'https').
 * @returns Properly formatted URL string.
 */
export const formatUrl = (url: string, protocol: string = "https") => {
  if (!url) {
    return "";
  }

  // Check if URL already has a protocol.
  if (/^[a-zA-Z]+:\/\//.test(url)) {
    return url;
  }

  return `${protocol}://${url}`;
};
