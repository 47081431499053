import { z } from "zod";

export const shiftStatusSchema = z.enum([
  "CONFIRMED",
  "REQUESTED",
  "EXPIRED",
  "UNFILLED",
  "COMPLETED",
]);

export type ShiftStatus = z.infer<typeof shiftStatusSchema>;
