import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

type AutocompleteService = google.maps.places.AutocompleteService;
type PlacesService = google.maps.places.PlacesService;

export const useGooglePlacesService = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [autocomplete, setAutocomplete] = useState<AutocompleteService | undefined>(undefined);
  const [places, setPlaces] = useState<PlacesService | undefined>(undefined);
  const map = useMap();
  const placesLibrary = useMapsLibrary("places");

  useEffect(() => {
    if (placesLibrary && map) {
      setPlaces(new placesLibrary.PlacesService(map));
      setAutocomplete(new placesLibrary.AutocompleteService());
      setIsLoading(false);
    }
  }, [placesLibrary, map]);

  return { isLoading, autocompleteService: autocomplete, placesService: places };
};
