import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptionsWithData, Favorites } from "@RooBeta/types";

type UseFavoriteHospitalsProps = CustomQueryOptionsWithData<Set<number>, Set<number>>;

export const useFavoriteHospitalsQuery = ({ ...options }: UseFavoriteHospitalsProps) => {
  const {
    userId,
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.Favorites, userId],
    queryFn: async () => {
      const { favorites } = await get<Favorites>(
        providerType === "VET" ? `api/vet/favorites` : `api/tech/favorites`
      );

      return favorites
        ? new Set(
            favorites
              .split(",")
              .map((id) => parseInt(id.trim(), 10))
              .filter((id) => !isNaN(id))
          )
        : new Set<number>();
    },
    staleTime: 5 * 60 * 1000,
    ...options,
  });
};
