import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { CacheKey, CustomQueryOptions, HospitalInfo } from "@RooBeta/types";

type UseHospitalInfoOptions = CustomQueryOptions<HospitalInfo> & {
  hospitalId?: number | null;
};

export const useHospitalInfoQuery = ({ hospitalId, ...options }: UseHospitalInfoOptions) =>
  useQuery({
    queryKey: [CacheKey.HospitalInfo, { hospitalId }],
    queryFn: () => get<HospitalInfo>(`api/hospital/${hospitalId}/info`),
    enabled: !!hospitalId,
    staleTime: 1000 * 60 * 5,
    retry: false,
    ...options,
  });
