import { useQuery } from "@tanstack/react-query";

import { post } from "@RooBeta/api";
import { useSearchRequestBody } from "@RooBeta/hooks";
import {
  CacheKey,
  CustomQueryOptions,
  SearchRequest,
  SearchResponse,
  SearchShifts,
  searchRequestSchema,
} from "@RooBeta/types";

const searchShifts = (request: SearchShifts) => {
  const search = searchRequestSchema.parse(request);
  // eslint-disable-next-line no-console
  // console.log("searchShifts", search);
  return post<SearchRequest, SearchResponse>(`api/shiftSearch`, search);
};

type UseSearchShiftsOptions = CustomQueryOptions<SearchResponse>;

export const useSearchShifts = (options: UseSearchShiftsOptions) => {
  const searchRequestBody = useSearchRequestBody();

  return useQuery({
    queryKey: [
      CacheKey.Search,
      searchRequestBody.boundingBox,
      searchRequestBody.zoom,
      searchRequestBody.dateRange,
      searchRequestBody.rank,
      searchRequestBody.filters,
    ],
    queryFn: () => searchShifts(searchRequestBody),
    retry: false,
    ...options,
  });
};
