import {
  Button,
  Checkbox,
  Container,
  FlexLayout,
  Input,
  Link,
  Select,
  TwoColumnLayout,
  SmallText,
} from "@RooUI";
import React, { useCallback } from "react";

import { PasswordInput } from "../../../../Common/Form/PasswordInput";
import { PhoneInput } from "../../../../Common/Form/PhoneInput";
import { ButtonGroup, FooterGroup } from "../styles";

import { EventStudentSignUpForm } from "./StudentSignUpContainer";
import { useFormContext } from "./useFormContext";

const GRADUATION_YEARS = Array.from({ length: 10 }, (_, i) => {
  const year = new Date().getFullYear() + i;
  return { value: year.toString(), label: year.toString() };
});

export const StudentSignUp = (_props: { booth: boolean }) => {
  const { form, onCancel, onChange, onSave, errors = {}, loading = false } = useFormContext();

  const handleChange = useCallback(
    (field: keyof EventStudentSignUpForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(field, e.target.value);
      }
    },
    [onChange]
  );

  if (!form) {
    return null;
  }

  const {
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    zipcode,
    studentSchool,
    graduationYear,
    acceptedTerms,
    acceptedSmsMessages,
  } = form;

  return (
    <Container data-testid="studentContainer">
      <TwoColumnLayout>
        <Input
          label="First name"
          value={firstName}
          onChange={handleChange("firstName")}
          error={errors["firstName"]}
          name="firstName"
          data-testid="studentFirstName"
        />
        <Input
          label="Last name"
          value={lastName}
          onChange={handleChange("lastName")}
          error={errors["lastName"]}
          name="lastName"
          data-testid="studentLastName"
        />
        <FlexLayout gap="xs" vertical>
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={handleChange("email")}
            error={errors["email"]}
            name="email"
            data-testid="studentEmail"
          />
          <SmallText>Please use the email that you plan on keeping after graduation.</SmallText>
        </FlexLayout>
        <PasswordInput
          password={password}
          error={errors["password"]}
          onChange={(value) => onChange?.("password", value)}
          data-testid="studentPassword"
        />
        <Input
          label="Zip"
          inputMode="numeric"
          value={zipcode}
          maxLength={5}
          onChange={handleChange("zipcode")}
          error={errors["zipcode"]}
          name="zipcode"
          data-testid="studentZipcode"
        />
        <PhoneInput
          label="Phone number"
          value={phoneNumber}
          error={errors["phoneNumber"]}
          onChange={(phone) => onChange?.("phoneNumber", phone?.toString() || "")}
          id="studentMobileInput"
          data-testid="studentPhoneNumber"
        />
        <Input
          label="Vet school"
          value={studentSchool}
          onChange={handleChange("studentSchool")}
          error={errors["studentSchool"]}
          name="studentSchool"
          data-testid="studentSchool"
        />
        <Select
          label="Year of graduation"
          value={graduationYear}
          onChange={(_option, value) => onChange?.("graduationYear", value || "")}
          error={errors["graduationYear"]}
          options={GRADUATION_YEARS}
          data-testid="studentGraduationYear"
        />
      </TwoColumnLayout>

      <FooterGroup>
        <FlexLayout gap="m" vertical>
          <div>
            <Checkbox
              checked={acceptedTerms}
              onChange={(e) => onChange?.("acceptedTerms", e.target.checked)}
              error={errors["acceptedTerms"]}
              data-testid="studentTerms"
              label={
                <>
                  I have read and accept the{" "}
                  <Link href="/terms-of-use" target="_blank" data-testid="termsOfUseLink">
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link href="/privacy-policy" target="_blank" data-testid="privacyPolicyLink">
                    Privacy Policy
                  </Link>
                </>
              }
            />
          </div>
          <div>
            <Checkbox
              checked={acceptedSmsMessages}
              onChange={(e) => onChange?.("acceptedSmsMessages", e.target.checked)}
              data-testid="studentSmsConsent"
              label="I agree to receive email and SMS messages from Roo"
            />
          </div>
        </FlexLayout>
        <ButtonGroup>
          <Button
            title="Create My Account"
            $buttonType="primary"
            onClick={onSave}
            loading={loading}
            testId="createStudentAccount"
          />
          <Button title="Cancel" onClick={onCancel} loading={loading} testId="cancelStudent" />
        </ButtonGroup>
      </FooterGroup>
    </Container>
  );
};
