import { Input, Select, SmallText, Toggle } from "@RooUI";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { RooIcon, VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";
import { ShiftAdjustments as FormData } from "@RooBeta/types";

export const ShiftAdjustments = () => {
  const { shiftDetails } = useShiftDetailsStore();

  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<FormData>();

  const bidPrice = watch("bidPrice");
  const requestedStartTime = watch("requestedStartTime");
  const requestedEndTime = watch("requestedEndTime");
  const isRequestWithoutSurgery = watch("requestedWithoutSurgery");

  useEffect(() => {
    if (shiftDetails) {
      setValue("bidCeiling", shiftDetails.price * 1.3);
      setValue("rateBidMinimum", shiftDetails.price);

      if (shiftDetails.isTimeBiddingEnabled || shiftDetails.isFlexibleTiming) {
        setValue("requestedStartTime", shiftDetails.startTime);
        setValue("requestedEndTime", shiftDetails.endTime);
      }
    }
  }, [shiftDetails, setValue]);

  return (
    <VStack $gap="m" $padding="l">
      {shiftDetails?.isRateBiddingEnabled && (
        <>
          <Input
            label="Total Pay"
            type="number"
            maxLength={5}
            value={bidPrice}
            onChange={({ target: { value } }) =>
              setValue("bidPrice", value ? parseFloat(value) : undefined)
            }
            error={errors.bidPrice?.message}
            prefix={<RooIcon icon="payment" size="input" />}
            allowClear
            autoComplete="off"
            data-1p-ignore
          />
          <SmallText>{shiftDetails?.priceFormatted}</SmallText>
        </>
      )}
      {(shiftDetails?.isTimeBiddingEnabled || shiftDetails?.isFlexibleTiming) && (
        <>
          <Select
            label="Start Time"
            options={shiftDetails?.flexibleStartTimeOptions ?? []}
            value={requestedStartTime}
            onChange={(_, value) => setValue("requestedStartTime", value)}
          />
          <SmallText>{shiftDetails?.startTime}</SmallText>
          <Select
            label="End Time"
            options={shiftDetails?.flexibleEndTimeOptions ?? []}
            value={requestedEndTime}
            onChange={(_, value) => setValue("requestedEndTime", value)}
          />
          <SmallText>{shiftDetails?.endTime}</SmallText>
        </>
      )}
      {shiftDetails?.isFlexibleSurgery && (
        <Toggle
          name="isSurgeryExpected"
          label="Request no surgery"
          checked={isRequestWithoutSurgery}
          onChange={() => setValue("requestedWithoutSurgery", !isRequestWithoutSurgery)}
        />
      )}
    </VStack>
  );
};
