import moment from "moment";

export const isWithinBusinessDays = (formattedDate: string, days: number) => {
  const date = moment(formattedDate, "YYYY-MM-DD");

  if (!date.isValid()) {
    return false;
  }

  let count = 0;

  while (count < days) {
    date.subtract(1, "days");

    if (date.isoWeekday() >= 1 && date.isoWeekday() <= 5) {
      count += 1;
    }
  }

  return moment().isAfter(date);
};
