import { SmallText, grayLight400 } from "@RooUI";
import React from "react";

import { HStack, RooIcon, VStack } from "@RooBeta/components";
import { ShiftDetailsMetadata as Metadata } from "@RooBeta/types";

import { PreviousPrice } from "./styles";

export const ShiftDetailsMetadata = ({ metadata }: { metadata?: Metadata[] }) => (
  <VStack $gap="m" $padding="l">
    {metadata?.map(({ icon, title, value, updatedValue }) => (
      <HStack $gap="m" key={title}>
        <RooIcon icon={icon} size="l" />
        <VStack>
          {updatedValue ? (
            <HStack $gap="xs">
              <div>{updatedValue}</div>
              <PreviousPrice>{value}</PreviousPrice>
            </HStack>
          ) : (
            <div>{value}</div>
          )}
          <SmallText color={grayLight400}>{title}</SmallText>
        </VStack>
      </HStack>
    ))}
  </VStack>
);
