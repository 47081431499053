import { BreakpointEntry, ViewportSize } from "@RooBeta/types";

export const NAVIGATION_HEIGHT = 60;
export const FILTERS_OPEN_WIDTH = 270;
export const FILTERS_CLOSED_WIDTH = 30;
export const SEARCH_HEIGHT = 163;
export const STICKY_HEADER_HEIGHT = 50;
export const SHIFT_CARD_HEIGHT = 160;
export const BANNER_HEIGHT = 109;

export const BREAKPOINTS: BreakpointEntry[] = [
  { key: ViewportSize.s, value: 824 },
  { key: ViewportSize.m, value: 1280 },
  { key: ViewportSize.l, value: 1440 },
];
