import { primaryFont, purpleBase, purpleLight300, purpleLight400 } from "@RooUI";
import { ConfigProvider } from "antd";
import React from "react";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: primaryFont,
      },
      components: {
        Tabs: {
          inkBarColor: purpleBase,
          itemSelectedColor: purpleBase,
          itemHoverColor: purpleLight300,
          itemActiveColor: purpleLight400,
          horizontalMargin: "0",
        },
        Collapse: {
          contentPadding: "0",
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
);
