import React from "react";

import { useAddBookmark } from "@Roo/hooks/api/useAddBookmark";
import { useRemoveBookmark } from "@Roo/hooks/api/useRemoveBookmark";
import { RooIcon } from "@RooBeta/components";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

import { ShareLink } from "./styles";

interface ShareShiftButtonProps {
  shiftGroupId: number;
  bookmarkId?: number | null;
  className?: string;
}

export const ShareShiftButton = ({
  shiftGroupId,
  bookmarkId = null,
  className,
}: ShareShiftButtonProps) => {
  const { isSuperUser } = useRequiredAuthorizedUser();
  const { mutate: addBookmark } = useAddBookmark();
  const { mutate: removeBookmark } = useRemoveBookmark();
  const handleClick = async (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    if (bookmarkId) {
      removeBookmark({ bookmarkId });
    } else {
      addBookmark({ shiftGroupId });
    }
  };

  if (bookmarkId) {
    return <RooIcon icon="clear" size="l" onClick={handleClick} title="Clear" />;
  }

  if (!isSuperUser) {
    return null;
  }

  return (
    <ShareLink $bookmarkId={bookmarkId} icon="Check" onClick={handleClick} className={className}>
      {bookmarkId ? "" : "Share"}
    </ShareLink>
  );
};
