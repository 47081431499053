// COMMON

import { NotificationChannelInfo } from "@Roo/Common/NotificationSettingsNew/interfaces";

import {
  HOSPITAL_USER_TYPE,
  STUDENT_USER_TYPE,
  TECH_USER_TYPE,
  VET_USER_TYPE,
} from "./UserTypeConstants";

// NAV
export const SHIFTS = "Shifts";
export const CHAT = "Chat";
export const MARKETING = "Marketing";
export const SHIFT_REQUEST = "Shift request";

// SHIFT SECTIONS
export const SHIFT_UPDATES = "Shift updates";
export const NO_SECTIONS = "";

// TOGGLE IDS
export const EMAIL = "Email";
export const SMS = "SMS";
export const PUSH_NOTIFICATIONS = "Push Notifications";

export const DEFAULT_TOGGLE_OPTIONS = {
  [EMAIL]: {
    label: EMAIL,
    value: false,
    disabled: false,
  },
  [SMS]: {
    label: SMS,
    value: false,
    disabled: false,
  },
  [PUSH_NOTIFICATIONS]: {
    label: PUSH_NOTIFICATIONS,
    value: false,
    disabled: false,
  },
};

/* VETS */
export const RATINGS = "Ratings";
export const PAYMENTS = "Payments";

/* SHIFTS */
// SECTIONS:
export const NEW_SHIFT_ALERTS = "New shift alerts";
export const FAVORITE_HOSPITALS = "New shifts from your favorite hospitals";
export const WEEKLY_NEW_SHIFT_RECOMMENDATIONS = "Weekly new shift recommendations";
export const SHIFT_REQUESTS = "Shift requests";
export const SHIFT_REMINDERS = "Shift reminders";
export const SHIFT_CONFIRMATIONS = "Shift confirmations";
export const MARKETING_SMS = "Marketing SMS";
export const PROMO = "Promotions";
export const WEEKLY_DIGEST = "Weekly digest";

export const VET_TECH_SHIFTS = {
  [NEW_SHIFT_ALERTS]: {
    label: "Get notified when new shifts are posted",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 29,
  },
  [FAVORITE_HOSPITALS]: {
    label: "Get notified when your favorite hospitals post new shifts",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 1,
  },
  [WEEKLY_NEW_SHIFT_RECOMMENDATIONS]: {
    label: "",
    channels: [EMAIL],
    typeCode: 2,
  },
  [SHIFT_REQUESTS]: {
    label: "Get a confirmation when you request a shift",
    channels: [EMAIL],
    typeCode: 3,
  },
  [SHIFT_UPDATES]: {
    label: "Get notified when shift details change",
    channels: [EMAIL],
    typeCode: 4,
  },
  [SHIFT_REMINDERS]: {
    label: "",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 5,
  },
  [SHIFT_CONFIRMATIONS]: {
    label: "Get notified when your shift requests are confirmed or declined",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 6,
  },
  [WEEKLY_DIGEST]: {
    label: "Receive weekly shift recommendations",
    channels: [EMAIL],
    typeCode: 36,
  },
};

export const VET_TECH_MARKETING = {
  [MARKETING_SMS]: {
    label: "",
    channels: [SMS],
    typeCode: 19,
  },
  [PROMO]: {
    label: "",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 17,
  },
};

/* HOSPITALS */
export const REVIEWS_AND_STATS = "Reviews and Stats";
export const COMMUNITY = "Community";
export const BILLING = "Billing";
export const GENERAL = "General";

/* SHIFTS */
// SHIFT SECTIONS
export const NEW_SHIFT_POSTED = "New shift posted";
export const TECH_CALL_OUT = "Tech call out";
export const SHIFT_BOOSTING = "Shift boosting recommendation";

export const HOSPITAL_SHIFTS = {
  [NEW_SHIFT_POSTED]: {
    label: "Get notified when new shifts are posted",
    channels: [EMAIL],
    typeCode: 7,
  },
  [SHIFT_UPDATES]: {
    label: "Get notified when shift details change",
    channels: [EMAIL],
    typeCode: 4,
  },
  [SHIFT_REQUEST]: {
    label: "Get notified regarding requests on your posted shifts",
    channels: [EMAIL, SMS],
    typeCode: 9,
    disabledOnPrimary: true,
  },
  [TECH_CALL_OUT]: {
    label: "",
    channels: [EMAIL],
    typeCode: 10,
    disabledOnPrimary: true,
  },
  [SHIFT_BOOSTING]: {
    label: "Get notified regarding boost recommendations for posted shifts",
    channels: [EMAIL],
    typeCode: 35,
  },
};

export type UserTypeId =
  | typeof VET_USER_TYPE
  | typeof TECH_USER_TYPE
  | typeof HOSPITAL_USER_TYPE
  | typeof STUDENT_USER_TYPE;

type SideNavKey = "vet_or_tech" | "hospital" | "student";

export const sideNavKeyMap: Record<UserTypeId, SideNavKey> = {
  [VET_USER_TYPE]: "vet_or_tech",
  [TECH_USER_TYPE]: "vet_or_tech",
  [HOSPITAL_USER_TYPE]: "hospital",
  [STUDENT_USER_TYPE]: "student",
};

export const sideNavItems: Record<SideNavKey, any> = {
  vet_or_tech: [
    {
      label: SHIFTS,
      title: SHIFTS,
      content: VET_TECH_SHIFTS,
      typeCodes: [1, 2, 3, 4, 5, 6, 29, 36],
    },
    {
      label: RATINGS,
      title: "Ratings reminders",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 11,
        },
      },
    },
    {
      label: CHAT,
      title: CHAT,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 12,
        },
      },
    },
    {
      label: "Payments",
      title: "Payments",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 13,
        },
      },
    },
    {
      label: "Marketing",
      title: "Marketing",
      content: VET_TECH_MARKETING,
      typeCodes: [19, 17],
    },
  ],
  hospital: [
    {
      label: SHIFTS,
      title: SHIFTS,
      content: HOSPITAL_SHIFTS,
      typeCodes: [7, 4, 8, 9, 10, 35],
      disabledOnPrimary: [9, 10],
    },
    {
      label: REVIEWS_AND_STATS,
      title: "Reviews",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS],
          typeCode: 20,
        },
      },
    },
    {
      label: CHAT,
      title: CHAT,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 12,
        },
      },
    },
    {
      label: COMMUNITY,
      title: COMMUNITY,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 21,
        },
      },
    },
    {
      label: BILLING,
      title: BILLING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 22,
          disabledOnPrimary: true,
        },
      },
      disabledOnPrimary: [22],
    },
    {
      label: GENERAL,
      title: GENERAL,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 23,
          disabledOnPrimary: true,
        },
      },
      disabledOnPrimary: [23],
    },
    {
      label: MARKETING,
      title: MARKETING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [SMS],
          typeCode: 19,
        },
      },
    },
  ],
  student: [
    {
      label: MARKETING,
      title: MARKETING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [SMS],
          typeCode: 19, // Added typeCode for student marketing SMS
        },
      },
    },
  ],
};

export const testResponse = [
  {
    user_notification_settings_id: 29597,
    sms_global_unsubscribe: 0,
    email_global_unsubscribe: 0,
    pn_global_unsubscribe: 0,
    notification_settings_id: 368921,
    email_enabled: 1,
    sms_enabled: 1,
    push_enabled: 1,
    frequency_type_code: 3,
    frequency_type_name: "Once a day",
    notification_type_code: 29,
    notification_type_name: "New shift alert",
    notification_type_description: null,
  },
  {
    user_notification_settings_id: 29597,
    sms_global_unsubscribe: 0,
    email_global_unsubscribe: 0,
    pn_global_unsubscribe: 0,
    notification_settings_id: 368922,
    email_enabled: 1,
    sms_enabled: 1,
    push_enabled: 0,
    frequency_type_code: null,
    frequency_type_name: null,
    notification_type_code: 1,
    notification_type_name: "Favorite hospitals new shift alerts",
    notification_type_description: "Get notified when your favorite hospitals post new shifts.",
  },
];

export interface NotificationSettingRow extends NotificationChannelInfo {
  label: string;
  notiticationTypeId: number;
  tooltip?: string;
}

export interface NotificationCategory {
  title: string;
  label: string;
  notificationTypeIds: number[];
  notificationSettings?: NotificationSettingRow[];
  toggleAllSettings: boolean;
}

const VetTechNotificationCategories: NotificationCategory[] = [
  {
    title: "Shift management",
    label:
      "Stay organized with timely updates on your shift requests, confirmations, changes, and reminders.",
    notificationTypeIds: [3, 4, 5, 6],
    toggleAllSettings: true,
  },
  {
    title: "Shift opportunities",
    label: "Be the first to know about available shifts that match your preferences.",
    notificationTypeIds: [1, 29, 36, 2],
    toggleAllSettings: true,
  },
  {
    title: "Engagement",
    label:
      "Get quick reminders to leave a rating and stay connected with hospitals and teams through chat updates.",
    notificationTypeIds: [11, 12],
    toggleAllSettings: true,
  },
  {
    title: "Financials",
    label:
      "Stay informed about your payments and earnings with timely updates, so you always know where your finances stand.",
    notificationTypeIds: [13],
    toggleAllSettings: true,
  },
  {
    title: "Marketing",
    label:
      "Stay informed about new features, special promotions, exclusive offers, events, Roo community updates, and more.",
    notificationTypeIds: [17, 19],
    toggleAllSettings: true,
  },
];

export const NotificationCategories: Record<UserTypeId, NotificationCategory[]> = {
  [VET_USER_TYPE]: VetTechNotificationCategories,
  [TECH_USER_TYPE]: VetTechNotificationCategories,
  [HOSPITAL_USER_TYPE]: [],
  [STUDENT_USER_TYPE]: [],
};

export const NotificationLabels = {
  1: "New shift opportunities from favorited hospitals",
  2: "Weekly shift digest",
  3: "Shifts requests",
  4: "Shift updates",
  5: "Shift reminders",
  6: "Shift confirmations",
  11: "Rating reminder",
  12: "Hospital chat update",
  13: "Payments",
  14: "Events",
  15: "Newsletter",
  17: "Promotions",
  18: "Product/Platform updates",
  19: "Marketing",
  29: "New shift opportunities based on preferences",
  36: "Weekly boosted shifts",
};

export const NotificationTooltips = {
  2: "Receive a weekly summary of newly added shifts tailored to your preferences.",
  36: "An update sent weekly highlighting shifts that hospitals have recently promoted.",
};
