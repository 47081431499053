import { create } from "zustand";

import { ChipId, Filters } from "@RooBeta/types";
import { sanitizeFormData } from "@RooBeta/utils";

type SearchFilterState = {
  filters: Filters;
  count: number;
};

interface SearchFilterActions {
  setFilters: (filters: Filters) => void;
  setChipId: (chipId?: ChipId) => void;
  clearFilters: () => void;
}

const initialState: SearchFilterState = {
  filters: {},
  count: 0,
};

export const useSearchFilterStore = create<SearchFilterState & SearchFilterActions>((set) => ({
  ...initialState,

  setFilters: (unsanitized: Filters) => {
    const filters = sanitizeFormData<Filters>(unsanitized);
    const { chipId, ...countable } = filters;
    const count = Object.keys(countable).length;
    set({ filters, count });
  },

  setChipId: (chipId?: ChipId) => set(({ filters }) => ({ filters: { ...filters, chipId } })),

  clearFilters: () => set(initialState),
}));
