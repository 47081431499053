import React, { FC } from "react";

import { blueDark600, grayLightest } from "../../styles/constants";
import { FormLabel } from "../FormLabel";
import { Icon, IconProps } from "../Icon";
import { ErrorText } from "../Text/ErrorText";

import {
  LabelContainer,
  RatingContainer,
  RatingOption,
  RatingOptions,
  TextStyled,
} from "./RateSliderStyles";

export interface RateSliderPropTypes {
  label?: string;
  leftLabel?: string;
  rightLabel?: string;
  count?: number;
  value: number;
  onChange: (value: number) => void;
  icon?: IconProps["name"];
  error?: string;
  testId?: string;
}
export const RateSlider: FC<RateSliderPropTypes> = ({
  count = 5,
  label,
  leftLabel,
  rightLabel,
  value,
  onChange,
  icon = "Pets",
  error,
  testId,
}) => {
  const items = [...Array(count).keys()];
  return (
    <div>
      <FormLabel error={!!error}>{label}</FormLabel>
      <RatingContainer data-testid={testId}>
        <RatingOptions>
          {items.map((i) => (
            <RatingOption
              $error={!!error}
              key={i}
              selected={i + 1 === value}
              onClick={() => onChange(i + 1)}
              data-testid="rateItem"
            >
              <Icon name={icon} color={i + 1 === value ? blueDark600 : grayLightest} />
            </RatingOption>
          ))}
        </RatingOptions>
        <LabelContainer>
          {leftLabel && <TextStyled $error={!!error}>{leftLabel}</TextStyled>}
          {rightLabel && <TextStyled $error={!!error}>{rightLabel}</TextStyled>}
        </LabelContainer>
      </RatingContainer>

      {error ? <ErrorText>{error}</ErrorText> : null}
    </div>
  );
};
