import {
  blueBackground,
  grayLightest,
  redBase,
  redLight400,
  spacingM,
  spacingS,
  spacingXL,
} from "@RooUI";
import styled from "styled-components";

export const Messages = styled.div`
  max-height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Message = styled.div`
  display: flex;
  max-width: 448px;
  width: 100vw;
  gap: ${spacingM};
  border-bottom: 1px solid ${grayLightest};
  padding: ${spacingS} ${spacingXL};
  cursor: pointer;
  align-items: center;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const MessageContainer = styled.div`
  flex: 1;
`;

export const MessageHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const UnreadMessageCount = styled.div`
  height: 24px;
  width: 24px;
  background: ${redLight400};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  position: relative;
`;

export const IconBackground = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${blueBackground};
  cursor: pointer;
`;

export const TotalUnreadCount = styled.div<{ $count: number; $design: "new" | "old" }>`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: ${({ $design }) =>
    $design === "new" ? "translate(110%, -140%)" : "translate(130%, -120%)"};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${redBase};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  opacity: ${(props) => (props.$count ? "1" : "0")};
`;

export const StateContainer = styled.div`
  height: 400px;
  width: 448px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacingS};
`;
