import { purpleBase, purpleLight400 } from "@RooUI";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntersectionObserver } from "usehooks-ts";

import { ProviderRestricted, RooIcon } from "@RooBeta/components";
import { useShiftImpression } from "@RooBeta/hooks";
import { useViewport } from "@RooBeta/store";
import { Event, EventTracker, ShiftWithIndex } from "@RooBeta/types";
import { getShiftDetailsPath } from "@RooBeta/utils";

import { ShareShiftButton } from "./ShareShiftButton";
import {
  Card,
  CardChevron,
  CardDateTime,
  CardDetails,
  CardDetailsWrapper,
  CardLogo,
  CardLogoIcon,
  CardMetadata,
  CardPricing,
  CrossedOutPrice,
  DateText,
  DefaultHospitalIcon,
  HospitalImage,
  HospitalName,
  Price,
  ShareShiftButtonContainer,
  ShiftMetadata,
  StatusTag,
  Tag,
  Tags,
  TimeText,
} from "./styles";
import { ShiftCardVariant, useShiftMetadata } from "./useShiftMetadata";

interface ShiftCardProps {
  shift: ShiftWithIndex;
  count: number;
  variant: ShiftCardVariant;
}

export const ShiftCard = ({
  shift: { index: position, ...shift },
  count,
  variant = "list",
}: ShiftCardProps) => {
  const viewport = useViewport();

  const {
    shiftId,
    shiftGroupId,
    hospitalName,
    hospitalImage,
    shiftDateFormatted,
    startTime,
    endTime,
    price,
    priceFormatted,
    previousPriceFormatted,
    shiftStatus,
    bookmarkId,
  } = shift;

  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.5 });
  useShiftImpression({ isIntersecting, position, count, shiftId, price });
  const history = useHistory();

  const onClick = useCallback(() => {
    EventTracker.send({
      eventName: Event.Name.VET_HOSPITAL_PROFILE_LINK,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET_SHIFT,
      entityId: shift.shiftId,
      context: { location: "shift_card", shiftStatus: shift.shiftStatus, isNewExperience: true },
    });

    history.push(getShiftDetailsPath(shift.shiftGroupId));
  }, [shift, history]);

  // Most display logic is handled in the useShiftMetadata hook.
  // Ideally this can be server-driven in the future.
  const { listItems, tags, elements } = useShiftMetadata({ shift, viewport, variant });

  return (
    <Card $variant={variant} ref={ref} onClick={onClick}>
      {elements.has("date_column") && (
        <CardDateTime>
          {elements.has("date") && <DateText>{shiftDateFormatted}</DateText>}
          <TimeText>{startTime} -</TimeText>
          <TimeText>{endTime}</TimeText>
          {elements.has("flexible_time") && <DateText>Flexible</DateText>}
        </CardDateTime>
      )}
      <CardDetails>
        <CardDetailsWrapper>
          {elements.has("hospital_logo") && (
            <CardLogo>
              {elements.has("favorite") && (
                <CardLogoIcon>
                  <RooIcon icon="favorite_filled" size="s" color={purpleBase} />
                </CardLogoIcon>
              )}
              {hospitalImage ? (
                <HospitalImage src={hospitalImage} alt={hospitalName} />
              ) : (
                <DefaultHospitalIcon>
                  <RooIcon icon="business" size="l" />
                </DefaultHospitalIcon>
              )}
            </CardLogo>
          )}
          <CardMetadata>
            {elements.has("hospital_name") && <HospitalName>{hospitalName}</HospitalName>}
            {listItems.map(({ icon, label }) => (
              <ShiftMetadata key={label}>
                <RooIcon icon={icon} /> {label}
              </ShiftMetadata>
            ))}
            {tags.length > 0 && (
              <Tags>
                {tags.map(({ icon, label }) => (
                  <Tag key={label}>
                    <RooIcon icon={icon} color={purpleLight400} />
                    {label}
                  </Tag>
                ))}
              </Tags>
            )}
          </CardMetadata>
        </CardDetailsWrapper>
      </CardDetails>
      {previousPriceFormatted || priceFormatted ? (
        <CardPricing>
          {previousPriceFormatted && <CrossedOutPrice>{previousPriceFormatted}</CrossedOutPrice>}
          <Price>{priceFormatted}</Price>
          {elements.has("status") && (
            <StatusTag $status={shiftStatus}>{shiftStatus.toLowerCase()}</StatusTag>
          )}
          {elements.has("currently_viewing") && (
            <StatusTag $status="VIEWING">Currently Viewing</StatusTag>
          )}
        </CardPricing>
      ) : null}

      {elements.has("chevron") && (
        <CardChevron>
          <RooIcon icon="chevron_right" size="l" />
        </CardChevron>
      )}
      <ShareShiftButtonContainer $bookmarked={!!bookmarkId}>
        <ProviderRestricted providerType="VET">
          <ShareShiftButton shiftGroupId={shiftGroupId} bookmarkId={bookmarkId} />
        </ProviderRestricted>
      </ShareShiftButtonContainer>
    </Card>
  );
};
