import { useMutation } from "@tanstack/react-query";
import qs from "qs";

import { createShortLink } from "@Roo/api/shortlink";
import { DEFAULT_UTM_SOURCE } from "@Roo/constants/constants";
import { Event, EventTracker, InitialSearchRequest } from "@RooBeta/types";

import { useRequiredAuthorizedUser } from "../useAuthorizedUser";

export const useCreateSharedSearchLinkMutation = () => {
  const { vetId, techId } = useRequiredAuthorizedUser();
  return useMutation({
    mutationFn: async (searchRequest: InitialSearchRequest) => {
      const search = qs.stringify(searchRequest);

      const shortLink = await createShortLink(
        `${
          `${window.location.href}`.split("?")[0]
        }?${search}&utm_source=${DEFAULT_UTM_SOURCE}&sharedSearch=true`
      );

      await navigator.clipboard.writeText(shortLink);

      const entityType = !!vetId ? Event.Entity.VET : Event.Entity.TECH;

      EventTracker.send({
        eventName: Event.Name.CREATE_SHARE_LINK,
        eventType: Event.Type.CLICK,
        entityType: entityType,
        entityId: vetId ?? techId ?? 0,
        context: {
          shortLink,
          searchParams: search,
        },
      });

      return shortLink;
    },
  });
};
