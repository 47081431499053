import {
  BodyText,
  blueDark600,
  blueLightest,
  grayBackground,
  grayBase,
  grayLight300,
  grayWhite,
  inputBorderRadius,
  redBase,
} from "@RooUI";
import { TimePicker as AntdTimePicker, TimePickerProps as AntdTimePickerProps } from "antd";
import React from "react";
import { styled } from "styled-components";

type TimePickerProps = AntdTimePickerProps & {
  disabled?: boolean;
  $status?: string;
};

export const TimePicker = (props: TimePickerProps) => <StyledTimePicker {...props} />;

const StyledTimePicker = styled(AntdTimePicker)<TimePickerProps>`
  height: 48px;
  ${BodyText}
  color: ${grayBase};
  background: ${(props) => (props.disabled ? grayBackground : grayWhite)};
  border: 2px solid ${(props) => `${props.$status === "error" ? redBase : blueLightest}`};
  border-radius: ${inputBorderRadius};
  padding: 12px 8px;

  ::placeholder {
    ${BodyText}
    color: ${(props) => `${props.$status === "error" ? redBase : grayLight300}`};
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: 2px solid ${blueDark600};
  }
`;
