import styled from "styled-components";

import {
  blueDark600Shadow,
  containerBorderRadius,
  grayWhite,
  redBackground,
  spacingS,
  spacingXL,
  tealBackground,
  yellowBackground,
} from "../../styles/constants";
import { MediumText } from "../Text/TextStyles";

import { AlertProps } from ".";

const getBackgroundColor = (alertStatus: AlertProps["$alertStatus"]) => {
  let backgroundColor: string = grayWhite;
  if (alertStatus === "error") backgroundColor = redBackground;
  if (alertStatus === "warning") backgroundColor = yellowBackground;
  if (alertStatus === "info") backgroundColor = tealBackground;
  return backgroundColor;
};

export const Alert = styled.div<AlertProps>`
  display: flex;
  width: 100%;
  padding: ${spacingXL};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: ${(props) => getBackgroundColor(props.$alertStatus)};
  box-shadow: 0px 2px 4px 0px ${blueDark600Shadow};
  border-radius: ${containerBorderRadius};
  gap: ${spacingS};

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const AlertLeftContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AlertActionArea = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: ${spacingS};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AlertText = styled(MediumText)`
  margin-left: ${spacingS};
`;
