import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptions, Shift } from "@RooBeta/types";

type UseHospitalShiftsOptions = CustomQueryOptions<Shift[]> & {
  hospitalId?: number;
};

export const useHospitalShifts = ({ hospitalId, ...options }: UseHospitalShiftsOptions) => {
  const { userId, provider } = useRequiredAuthorizedUser();
  const role = provider.providerType.toLowerCase();

  return useQuery({
    queryKey: [CacheKey.HospitalShifts, { hospitalId, userId }],
    queryFn: () => get<Shift[]>(`api/hospital/${hospitalId}/shifts?role=${role}`),
    enabled: !!hospitalId,
    staleTime: 1000 * 60 * 5,
    retry: false,
    ...options,
  });
};
