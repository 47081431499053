import React from "react";

import { ErrorText } from "../Text/ErrorText";

import {
  RadioGroupFlex,
  RadioGroupGrid,
  SingleColumnDiv,
  StyledFormLabel,
} from "./RadioGroupStyles";

import { CustomRadioProps, Radio } from "./index";

export type RadioGroupProps = {
  radios: CustomRadioProps[];
  onChange: (value: any) => void;
  label?: string;
  value?: any;
  error?: string;
  name?: string;
  singleColumn?: boolean;
  disabled?: boolean;
  flex?: boolean;
  multiSelect?: boolean;
  type?: "classic" | "informational";
  $shouldWrapRadios?: boolean;
  rowGap?: string;
  gridMarginTop?: string;
  "data-testid"?: string;
};

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    radios,
    onChange,
    label,
    error,
    name,
    value,
    type = "classic",
    singleColumn,
    $shouldWrapRadios: $shouldWrapRadios,
    rowGap,
    gridMarginTop,
    disabled,
    flex,
    multiSelect,
    "data-testid": testId,
    ...rest
  } = props;
  const radioComponents = radios?.map((radio) => (
    <Radio
      key={radio.id}
      type={type}
      {...radio}
      onChange={(e) => onChange(e.target.value)}
      checked={multiSelect ? value.includes(radio.value) : value === radio.value}
      error={error}
      name={name}
      disabled={disabled}
    />
  ));

  const wrapper = () => {
    if (flex) {
      return <RadioGroupFlex>{radioComponents}</RadioGroupFlex>;
    } else if (!singleColumn) {
      return (
        <RadioGroupGrid $marginTop={gridMarginTop} $rowGap={rowGap}>
          {radioComponents}
        </RadioGroupGrid>
      );
    } else {
      return (
        <SingleColumnDiv $shouldWrapRadios={$shouldWrapRadios}>
          {singleColumn && radioComponents}
        </SingleColumnDiv>
      );
    }
  };

  return (
    <div {...rest} data-testid={testId}>
      {label && (
        <StyledFormLabel $disabled={disabled} error={!!error}>
          {label}
        </StyledFormLabel>
      )}
      {wrapper()}
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
