import { Button, Text } from "@RooUI";
import React, { useCallback } from "react";

import { VStack } from "@RooBeta/components";
import { useSearchFilterStore } from "@RooBeta/store";

import { EmptyListContainer } from "./styles";

export const EmptyList = () => {
  const {
    filters: { chipId },
    count,
    clearFilters,
  } = useSearchFilterStore();

  const onClick = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  return (
    <EmptyListContainer>
      <VStack $justifyContent="center" $alignItems="center" $gap="m">
        {chipId === "favorites" ? (
          <Text>No favorites yet</Text>
        ) : chipId === "workedBefore" ? (
          <Text>No previously worked hospitals</Text>
        ) : count > 0 ? (
          <Text>No shifts match your criteria</Text>
        ) : (
          <Text>No shifts available in map area</Text>
        )}
        {count > 0 && (
          <Button size="small" onClick={onClick}>
            Clear Filters
          </Button>
        )}
      </VStack>
    </EmptyListContainer>
  );
};
