import styled from "styled-components";

import {
  SmallMediumBodyText,
  grayBase,
  redBase,
  smallMediaBreakpoint,
  spacingM,
} from "../../styles/constants";

export const TextStyled = styled.span<{ $error: boolean }>`
  ${SmallMediumBodyText};
  color: ${(props) => (props.$error ? redBase : grayBase)};
`;

export const RatingContainer = styled.div`
  text-align: center;
  grid-gap: 24px;
  margin-top: ${spacingM};
  max-width: 300px;
  @media (max-width: ${smallMediaBreakpoint}) {
    margin: ${spacingM} auto 0;
  }
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    max-width: calc(50% - ${spacingM});
  }
  span:first-of-type {
    text-align: left;
  }
  span:last-of-type {
    text-align: right;
  }
`;

export const RatingOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface RatingOptionProps {
  selected: boolean;
  $error: boolean;
}

export const RatingOption = styled.div<RatingOptionProps>`
  ${(props) => {
    if (props.$error) {
      return `
          svg {
            color: ${redBase} !important;
          }
       `;
    }
    return "";
  }};
`;
