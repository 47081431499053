import {
  Container,
  FlexLayout,
  StepperSteps,
  grayBackground,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
  spacingS,
  spacingXXL,
} from "@RooUI";
import styled from "styled-components";

export const MarsNDAContainer = styled(Container)`
  margin-bottom: 0;
  .text-container {
    p {
      display: inline-block;
    }
  }
`;

export const ScrollArea = styled.div`
  overflow-y: auto;
  min-height: 200px;
  max-height: 25vh;
  border: 2px solid ${grayBackground};
  padding: ${spacingXXL};
  .heading-container {
    display: flex;
    flex-direction: column;
    gap: ${spacingS};
    align-items: flex-start;
  }
  .contract-container {
    display: flex;
    flex-direction: column;
    gap: ${spacingXXL};
    padding-top: ${spacingM};
  }
`;

export const NDAModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacingXXL};
  .logo-container,
  .input-container {
    display: flex;
    flex-flow: row nowrap;
  }
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 44px 0 44px;
  }
  .logo-container {
    height: 42px;
    overflow: hidden;
    align-items: center;
    gap: ${spacingM};
    img {
      height: 30px;
      width: 138px;
    }
    img:nth-child(2) {
      height: 56px;
    }
  }
  .input-container {
    width: 100%;
    gap: ${spacingM};
    > * {
      width: 100%;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    gap: ${spacingL};
    @media (max-width: ${smallMediaBreakpoint}) {
      flex-direction: column-reverse;
    }
  }
  .text-container {
    p {
      display: inline;
    }
  }
`;

export const StepperContentContainer = styled(FlexLayout)`
  padding: 0 44px 44px 44px;
  width: 100%;
`;

export const StyledStepperSteps = styled(StepperSteps)`
  display: flex;
  width: 100%;

  > * {
    flex: 1;
    text-align: center;
  }
`;
