import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Register the custom parse format plugin
dayjs.extend(customParseFormat);

export const TIME_FORMAT = "h:mm a";

export const parseTime = (time?: string) => {
  if (!time) {
    return undefined;
  }

  const parsed = dayjs(time.toLowerCase(), TIME_FORMAT, true);

  if (!parsed.isValid()) {
    return undefined;
  }

  return dayjs().hour(parsed.hour()).minute(parsed.minute()).second(0).millisecond(0);
};
