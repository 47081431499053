import { createGlobalStyle } from "styled-components";

export const ModalHeaderOffsetStyles = createGlobalStyle<{ $headerHeight: number }>`
  .ant-modal-root {
    .ant-modal-mask {
      top: ${({ $headerHeight }) => $headerHeight}px;
    }
    
    .ant-modal-wrap {
      top: ${({ $headerHeight }) => $headerHeight}px;
    }
  }
`;
