import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Control, useFormContext } from "react-hook-form";

import { ControlledInput } from "../../Controllers";
import { mapsLoader } from "../../mapUtils";
import { useStates } from "../hooks/useStates";
import { optionsAddressComponents } from "./options";

interface AddressParts {
  streetNumber: string;
  route: string;
  city: string;
  state: string;
  stateId: number;
  zipcode: string;
  fullAddress: string;
}

interface SingleInputAddressAutocompleteProps {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  isDuplicateAddressError?: boolean;
  setIsDuplicateAddressError?: (value: boolean) => void;
  labelPrefix?: string;
  onAddressSelected?: (addressParts: AddressParts) => void;
  error?: string;
}

interface AutocompleteState {
  autocomplete: google.maps.places.Autocomplete | null;
  listener: google.maps.MapsEventListener | null;
}

export const SingleInputAddressAutocomplete = ({
  control,
  name,
  defaultValue = "",
  labelPrefix = "",
  onAddressSelected,
  error,
}: SingleInputAddressAutocompleteProps) => {
  const [{ autocomplete, listener }, setAutocompleteState] = useState<AutocompleteState>({
    autocomplete: null,
    listener: null,
  });
  const { stateOptions, loading } = useStates();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!loading) {
      mapsLoader
        .load()
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(
          (google: {
            maps: {
              places: {
                Autocomplete: new (
                  arg0: HTMLInputElement,
                  arg1: { fields: string[]; strictBounds: boolean; types: string[] }
                ) => any;
              };
            };
          }) => {
            const inputElement = document.getElementById(
              "single-autocomplete-address"
            ) as HTMLInputElement;
            const ac = new google.maps.places.Autocomplete(inputElement, optionsAddressComponents);
            const acListener = ac.addListener("place_changed", async () => {
              const place = ac.getPlace();
              const addressComponents = place.address_components.reduce(
                (
                  acc: { [key: string]: string },
                  addressComponent: { long_name: string; types: string[] }
                ) => {
                  acc[addressComponent.types[0]] = addressComponent["long_name"];
                  return acc;
                },
                {}
              );
              const stateId = stateOptions?.find(
                (state) => state.label === addressComponents["administrative_area_level_1"]
              )?.id;
              if (
                addressComponents["street_number"] &&
                addressComponents["route"] &&
                addressComponents["locality"] &&
                addressComponents["administrative_area_level_1"] &&
                addressComponents["postal_code"]
              ) {
                setValue(
                  name,
                  `${addressComponents["street_number"] || ""} ${addressComponents["route"]}, ${
                    addressComponents["locality"]
                  }, ${addressComponents["administrative_area_level_1"]} ${
                    addressComponents["postal_code"]
                  } `.trim(),
                  { shouldValidate: true }
                );
                if (onAddressSelected) {
                  onAddressSelected({
                    streetNumber: addressComponents["street_number"],
                    route: addressComponents["route"],
                    city: addressComponents["locality"],
                    state: addressComponents["administrative_area_level_1"],
                    stateId: stateId || 0,
                    zipcode: addressComponents["postal_code"],
                    fullAddress: `${addressComponents["street_number"] || ""} ${
                      addressComponents["route"]
                    }`.trim(),
                  });
                }
              }
            });
            setAutocompleteState({ autocomplete: ac, listener: acListener });
          }
        )
        .catch((error: any) => {
          Sentry.captureException(error, {
            extra: { context: "Error loading Google Maps API: " },
          });
        });
      return () => {
        autocomplete?.unbindAll();
        listener?.remove();
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <ControlledInput
      control={control}
      label={`${labelPrefix} ${labelPrefix ? "address" : "Address"}`}
      id="single-autocomplete-address"
      name={name}
      error={error}
      defaultValue={defaultValue || undefined}
    />
  );
};
