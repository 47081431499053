import { Button } from "@RooUI";
import React from "react";

import { VStack } from "@RooBeta/components";

import { useShiftCancellation } from "./useShiftCancellation";

type ShiftCancellationFooterProps = {
  onClick: () => void;
  isLoading: boolean;
};

export const ShiftCancellationFooter = ({ onClick, isLoading }: ShiftCancellationFooterProps) => {
  const {
    isCancellationPolicyAcknowledgementRequired,
    isCancellationRequestRequired,
    setIsCancellationPolicyAcknowledged,
  } = useShiftCancellation();

  return (
    <VStack $gap="m" $padding="m">
      {isCancellationPolicyAcknowledgementRequired ? (
        <Button
          $buttonType="primary"
          onClick={() => setIsCancellationPolicyAcknowledged(true)}
          style={{ width: "100%" }}
        >
          Acknowledge And Continue
        </Button>
      ) : (
        <Button
          $buttonType="primary"
          onClick={onClick}
          loading={isLoading}
          style={{ width: "100%" }}
        >
          {isCancellationRequestRequired ? "Submit cancellation request" : "Confirm cancellation"}
        </Button>
      )}
    </VStack>
  );
};
