import { Button, FlexLayout } from "@RooUI";
import React from "react";

import { HStack, VStack } from "@RooBeta/components";

import { ShiftPrice } from "./ShiftPrice";
import { Skeleton } from "antd";

type ShiftAdjustmentsFooterProps = {
  price?: string;
  previousPrice?: string | null;
  onCancel: () => void;
  onRequest: () => void;
  isLoading: boolean;
  isPricingLoading: boolean;
};

export const ShiftAdjustmentsFooter = ({
  price,
  previousPrice,
  onCancel,
  onRequest,
  isLoading,
  isPricingLoading,
}: ShiftAdjustmentsFooterProps) => (
  <VStack $gap="m" $padding="m">
    {isPricingLoading ? (
      <FlexLayout justify="center" align="center" data-testid="pricingSkeleton">
        <Skeleton.Input active size="small" />
      </FlexLayout>
    ) : (
      <ShiftPrice price={price} previousPrice={previousPrice} />
    )}
    <HStack $gap="m">
      <Button
        $buttonType="secondary"
        style={{ flex: 1 }}
        onClick={onCancel}
        disabled={isLoading}
        data-testid="cancelButton"
      >
        Cancel
      </Button>
      <Button
        $buttonType="primary"
        style={{ flex: 1 }}
        onClick={onRequest}
        loading={isLoading}
        disabled={isPricingLoading || isLoading}
        data-testid="requestButton"
      >
        Request
      </Button>
    </HStack>
  </VStack>
);
