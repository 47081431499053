import { useQuery } from "@tanstack/react-query";

import { queryClient } from "../../api/reactQueryClient";
import { fetchHospitalAvailableVetShifts } from "../../api/shift";
import { fetchHospitalAvailableTechShifts } from "../../api/techShift";
import { CACHE } from "../../api/utility/constants";

interface HospitalAvailableShiftsParams {
  vetId?: number;
  techId?: number;
  hospitalId: number;
  latitude: number;
  longitude: number;
}

export const invalidateHospitalAvailableShiftsCache = async ({
  vetId,
  techId,
  hospitalId,
}: Omit<HospitalAvailableShiftsParams, "longitude" | "latitude">) =>
  queryClient.invalidateQueries({
    queryKey: [CACHE.AVAILABLE_HOSPITAL_SHIFTS, vetId, techId, hospitalId],
  });

export const useHospitalAvailableShifts = ({
  vetId,
  techId,
  hospitalId,
  latitude,
  longitude,
}: HospitalAvailableShiftsParams) =>
  useQuery(
    [CACHE.AVAILABLE_HOSPITAL_SHIFTS, vetId, techId, hospitalId],
    () =>
      !!vetId
        ? fetchHospitalAvailableVetShifts(hospitalId, vetId, latitude, longitude)
        : fetchHospitalAvailableTechShifts(hospitalId, techId, latitude, longitude),
    {
      enabled: !!hospitalId && (!!vetId || !!techId),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
