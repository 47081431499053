import { Link } from "@RooUI";
import React from "react";

import { HStack, RooIcon } from "@RooBeta/components";

type HospitalAddressProps = {
  address: string;
};

export const HospitalAddress = ({ address }: HospitalAddressProps) => (
  <HStack $alignItems="center" $gap="xs">
    <RooIcon icon="pin" />
    <Link
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
      target="_blank"
      rel="noopener noreferrer"
      linkSize="small"
    >
      {address}
    </Link>
  </HStack>
);
