import {
  Button,
  Input,
  Link,
  MediumText,
  SmallText,
  Stepper,
  StepperContent,
  StepperNext,
  StepperPrevious,
  StepperStep,
  Text,
} from "@RooUI";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { ControlledInput, ControlledPhoneInput } from "@Roo/Common/Controllers";
import { useStates } from "@Roo/Common/Form/hooks/useStates";
import { openZendeskChat } from "@Roo/Common/zendeskUtils";
import BanfieldLogo from "@Roo/HospitalProfilePanels/MarsNDA/Logos/Banfield.png";
import BluePearlLogo from "@Roo/HospitalProfilePanels/MarsNDA/Logos/BluePearl.png";
import { MarsContract } from "@Roo/HospitalProfilePanels/MarsNDA/MarsContract";
import {
  NDAModalContainer,
  ScrollArea,
  StepperContentContainer,
  StyledStepperSteps,
} from "@Roo/HospitalProfilePanels/MarsNDA/styles";
import { useContractSignatureMutation, useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { Event, EventTracker } from "@RooBeta/types";

export interface FormData {
  date: string;
  fullName: string;
  homeAddress: string;
  phoneNumber: string;
}

export interface NDAFormProps {
  enterpriseContractId: number;
  shiftId: number;
  isInstantBookable: boolean;
  onClose: () => void;
  onSignature: () => Promise<void>;
}

export const NDAForm = ({
  enterpriseContractId,
  shiftId,
  isInstantBookable,
  onClose,
  onSignature,
}: NDAFormProps) => {
  const { address, city, stateId, zipcode, phoneNumber, provider, isSuperUser } =
    useRequiredAuthorizedUser();

  const { stateOptions } = useStates();
  const stateCode = stateOptions.find((state) => state.id === stateId)?.stateCode;

  const [stepperStep, setStepperStep] = useState<number>(0);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { date: "", fullName: "", homeAddress: "", phoneNumber: "" },
    mode: "onBlur",
  });

  const { fullName, homeAddress, phoneNumber: formPhoneNumber } = watch();

  const onCloseModal = (source: string, step: number) => {
    setStepperStep(0);

    EventTracker.send({
      eventName:
        step === 0 ? Event.Name.OVERVIEW_AGREEMENT_DISMISS : Event.Name.EXIT_AGREEMENT_MODAL,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET_SHIFT,
      entityId: shiftId,
      context: { source, marsNDAFlowV2: true, isNewExperience: true },
    });

    onClose();
  };

  useEffect(() => {
    setValue("date", moment(new Date()).format("MMM. D, YYYY"));
  }, [setValue]);

  useEffect(() => {
    setValue(
      "homeAddress",
      address && city && stateCode && zipcode ? `${address}, ${city}, ${stateCode}, ${zipcode}` : ""
    );

    setValue("phoneNumber", phoneNumber ?? "");
  }, [phoneNumber, address, city, stateCode, zipcode, setValue]);

  const contractSignature = useContractSignatureMutation();

  const onSubmit = handleSubmit(
    async ({ fullName: signature, homeAddress: address, phoneNumber }) => {
      await contractSignature.mutateAsync({
        enterpriseContractId,
        signature,
        address,
        phoneNumber,
      });

      EventTracker.send({
        eventName: Event.Name.SIGN_AGREEMENT,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.VET_SHIFT,
        entityId: shiftId,
        context: { marsNDAFlowV2: true, isNewExperience: true },
      });

      setStepperStep(0);
      await onSignature();
      onClose();
    }
  );

  return (
    <NDAModalContainer onSubmit={onSubmit}>
      <div className="header-container" data-testid="marsHeaderContainer">
        <SmallText>Mars Veterinary Health</SmallText>
        <div className="logo-container">
          <img src={BluePearlLogo} alt="BluePearl Logo" />
          <img src={BanfieldLogo} alt="Banfield Logo" />
        </div>
      </div>
      <Stepper count={2} step={stepperStep} onStepChange={setStepperStep}>
        <StyledStepperSteps>
          <StepperStep step={0} data-testid="marsOverviewStep">
            Overview
          </StepperStep>
          <StepperStep step={1} data-testid="marsReviewAgreementStep">
            Review Agreement
          </StepperStep>
        </StyledStepperSteps>
        <StepperContent step={0}>
          <StepperContentContainer vertical gap="xxl">
            <Text data-testid="marsOverviewText1">
              This agreement does NOT restrict you from working elsewhere, make you an employee of
              MVH, or limit professional communication with MVH clients. We’re excited to have you
              join Mars Veterinary Health (MVH)!
            </Text>
            <Text data-testid="marsOverviewText2">
              This agreement between you and MVH, requires you as an independent contractor to (1)
              protect MVH confidential information, (2) not take business away from MVH by
              soliciting our existing clients, (3) not work overtime without hospital approval, and
              (4) to maintain appropriate licensure.
            </Text>
            <div className="button-container">
              <StepperNext
                size="small"
                data-testid="marsOverviewContinueButton"
                onClick={() => {
                  EventTracker.send({
                    eventName: Event.Name.OVERVIEW_AGREEMENT_CONTINUE,
                    eventType: Event.Type.CLICK,
                    entityType: Event.Entity.VET_SHIFT,
                    entityId: shiftId,
                    context: { marsNDAFlowV2: true, isNewExperience: true },
                  });
                }}
              />
            </div>
          </StepperContentContainer>
        </StepperContent>
        <StepperContent step={1}>
          <StepperContentContainer vertical gap="xxl">
            <div className="text-container">
              <Text data-testid="marsHeaderText">
                Starting 10/30/24, Mars Veterinary Health Group is requiring vets to digitally sign
                the agreement below to request relief shifts at Banfield or BluePearl hospitals (not
                required for other hospitals).{" "}
                <MediumText>
                  This is not a Roo requirement.{" "}
                  <Link
                    href="https://5920186.hs-sites.com/mars-vsa-faq"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      EventTracker.send({
                        eventName: Event.Name.REVIEW_AGREEMENT_LEARN_MORE,
                        eventType: Event.Type.CLICK,
                        entityType: Event.Entity.VET_SHIFT,
                        entityId: shiftId,
                        context: { marsNDAFlowV2: true, isNewExperience: true },
                      });
                    }}
                  >
                    Learn more
                  </Link>
                </MediumText>
                .
              </Text>
            </div>
            <div className="text-container">
              <Text>
                Questions about the agreement? Contact{" "}
                <Link onClick={() => (window.location.href = "mailto:CommercialTeam@banfield.com")}>
                  CommercialTeam@banfield.com
                </Link>
                . For other questions please,{" "}
                <Link
                  onClick={() => {
                    onCloseModal("contactRoo", stepperStep);
                    openZendeskChat();
                  }}
                >
                  contact Roo
                </Link>
                .
              </Text>
            </div>
            <ScrollArea data-testid="marsContract">
              <MarsContract isHospitalView={false} vetId={provider.providerId} />
            </ScrollArea>
            <Text data-testid="marsContractText">
              To request Banfield or BluePearl shifts, please fill in the fields below and confirm
              your personal info to sign Mars group's agreement and submit your shift request. You
              only need to sign this once.
            </Text>
            <ControlledInput
              label="Full name"
              name="fullName"
              control={control}
              rules={{
                required: "Required",
                validate: (value) =>
                  value.length <= 255 || "Full name must be 255 characters or less",
              }}
              error={errors.fullName?.message}
              disabled={isSuperUser}
              data-testid="marsFullName"
            />
            <ControlledInput
              label="Home address"
              name="homeAddress"
              control={control}
              rules={{
                required: "Required",
                validate: (value) =>
                  value.length <= 400 || "Home address must be 400 characters or less",
              }}
              error={errors.homeAddress?.message}
              disabled={isSuperUser}
              data-testid="marsHomeAddress"
            />
            <div className="input-container">
              <ControlledPhoneInput
                label="Phone number"
                name="phoneNumber"
                control={control}
                rules={{
                  required: "Required",
                  validate: (value) => {
                    const digitsOnly = value.replace(/\D/g, "");
                    return digitsOnly.length === 10 || "Phone number must be 10 digits";
                  },
                }}
                error={errors.phoneNumber?.message}
                disabled={isSuperUser}
                data-testid="marsPhoneNumber"
              />
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <Input label="Date" disabled {...field} data-testid="marsDate" />
                )}
              />
            </div>
            <div className="button-container">
              <StepperPrevious
                size="small"
                onClick={() => {
                  EventTracker.send({
                    eventName: Event.Name.REVIEW_AGREEMENT_BACK,
                    eventType: Event.Type.CLICK,
                    entityType: Event.Entity.VET_SHIFT,
                    entityId: shiftId,
                    context: { marsNDAFlowV2: true, isNewExperience: true },
                  });
                }}
              />
              <Button
                disabled={
                  !fullName?.length ||
                  fullName?.length < 4 ||
                  !homeAddress?.length ||
                  !formPhoneNumber?.length ||
                  isSuperUser
                }
                $buttonType="primary"
                type="submit"
                size="small"
                data-testid="marsSignAndRequestButton"
                loading={contractSignature.isLoading}
              >
                {isInstantBookable ? "Sign & Book Now" : "Sign & Request"}
              </Button>
            </div>
          </StepperContentContainer>
        </StepperContent>
      </Stepper>
    </NDAModalContainer>
  );
};
