import { RadioGroup, RadioGroupProps } from "@RooUI";
import React from "react";

import { USER_TYPE } from "../../constants/UserTypeConstants";

type UserTypeRadioGroupProps = Omit<RadioGroupProps, "radios">;

export const UserTypeRadioGroup: React.FC<UserTypeRadioGroupProps> = (props) => {
  const type = "informational";
  const USER_TYPE_RADIOS: RadioGroupProps["radios"] = [
    {
      value: USER_TYPE.VET_USER_TYPE,
      label: "Veterinarian",
      type,
      icon: "OutlineVaccines",
      id: "signUpVet",
    },
    {
      value: USER_TYPE.TECH_USER_TYPE,
      label: "Vet Tech/Asst.",
      type,
      icon: "Pets",
      id: "signUpTech",
    },
    {
      value: USER_TYPE.STUDENT_USER_TYPE,
      label: "Student",
      type,
      icon: "School",
      id: "signUpStudent",
    },
    {
      value: USER_TYPE.HOSPITAL_USER_TYPE,
      label: "Hospital",
      type,
      icon: "LocalHospital",
      id: "signUpHospital",
    },
  ];

  return <RadioGroup radios={USER_TYPE_RADIOS} {...props} type="informational" $shouldWrapRadios />;
};
