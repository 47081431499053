import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import React, { createContext } from "react";

export const NotificationContext = createContext<NotificationInstance | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={api}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
