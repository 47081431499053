import * as C from "../../../constants/techConstants";

interface TechBasicInfo {
  firstName: string;
  lastName: string;
  address: string;
  aptUnitSuite?: string;
  city: string;
  stateId: string | number;
  zipcode: string;
  email: string;
  mobilePhoneNumber: string;
  birthday: string;
  pronouns?: string;
  techId: string;
  userId: string;
}

interface W9Data {
  taxPayerIdType: string;
  taxPayerId: string;
}

interface W9Info {
  techId: string;
  userId: string;
  w9Data: W9Data;
}

interface BlockHospitalModalData {
  isShow: boolean;
}

interface BlockedHospitalData {
  techId?: string | number;
  hospitalId?: string | number;
  userId: string | number;
  internalNote?: string;
  isNewlyAdded?: boolean;
}

interface FetchTechSettingAction {
  type: typeof C.GET_TECH_SETTING_INFORMATION;
  techId: string;
  userTypeId: string | number;
}

interface UpdateTechBasicInfoAction {
  type: typeof C.UPDATE_TECH_BASIC_INFO;
  payload: {
    techInformation: TechBasicInfo;
  };
}

interface InitializeTechSettingsAction {
  type: typeof C.INITIALIZE_TECH_SETTINGS;
}

interface GetW9InfoAction {
  type: typeof C.GET_TECH_W9_INFO;
  techId: string;
  userId: string;
}

interface UpdateW9Action {
  type: typeof C.UPDATE_TECH_W9;
  payload: W9Info;
}

interface ResetTechBasicInfoUpdateSuccessAction {
  type: typeof C.RESET_TECH_BASIC_INFO_UPDATE_SUCCESS;
}

interface ResetTechW9UpdateSuccessAction {
  type: typeof C.RESET_TECH_W9_UPDATE_SUCCESS;
}

interface GetBlockedHospitalListAction {
  type: typeof C.GET_BLOCKED_HOSPITAL_LIST;
  payload: {
    techId: string;
    userId: string;
  };
}

interface RemoveBlockedHospitalAction {
  type: typeof C.REMOVE_BLOCKED_HOSPITAL;
  payload: BlockedHospitalData;
}

interface BlockHospitalAction {
  type: typeof C.BLOCK_HOSPITAL;
  payload: BlockedHospitalData;
}

interface HideBlockHospConfirmationModalAction {
  type: typeof C.HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL;
  payload: BlockHospitalModalData;
}

export function fetchTechSettingInformationAction(
  techId: string,
  userTypeId: string | number
): FetchTechSettingAction {
  return {
    type: C.GET_TECH_SETTING_INFORMATION,
    techId,
    userTypeId,
  };
}

export function updateTechBasicInfoAction(
  techInformation: TechBasicInfo
): UpdateTechBasicInfoAction {
  return {
    type: C.UPDATE_TECH_BASIC_INFO,
    payload: {
      techInformation,
    },
  };
}

export function initializeTechSettingsAction(): InitializeTechSettingsAction {
  return {
    type: C.INITIALIZE_TECH_SETTINGS,
  };
}

export function getW9InfoAction(techId: string, userId: string): GetW9InfoAction {
  return {
    type: C.GET_TECH_W9_INFO,
    techId,
    userId,
  };
}

export function updateW9Action(oW9: W9Info): UpdateW9Action {
  return {
    type: C.UPDATE_TECH_W9,
    payload: oW9,
  };
}

export function resetTechBasicInfoUpdateSuccessAction(): ResetTechBasicInfoUpdateSuccessAction {
  return {
    type: C.RESET_TECH_BASIC_INFO_UPDATE_SUCCESS,
  };
}

export function resetTechW9UpdateSuccessAction(): ResetTechW9UpdateSuccessAction {
  return {
    type: C.RESET_TECH_W9_UPDATE_SUCCESS,
  };
}

export function getBlockedHospitalListAction(data: {
  techId: string;
  userId: string;
}): GetBlockedHospitalListAction {
  return {
    type: C.GET_BLOCKED_HOSPITAL_LIST,
    payload: data,
  };
}

export function removeBlockedHospitalAction(
  data: BlockedHospitalData
): RemoveBlockedHospitalAction {
  return {
    type: C.REMOVE_BLOCKED_HOSPITAL,
    payload: data,
  };
}

export function blockHospitalAction(data: BlockedHospitalData): BlockHospitalAction {
  return {
    type: C.BLOCK_HOSPITAL,
    payload: data,
  };
}

export function hideBlockHospConfirmationModalAction(
  data: BlockHospitalModalData
): HideBlockHospConfirmationModalAction {
  return {
    type: C.HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL,
    payload: data,
  };
}
