import { FlexLayout } from "@RooUI";
import { Skeleton } from "antd";
import React from "react";

import { FlexLayoutStyled } from "./styles";

interface ShiftMetadataLoadingProps {
  count: number;
}

export const ShiftMetadataLoading = ({ count }: ShiftMetadataLoadingProps) => (
  <FlexLayout vertical gap="m">
    {Array.from({ length: count }).map((_, index) => (
      <FlexLayout key={index} gap="s">
        <Skeleton.Avatar active />
        <FlexLayoutStyled vertical gap="s">
          <Skeleton.Input active size="small" style={{ width: "100%" }} />
          <Skeleton.Input active size="small" style={{ width: "100%" }} />
        </FlexLayoutStyled>
      </FlexLayout>
    ))}
  </FlexLayout>
);
