import React from "react";

import { GroupedShiftList, UngroupedShiftList } from "@RooBeta/types";

import { EmptyList } from "./EmptyList";
import { FlatShiftList as FlatShiftListComponent } from "./FlatShiftList";
import { GroupedShiftList as GroupedShiftListComponent } from "./GroupedShiftList";

type ShiftListProps = {
  shiftList: GroupedShiftList | UngroupedShiftList;
  fixedHeight?: boolean;
};

export const ShiftList = ({ shiftList, fixedHeight = true }: ShiftListProps) =>
  shiftList.count === 0 ? (
    <EmptyList />
  ) : shiftList.format === "grouped" ? (
    <GroupedShiftListComponent shiftList={shiftList} fixedHeight={fixedHeight} />
  ) : (
    <FlatShiftListComponent shiftList={shiftList} fixedHeight={fixedHeight} />
  );
