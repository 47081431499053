import { cloneDeep, find, stubTrue } from "lodash";

import * as C from "../../../constants/hospitalConstants";

const initialState = {
  hospitalInformation: {
    aptUnitSuite: null,
    city: "",
    email: "",
    fullName: "",
    hospitalAddress: "",
    hospitalName: "",
    hospitalWebsite: "",
    phoneNumber: "",
    roleId: null,
    stateId: null,
    zipcode: "",
    stripeCustomerId: "",
    mobilePhoneNumber: "",
    billingEmails: "",
    hospitalId: null,
    stripeSetupIntentStatus: null,
    stripeVerifyMdHostedUrl: null,
  },
  myAccountInfo: {
    fullName: "",
    emailId: "",
    phoneNumber: "",
  },
  defaultCard: "",
  paymentInformation: [],
  autoConfirmList: [],
  recommendedVetOrTechList: [],
  isInfoFetched: false,
  isCardAdded: false,
  isCardDeleted: false,
  isDefaultCardSuccess: false,
  isUpdateBillingEmailsSuccess: false,
  isUpdateBillingEmailsFail: false,
  isPrimaryContactChanged: false,
  blockedVetList: [],
  blockedTechList: [],
  blockedVetCount: 0,
  blockedTechCount: 0,
  isShowBlockingVetSuccessModal: false,
  isShowBlockingTechSuccessModal: false,
};

// eslint-disable-next-line default-param-last
const hospitalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_HOSPITAL_SETTING_INFO:
      return {
        ...state,
        isInfoFetched: false,
      };

    case C.FETCH_HOSPITAL_SETTING_INFO_SUCCESS:
      let data = {
        isInfoFetched: true,
        hospitalInformation: {
          hospitalId: action.payload?.hospitalId ?? null,
          hospitalUserId: action.payload.hospitalInformation.userId,
          ...action.payload.hospitalInformation,
        },
        defaultCard: action.payload.defaultCard,
        paymentInformation: action.payload.paymentInformation,
      };

      if (!action?.payload?.getPaymentOnly) {
        data = {
          ...data,
          autoConfirmList: action.payload.autoConfirmList.map((o) => {
            return { ...o, isDisabled: o.isDisabled === "1" ? true : false };
          }),
          recommendedVetOrTechList: action.payload.recommendedVetOrTechList,
          blockedVetList: action.payload.blockedVetList,
          blockedTechList: action.payload.blockedTechList,
        };
      }
      return { ...state, ...data };

    case C.FETCH_HOSPITAL_SETTING_INFO_FAIL:
      return {
        ...state,
      };

    case C.FETCH_MY_ACCOUNT_SETTING_INFO_SUCCESS:
      return {
        ...state,
        myAccountInfo: cloneDeep(action.payload),
      };

    case C.UPDATE_MY_ACCOUNT_SETTING_INFO_SUCCESS:
      return {
        ...state,
        myAccountInfo: {
          ...state.myAccountInfo,
          ...action.payload,
        },
      };

    case C.ADD_HOSPITAL_CARD_REQUEST:
      return { ...state, isCardAdded: false };

    case C.ADD_HOSPITAL_CARD_SUCCESS:
      const defaultCard = action.payload.cardId
        ? action.payload.cardId
        : state.paymentInformation.length > 0
        ? state.defaultCard
        : action.payload.paymentInformation[0].id;
      return {
        ...state,
        isCardAdded: true,
        hospitalInformation: {
          ...state.hospitalInformation,
          stripeCustomerId: action.payload.stripeCustomerId, // update in case adding card and creating customer
        },
        defaultCard: defaultCard,
        paymentInformation: action.payload.paymentInformation,
      };

    case C.DELETE_HOSPITAL_CARD_REQUEST:
      return { ...state, isCardDeleted: false };

    case C.DELETE_HOSPITAL_CARD_SUCCESS:
      return {
        ...state,
        paymentInformation: action.payload.paymentInformation,
        isCardDeleted: true,
      };

    case C.UPDATE_HOSPITAL_SETTING_INFORMATION_SUCCESS:
      localStorage.setItem("hospitalStateId", action.payload.updatedInfo.stateId);
      return {
        ...state,
        hospitalInformation: {
          ...state.hospitalInformation,
          hospitalUserId: action.payload.updatedInfo.userId,
          aptUnitSuite: action.payload.updatedInfo.aptUnitSuite,
          city: action.payload.updatedInfo.city,
          fullName: action.payload.updatedInfo.fullName,
          hospitalAddress: action.payload.updatedInfo.hospitalAddress,
          hospitalName: action.payload.updatedInfo.hospitalName,
          hospitalWebsite: action.payload.updatedInfo.hospitalWebsite,
          phoneNumber: action.payload.updatedInfo.phoneNumber,
          roleId: action.payload.updatedInfo.roleId,
          stateId: action.payload.updatedInfo.stateId,
          zipcode: action.payload.updatedInfo.zipcode.toString(),
          mobilePhoneNumber: action.payload.updatedInfo.mobilePhoneNumber,
        },
      };

    case C.UPDATE_BILLING_EMAILS:
      return { ...state, isUpdateBillingEmailsSuccess: false };

    case C.UPDATE_BILLING_EMAILS_SUCCESS:
      return {
        ...state,
        isUpdateBillingEmailsSuccess: true,
        hospitalInformation: {
          ...state.hospitalInformation,
          billingEmails: action.payload.updatedInfo.billingEmails,
        },
      };

    case C.UPDATE_BILLING_EMAILS_FAIL:
      return {
        ...state,
        isUpdateBillingEmailsFail: true,
      };

    case C.CHANGE_PRIMARY_CONTACT:
      return { ...state, isPrimaryContactChanged: false };

    case C.CHANGE_PRIMARY_CONTACT_SUCCESS:
      return {
        ...state,
        isPrimaryContactChanged: true,
        newPrimaryUserId: action.payload?.newPrimaryUserId,
      };

    case C.RESET_UPDATE_BILLING_EMAILS_FLAG:
      return {
        ...state,
        isUpdateBillingEmailsSuccess: false,
      };

    case C.SET_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        defaultCard: action.payload.defaultCard,
        isDefaultCardSuccess: true,
      };

    case C.DELETE_FUTURE_AUTO_CONFIRM_SUCCESS: {
      const idProp = Boolean(action.payload.vetId) ? "vetId" : "techId";
      const autoConfirmListAfterDeleted = state.autoConfirmList.filter(
        (vetOrTech) => vetOrTech[idProp] !== action.payload[idProp]
      );

      const recommendedVetOrTechListAfterDeleted = action.payload.isRecommended
        ? state.recommendedVetOrTechList.filter((vetOrTech) => {
            return vetOrTech[idProp] !== action.payload[idProp];
          })
        : state.recommendedVetOrTechList;

      return {
        ...state,
        autoConfirmList: autoConfirmListAfterDeleted,
        recommendedVetOrTechList: recommendedVetOrTechListAfterDeleted,
      };
    }

    case C.DISABLE_FUTURE_AUTO_CONFIRM_SUCCESS:
      const autoConfirmListContractorDisabled = state.autoConfirmList.map((o) => {
        if (
          (o.vetId && o.vetId === action.payload.vetId) ||
          (o.techId && o.techId === action.payload.techId)
        ) {
          return { ...o, isDisabled: true };
        } else {
          return o;
        }
      });
      return {
        ...state,
        autoConfirmList: autoConfirmListContractorDisabled,
      };

    case C.ENABLE_FUTURE_AUTO_CONFIRM_SUCCESS: {
      const autoConfirmListContractorEnabled = state.autoConfirmList.map((vetOrTech) => {
        if (
          (vetOrTech?.vetId && vetOrTech.vetId === action.payload.vetId) ||
          (vetOrTech?.techId && vetOrTech.techId === action.payload.techId)
        ) {
          return { ...vetOrTech, isDisabled: false };
        } else {
          return vetOrTech;
        }
      });

      let updatedRecommendedVetOrTechList = state.recommendedVetOrTechList;
      if (action.payload.isRecommended) {
        const enabledVet = state.recommendedVetOrTechList.find(
          (vetOrTech) =>
            vetOrTech.vetId === action.payload.vetId && vetOrTech.techId === action.payload.techId
        );
        autoConfirmListContractorEnabled.push(enabledVet);
        updatedRecommendedVetOrTechList = state.recommendedVetOrTechList.filter((vetOrTech) => {
          return vetOrTech?.vetId !== enabledVet?.vetId || vetOrTech?.techId !== enabledVet?.techId;
        });
      }
      return {
        ...state,
        autoConfirmList: autoConfirmListContractorEnabled,
        recommendedVetOrTechList: updatedRecommendedVetOrTechList,
        isAutoConfirmEnabled: true,
      };
    }

    case C.GET_AUTO_CONFIRM_CONTRACTOR_LIST_SUCCESS: {
      return {
        ...state,
        autoConfirmList: action.payload?.autoConfirmList,
        recommendedVetOrTechList: action.payload?.recommendedVetOrTechList,
      };
    }

    case C.GET_BLOCKED_CONTRACTOR_LIST: {
      return {
        ...state,
        blockedVetList: action?.payload?.isVet || action.payload.vetId ? [] : state.blockedVetList,
        blockedTechList:
          action?.payload?.isTech || action.payload.techId ? [] : state.blockedTechList,
      };
    }

    case C.GET_BLOCKED_CONTRACTOR_LIST_SUCCESS: {
      const key = action.payload.isVet ? "blockedVetList" : "blockedTechList";
      return {
        ...state,
        blockedVetList: action?.payload?.isVet
          ? action.payload.blockedVetList
          : state.blockedVetList,
        blockedTechList: action?.payload?.isTech
          ? action.payload.blockedTechList
          : state.blockedTechList,
        blockedVetCount: state.blockedVetList.length,
        blockedTechCount: state.blockedTechList.length,
      };
    }

    case C.REMOVE_BLOCKED_CONTRACTOR_SUCCESS:
      const blockedListKey = action.payload.vetId ? "blockedVetList" : "blockedTechList";
      const contractorIdKey = action.payload.vetId ? "vetId" : "techId";
      const id = action.payload.vetId || action.payload.techId;

      const updatedList = state[blockedListKey].filter((o) => o[contractorIdKey] !== id);
      return {
        ...state,
        [blockedListKey]: updatedList,
        blockedVetCount: action.payload.vetId ? updatedList.length : state.blockedVetCount,
        blockedTechCount: action.payload.techId ? updatedList.length : state.blockedTechCount,
      };

    case C.BLOCK_CONTRACTOR_SUCCESS:
      const { vetId, techId, internalNote, isNewlyAdded } = action.payload.data;
      const listKey = vetId ? "blockedVetList" : "blockedTechList";
      const showSuccessModalKey = vetId
        ? "isShowBlockingVetSuccessModal"
        : "isShowBlockingTechSuccessModal";
      const idKey = vetId ? "vetId" : "techId";
      const contractorId = vetId || techId;

      const blockedContractorList = Object.assign([], state[listKey]);
      const blockContractorDetails = find(blockedContractorList, { [idKey]: contractorId });
      if (!isNewlyAdded && blockContractorDetails) {
        blockContractorDetails.isNewlyAdded = false;
        blockContractorDetails.internalNote = internalNote;
      } else {
        blockedContractorList.splice(0, 0, action.payload.data);
      }
      return {
        ...state,
        [listKey]: blockedContractorList,
        blockedVetCount: vetId ? blockedContractorList.length : state.blockedVetCount,
        blockedTechCount: techId ? blockedContractorList.length : state.blockedTechCount,
        [showSuccessModalKey]: true,
      };

    case C.SET_BLOCKED_CONTRACTOR_COUNT:
      if (action.payload.isVet) {
        return {
          ...state,
          blockedVetCount: action.payload.blockedVetCount,
        };
      } else {
        return {
          ...state,
          blockedTechCount: action.payload.blockedTechCount,
        };
      }

    case C.UPDATE_BLOCKED_CONTRACTOR_COUNT:
      const blockedVets = state.blockedVetCount;
      const blockedTechs = state.blockedTechCount;
      return {
        ...state,
        blockedVetCount: action.payload.vetId ? blockedVets + 1 : blockedVets,
        blockedTechCount:
          !action.payload.vetId && action.payload.techId ? blockedTechs + 1 : blockedTechs,
      };

    case C.INITIALIZE_HOSPITAL_SETTINGS:
      const blockedVetCount = state.blockedVetCount;
      const blockedTechCount = state.blockedTechCount;
      return {
        ...initialState,
        hospitalInformation: {
          ...initialState.hospitalInformation,
        },
        blockedVetCount,
        blockedTechCount,
        paymentInformation: [],
      };

    case C.HIDE_BLOCK_TECHVET_CONFIRMATION_MODAL:
      const dynamicKey = action?.payload?.vetId
        ? "isShowBlockingVetSuccessModal"
        : "isShowBlockingTechSuccessModal";
      return {
        ...state,
        [dynamicKey]: action.payload.showSuccessModal,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default hospitalSettingsReducer;
