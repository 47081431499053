import { useEffect } from "react";
import { useWindowSize } from "usehooks-ts";

import { BREAKPOINTS } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { ViewportSize } from "@RooBeta/types";

type UseBreakpointsOptions = {
  debounceDelay?: number;
};

export const useViewportSize = (options: UseBreakpointsOptions = {}) => {
  const windowSize = useWindowSize(options);
  const { viewportSize, setViewportSize, setWindowSize } = useViewportStore();

  useEffect(() => {
    setViewportSize(
      BREAKPOINTS.find(({ value }) => windowSize.width <= value)?.key || ViewportSize.l
    );

    setWindowSize(windowSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width, windowSize.height, setViewportSize, setWindowSize]);

  return viewportSize;
};
