import { CollapsePanelProps } from "antd";
import React, { PropsWithChildren } from "react";

import { StyledAccordion, StyledAccordionItem } from "./styles";

const Item = ({ children, ...rest }: CollapsePanelProps) => (
  <StyledAccordionItem {...rest}>{children}</StyledAccordionItem>
);

export type AccordionProps = PropsWithChildren<{
  defaultActiveIds?: string[];
}>;

export const Accordion = ({ children, defaultActiveIds, ...rest }: AccordionProps) => (
  <StyledAccordion defaultActiveKey={defaultActiveIds} {...rest}>
    {children}
  </StyledAccordion>
);

Accordion.Item = Item;
