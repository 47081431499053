import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import { LoaderIcon } from "./LoaderIcon";

type LoaderProps = HTMLAttributes<HTMLDivElement>;

export const Loader = (props: LoaderProps) => (
  <LoaderContainer {...props}>
    <LoaderIcon />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  @supports not (height: 100dvh) {
    height: 100vh;
  }
`;
