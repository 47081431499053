import * as Sentry from "@sentry/react";

import { APIResponse } from "./common/response";
import { get, post } from "./common/rooFetch";

interface ContractStatusResponse {
  contractId: number;
  enterpriseContractId: number;
  userId: number | null;
  signature: string | null;
  phoneNumber: string | null;
  address: string | null;
  ipAddress: string | null;
  createdOn: string | null;
}

export const fetchContractStatus = async (
  userId: number,
  enterpriseId: number,
  contractName: string
): Promise<ContractStatusResponse | null> => {
  try {
    const response = await get<APIResponse<ContractStatusResponse | undefined>>(
      `api/enterprise/contract/signature/${contractName}/${enterpriseId}/${userId}`
    );

    if (!response.data) {
      return null;
    }

    return {
      ...response.data,
      contractId: response.data.enterpriseContractId,
    };
  } catch (error) {
    Sentry.captureException(new Error("Error fetching contract status"), {
      extra: { error },
    });
    return null;
  }
};

export const fetchContractSignature = async (
  userId: number,
  contractName: string
): Promise<ContractStatusResponse | null> => {
  try {
    const response = await get<APIResponse<ContractStatusResponse | undefined>>(
      `api/enterprise/contract/signature/${contractName}/${userId}`
    );

    if (!response.data) {
      return null;
    }

    return {
      ...response.data,
      contractId: response.data.enterpriseContractId,
    };
  } catch (error) {
    Sentry.captureException(new Error("Error fetching contract status"), {
      extra: { error },
    });
    return null;
  }
};

export const submitContract = async (
  userId: number,
  contractId: number,
  signature: string,
  address: string,
  phoneNumber: string
): Promise<void> => {
  try {
    await post<
      {
        enterpriseContractId: number;
        userId: number;
        signature: string;
        address: string;
        phoneNumber: string;
      },
      void
    >(`api/enterprise/contract/signature`, {
      enterpriseContractId: contractId,
      userId,
      signature,
      address,
      phoneNumber,
    });
  } catch (error) {
    Sentry.captureException(new Error("Error submitting contract"), {
      extra: { error, userId, contractId },
    });
    throw error;
  }
};
