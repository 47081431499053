import {
  Button,
  CheckboxGroup,
  FormFields,
  OpenText,
  mediumMediaBreakpoint,
  spacingL,
} from "@RooUI";
import React, { useEffect } from "react";
import styled from "styled-components";

import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import {
  HighCommitmentTopicsToDiscuss,
  LowCommitmentTopicsToDiscuss,
} from "@Roo/constants/checkBoxConstants";
import { ImprovedFiltersGroupWrapper } from "@Roo/RegistrationNew/Components/RegistrationWhyAreYouOnRoo/styles";

import { useFormContext } from "./RegistrationMeetYourRepFormContext";

const OPEN_RESPONSE_MIN_LENGTH = 10;
const OPEN_RESPONSE_MAX_LENGTH = 200;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-top: 32px;
  @media (max-width: ${mediumMediaBreakpoint}) {
    align-self: unset;
    width: 100%;
    margin-top: ${spacingL};
  }
`;

interface RegistrationMeetYourRepFormProps {
  highCommitment: boolean;
}
export const RegistrationMeetYourRepForm = ({
  highCommitment,
}: RegistrationMeetYourRepFormProps) => {
  const {
    onChange,
    errors,
    form: { topicsToDiscuss, topicsToDiscussOpenResponse },
  } = useFormContext();

  const showTopicsToDiscussTextbox = !!topicsToDiscuss.find(
    (option: { id: string; checked: boolean }) => {
      const otherOption = highCommitment
        ? HighCommitmentTopicsToDiscuss.Other
        : LowCommitmentTopicsToDiscuss.Other;
      return option.id === otherOption && option.checked;
    }
  );

  const { t } = useRooTranslation();

  useEffect(() => {
    if (!onChange) {
      return;
    }

    if (!showTopicsToDiscussTextbox) {
      onChange("topicsToDiscussOpenResponse", undefined);
    }
  }, [showTopicsToDiscussTextbox]); // eslint-disable-line react-hooks/exhaustive-deps

  const prefix = highCommitment ? "hc" : "lc";

  return (
    <FormFields>
      <ImprovedFiltersGroupWrapper data-testid="topicsToDiscuss">
        <CheckboxGroup
          label={t(`registration.${prefix}MeetYourRooRep.form.topicsToDiscussLabel`)}
          onChange={(_e, updated) => {
            if (onChange) {
              onChange("topicsToDiscuss", updated);
            }
          }}
          flex
          checkboxes={topicsToDiscuss}
          error={errors?.["topicsToDiscuss"]}
          name="topicsToDiscuss"
        />
        {showTopicsToDiscussTextbox && (
          <OpenText
            placeholder={t("registration.vetPreferences.form.openResponsePlaceholder")}
            $error={errors?.["topicsToDiscussOpenResponse"]}
            minLength={OPEN_RESPONSE_MIN_LENGTH}
            maxLength={OPEN_RESPONSE_MAX_LENGTH}
            onChange={(e) => {
              if (onChange) {
                onChange("topicsToDiscussOpenResponse", e.target.value);
              }
            }}
            value={topicsToDiscussOpenResponse}
            data-testid="topicsToDiscussInput"
          />
        )}
      </ImprovedFiltersGroupWrapper>
    </FormFields>
  );
};

interface RegistrationMeetYourRepFormButtonProps {
  highCommitment: boolean;
}
export const RegistrationMeetYourRepFormButton = ({
  highCommitment,
}: RegistrationMeetYourRepFormButtonProps) => {
  const { onSave, loading } = useFormContext();
  const { t } = useRooTranslation();
  const prefix = highCommitment ? "hc" : "lc";
  return (
    <ButtonContainer>
      <Button
        $buttonType="primary"
        title={t(`registration.${prefix}MeetYourRooRep.form.submitButton`)}
        onClick={onSave}
        loading={loading}
        testId="meetYourRepContinue"
      />
    </ButtonContainer>
  );
};
