import { grayWhite } from "@RooUI";
import { Tabs } from "antd";
import React, { useEffect, useMemo } from "react";
import StickyBox from "react-sticky-box";

import { useShiftDetailsStore, useToastStore } from "@RooBeta/store";

import { HospitalInfo } from "./HospitalInfo";
import { HospitalReviews } from "./HospitalReviews";
import { HospitalShifts } from "./HospitalShifts";
import { ShiftInfo } from "./ShiftInfo";

export const ShiftDetailsTabs = () => {
  const { tab, setTab } = useShiftDetailsStore();
  const { hide } = useToastStore();

  const items = useMemo(() => {
    return [
      { key: "info", label: "Shift Info", children: <ShiftInfo /> },
      { key: "hospital", label: "Hospital", children: <HospitalInfo /> },
      { key: "reviews", label: "Reviews", children: <HospitalReviews /> },
      { key: "shifts", label: "All Shifts", children: <HospitalShifts /> },
    ];
  }, []);

  useEffect(() => {
    return () => hide();
  }, [hide]);

  return (
    <Tabs
      activeKey={tab}
      defaultActiveKey={"info"}
      items={items}
      onChange={setTab}
      renderTabBar={(props, DefaultTabBar) => (
        <StickyBox offsetTop={0} style={{ zIndex: 1, background: grayWhite }}>
          <DefaultTabBar {...props} />
        </StickyBox>
      )}
      centered
    />
  );
};
