import React from "react";

export const MdAlarm = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 32 32"
    width="24px"
    fill="#e8eaed"
    {...props}
  >
    <path
      id="vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.69278 3.52827C8.95313 3.78862 8.95313 4.21073 8.69278 4.47108L3.13807 10.0258C2.87772 10.2861 2.45561 10.2861 2.19526 10.0258C1.93491 9.76544 1.93491 9.34333 2.19526 9.08298L7.74997 3.52827C8.01032 3.26792 8.43243 3.26792 8.69278 3.52827ZM23.3073 3.52827C23.5676 3.26792 23.9897 3.26792 24.2501 3.52827L29.8048 9.08298C30.0651 9.34333 30.0651 9.76544 29.8048 10.0258C29.5444 10.2861 29.1223 10.2861 28.862 10.0258L23.3073 4.47108C23.0469 4.21073 23.0469 3.78862 23.3073 3.52827ZM15.9967 6.69731C9.96008 6.69731 5.06647 11.5909 5.06647 17.6275C5.06647 23.6641 9.96008 28.5577 15.9967 28.5577C22.0332 28.5577 26.9268 23.6641 26.9268 17.6275C26.9268 11.5909 22.0332 6.69731 15.9967 6.69731ZM3.73314 17.6275C3.73314 10.8545 9.2237 5.36398 15.9967 5.36398C22.7696 5.36398 28.2602 10.8545 28.2602 17.6275C28.2602 24.4005 22.7696 29.891 15.9967 29.891C9.2237 29.891 3.73314 24.4005 3.73314 17.6275ZM15.7632 9.58195C16.1314 9.58195 16.4299 9.88043 16.4299 10.2486V17.9167L21.1718 22.1897C21.4453 22.4361 21.4673 22.8577 21.2208 23.1312C20.9743 23.4047 20.5528 23.4267 20.2793 23.1802L15.3169 18.7087C15.1766 18.5823 15.0965 18.4023 15.0965 18.2134V10.2486C15.0965 9.88043 15.395 9.58195 15.7632 9.58195Z"
    />
  </svg>
);
MdAlarm.isCustomSvg = true;
