import * as C from "../../../constants/vetConstants";

interface VetBasicInfo {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zipcode: string;
  stateId: number | string;
  mobilePhoneNumber: string;
  email: string;
  birthday: string;
  pronouns?: string;
  aptUnitSuite?: string;
  vetId: string;
  userId: string;
}

interface W9Data {
  taxPayerIdType: string;
  taxPayerId: string;
}

interface BlockedHospitalData {
  vetId?: string | number;
  hospitalId?: string | number;
  userId: string | number;
  internalNote?: string;
  isNewlyAdded?: boolean;
}

interface GetVetSettingAction {
  type: typeof C.GET_VET_SETTING_INFORMATION;
  vetId: string;
  userTypeId: string;
}

interface UpdateVetBasicInfoAction {
  type: typeof C.UPDATE_VET_BASIC_INFO;
  payload: {
    vetInformation: VetBasicInfo;
  };
}

interface InitializeVetSettingsAction {
  type: typeof C.INITIALIZE_VET_SETTINGS;
}

interface GetW9InfoAction {
  type: typeof C.GET_VET_W9_INFO;
  vetId: string;
  userId: string;
}

interface UpdateW9Action {
  type: typeof C.UPDATE_VET_W9;
  payload: {
    vetId: string;
    userId: string;
    w9Data: W9Data;
  };
}

interface ResetVetBasicInfoUpdateSuccessAction {
  type: typeof C.RESET_VET_BASIC_INFO_UPDATE_SUCCESS;
}

interface ResetVetW9UpdateSuccessAction {
  type: typeof C.RESET_VET_W9_UPDATE_SUCCESS;
}

interface GetBlockedHospitalListAction {
  type: typeof C.GET_BLOCKED_HOSPITAL_LIST;
  payload: {
    vetId: string;
  };
}

interface RemoveBlockedHospitalAction {
  type: typeof C.REMOVE_BLOCKED_HOSPITAL;
  payload: BlockedHospitalData;
}

interface BlockHospitalAction {
  type: typeof C.BLOCK_HOSPITAL;
  payload: BlockedHospitalData;
}

export function fetchVetSettingInformationAction(
  vetId: string,
  userTypeId: string
): GetVetSettingAction {
  return {
    type: C.GET_VET_SETTING_INFORMATION,
    vetId,
    userTypeId,
  };
}

export function updateVetBasicInfoAction(vetInformation: VetBasicInfo): UpdateVetBasicInfoAction {
  return {
    type: C.UPDATE_VET_BASIC_INFO,
    payload: {
      vetInformation,
    },
  };
}

export function initializeVetSettingsAction(): InitializeVetSettingsAction {
  return {
    type: C.INITIALIZE_VET_SETTINGS,
  };
}

export function getW9InfoAction(vetId: string, userId: string): GetW9InfoAction {
  return {
    type: C.GET_VET_W9_INFO,
    vetId,
    userId,
  };
}

export function updateW9Action(oW9: {
  vetId: string;
  userId: string;
  w9Data: W9Data;
}): UpdateW9Action {
  return {
    type: C.UPDATE_VET_W9,
    payload: oW9,
  };
}

export function resetVetBasicInfoUpdateSuccessAction(): ResetVetBasicInfoUpdateSuccessAction {
  return {
    type: C.RESET_VET_BASIC_INFO_UPDATE_SUCCESS,
  };
}

export function resetVetW9UpdateSuccessAction(): ResetVetW9UpdateSuccessAction {
  return {
    type: C.RESET_VET_W9_UPDATE_SUCCESS,
  };
}

export function getBlockedHospitalListAction(data: {
  vetId: string;
}): GetBlockedHospitalListAction {
  return {
    type: C.GET_BLOCKED_HOSPITAL_LIST,
    payload: data,
  };
}

export function removeBlockedHospitalAction(
  data: BlockedHospitalData
): RemoveBlockedHospitalAction {
  return {
    type: C.REMOVE_BLOCKED_HOSPITAL,
    payload: data,
  };
}

export function blockHospitalAction(data: BlockedHospitalData): BlockHospitalAction {
  return {
    type: C.BLOCK_HOSPITAL,
    payload: data,
  };
}
