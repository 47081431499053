import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ActionTypes from "../chatConstants";
import { ConvoInfoResp, GetConvoInfoAction } from "../types/chatType";

export function* getConvoInfo(action: GetConvoInfoAction) {
  try {
    const { hospitalId, vetUserId, hideLoader, hospitalUserId } = action.payload;

    if (!hideLoader) {
      yield put({
        type: ActionTypes.START_CHAT_WINDOW_LOADING,
        payload: { message: "Fetching the conversation's history..." },
      });
    }
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.MESSAGING_API_URL +
        `/api/v2/conversation/info?hospitalId=${hospitalId}&vetUserId=${vetUserId}&hospitalUserId=${hospitalUserId}`
    );

    const responseData: ConvoInfoResp = yield response.json();

    if (responseData?.error?.type === ActionTypes.CONVERSATION_NOT_FOUND_ERROR) {
      yield put({
        type: ActionTypes.GET_CONVO_INFO_FAIL,
        payload: { isUnexpectedError: false },
      });
    } else {
      yield put({
        type: ActionTypes.GET_CONVO_INFO_SUCCESS,
        payload: responseData,
      });

      yield put({
        type: ActionTypes.STOP_CHAT_WINDOW_LOADING,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error in getConvoInfo: " } });

    yield put({
      payload: { isUnexpectedError: true },
      type: ActionTypes.GET_CONVO_INFO_FAIL,
    });
  }
}

export function* createConversation(action: any) {
  try {
    const { shiftGroupId } = action.payload;

    yield put({
      type: ActionTypes.CREATE_CONVERSATION_LOADING,
      payload: { message: "Creating a chat session..." },
    });

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/message/createConversation?shiftGroupId=${shiftGroupId}`
    );

    const responseData: [] = yield response.json();

    yield put({
      type: ActionTypes.CREATE_CONVERSATION_SUCCESS,
      payload: responseData,
    });
    yield put({
      type: ActionTypes.STOP_CREATE_CONVERSATION_LOADING,
    });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error in createConversation: " } });
    yield put({
      type: ActionTypes.CREATE_CONVERSATION_FAIL,
    });
    yield put({
      type: ActionTypes.STOP_CREATE_CONVERSATION_LOADING,
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootChatSaga() {
  yield takeEvery(ActionTypes.GET_CONVO_INFO, getConvoInfo);
  yield takeEvery(ActionTypes.CREATE_CONVERSATION, createConversation);
}
