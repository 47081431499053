import React from "react";
import { IconType } from "react-icons";

import { grayBase } from "../../styles/constants";

import * as iconImport from "./constants";

const icons = iconImport as { [key: string]: IconType };

type IconImports = typeof iconImport;

export type IconName = {
  [K in keyof IconImports]: K extends `Mds${infer Name}`
    ? Name
    : K extends `Md${infer Name}`
    ? Name
    : K extends `Fi${infer Name}`
    ? Name
    : K extends `Ti${infer Name}`
    ? Name
    : K extends `Ai${infer Name}`
    ? Name
    : K extends `Ico${infer Name}`
    ? Name
    : never;
}[keyof IconImports];

export const ICON_NAMES = Object.keys(icons).map((key) => {
  if (key.startsWith("Mds")) {
    return key.slice(3);
  } else if (key.startsWith("Md")) {
    return key.slice(2);
  } else if (key.startsWith("Fi")) {
    return key.slice(2);
  } else if (key.startsWith("Ti")) {
    return key.slice(2);
  } else if (key.startsWith("Ai")) {
    return key.slice(2);
  } else if (key.startsWith("Ico")) {
    return key.slice(3);
  }
  return key;
}) as IconName[];

export interface IconProps {
  name: IconName;
  size?: "s" | "l" | "xl" | number;
  color?: string;
  onClick?: (e?: any) => void;
  "data-testid"?: string;
}

const ICON_SIZES: { [key: string]: number } = {
  s: 16,
  l: 24,
  xl: 32,
};

export const Icon: React.FC<IconProps> = ({
  name,
  size = "l",
  color = grayBase,
  onClick,
  "data-testid": testId,
}) => {
  const iconSize = typeof size === "number" ? size : ICON_SIZES[size];

  // Otherwise, use react-icons
  const FoundIcon: IconType & { isCustomSvg?: boolean } =
    icons[`Md${name}`] ||
    icons[`Mds${name}`] ||
    icons[`Fi${name}`] ||
    icons[`Ti${name}`] ||
    icons[`Ai${name}`] ||
    icons[`Ico${name}`];
  if (FoundIcon) {
    if (FoundIcon.isCustomSvg) {
      return (
        <span
          onClick={onClick}
          style={{
            cursor: onClick ? "pointer" : "inherit",
            verticalAlign: "text-top",
            display: "inline-block",
            width: iconSize,
            height: iconSize,
          }}
          data-testid={testId}
        >
          <FoundIcon fill={color} width={iconSize} height={iconSize} />
        </span>
      );
    }
    return (
      <FoundIcon
        size={iconSize}
        color={color}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "inherit", verticalAlign: "text-top" }}
        data-testid={testId}
      />
    );
  }

  return <pre>Error: Icon not loaded</pre>;
};
