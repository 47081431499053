import { create } from "zustand";

interface SearchSettingsStore {
  isFiltersOpen: boolean;
  setIsFiltersOpen: (open: boolean) => void;
}

export const useSearchSettings = create<SearchSettingsStore>((set) => ({
  isFiltersOpen: true,
  setIsFiltersOpen: (open) => set({ isFiltersOpen: open }),
}));
