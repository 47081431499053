import { Input } from "antd";
import styled from "styled-components";

import {
  BodyText,
  XLGridColumnWidth,
  blueLightest,
  desktopGridColumnWidth,
  disabledTextDark,
  grayBackground,
  grayBase,
  grayLight300,
  grayWhite,
  mobileGridColumnWidth,
  redBase,
  tabletGridColumnWidth,
} from "../../../styles/constants";
import { SmallText } from "../../Text";

import { AntTextAreaProps } from ".";

const { TextArea } = Input;

const getInputWidth = (width: string | number | undefined, gridWidth: number) => {
  let number;
  switch (width) {
    case "small":
      number = 1;
      break;
    case "medium":
      number = 2;
      break;
    case "large":
      number = 4;
      break;
    case "xl":
      number = 6;
      break;
    default:
      number = 2;
      break;
  }
  return number * gridWidth;
};

export const CustomTextArea = styled(TextArea)<AntTextAreaProps>`
  &.ant-input {
    height: 114px;
    border-color: ${(props) => `${props.$error ? redBase : "none"}`};
  }
  resize: none !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 8px;
  width: 100%;
  max-width: ${(props) => `${getInputWidth(props.$width, XLGridColumnWidth)}px`};
  height: 114px;
  background: ${grayWhite};
  border: 2px solid ${blueLightest};
  border-radius: 8px;
  ${BodyText}
  color: ${grayBase};
  ::placeholder {
    ${BodyText}
    color: ${(props) => `${props.$error ? redBase : grayLight300}`};
  }
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: ${redBase};
    color: ${redBase};
  }
  &.ant-input-disabled,
  &.ant-input[disabled] {
    background-color: ${grayBackground};
    border-color: ${blueLightest};
    color: ${disabledTextDark};
  }
  &:hover {
    border: 2px solid ${blueLightest};
  }
  &:focus {
    border: 2px solid ${blueLightest};
  }
  @media (max-width: 1439px) {
    max-width: ${(props) => `${getInputWidth(props.$width, desktopGridColumnWidth)}px`};
  }
  @media (max-width: 768px) {
    max-width: ${(props) => `${getInputWidth(props.$width, tabletGridColumnWidth)}px`};
  }
  @media (max-width: 480px) {
    max-width: ${(props) => `${getInputWidth(props.$width, mobileGridColumnWidth)}px`};
  }
`;

export const SubText = styled(SmallText)`
  color: ${(props) => (props.disabled ? disabledTextDark : "")};
`;
