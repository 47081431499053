import { Link } from "@RooUI";
import React from "react";

import { Event, EventTracker } from "@RooBeta/types";

import { WarningContainer } from "./styles";

type NDAWarningProps = {
  shiftId?: number;
};

export const NDAWarning = ({ shiftId }: NDAWarningProps) => (
  <WarningContainer data-testid="ndaWarning">
    Starting 10/30/24, Mars Veterinary Health Group is requiring vets to digitally sign their Mars
    Veterinary Services Agreement to request relief shifts at{" "}
    <b>Banfield or BluePearl hospitals (not required for other hospitals).</b> You'll only need to
    sign this once.{" "}
    <Link
      href="https://5920186.hs-sites.com/mars-vsa-faq"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        EventTracker.send({
          eventName: Event.Name.REVIEW_AGREEMENT_LEARN_MORE,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.VET_SHIFT,
          entityId: shiftId,
          context: { marsNDAFlowV2: true, isNewExperience: true },
        });
      }}
      linkSize="small"
    >
      Learn more
    </Link>
  </WarningContainer>
);
