import { useMutation } from "@tanstack/react-query";

import { post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey } from "@RooBeta/types";

interface UseBlockHospitalResponse {
  data: { success: boolean };
}

type BaseBlockHospitalRequest = {
  hospitalId: number;
  userId: number;
  internalNote: string;
};

type UseBlockHospitalRequest =
  | (BaseBlockHospitalRequest & { vetId: number })
  | (BaseBlockHospitalRequest & { techId: number });

export const useBlockHospitalMutation = () => {
  const { userId, provider } = useRequiredAuthorizedUser();

  return useMutation({
    mutationFn: async (params: Pick<UseBlockHospitalRequest, "hospitalId" | "internalNote">) =>
      post<UseBlockHospitalRequest, UseBlockHospitalResponse>(
        provider.providerType === "VET" ? "api/vet/blockHospital" : "api/tech/blockHospital",
        provider.providerType === "VET"
          ? { ...params, userId, vetId: provider.providerId }
          : { ...params, userId, techId: provider.providerId }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.BlockedHospitals, userId] });
    },
  });
};
