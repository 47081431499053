import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptions, HospitalRatings, UserType } from "@RooBeta/types";

type HospitalRatingsResponse = { data: HospitalRatings };

type UseHospitalRatingsResponseOptions = CustomQueryOptions<HospitalRatingsResponse> & {
  hospitalId?: number | null;
};

export const useHospitalRatingsQuery = ({
  hospitalId,
  ...options
}: UseHospitalRatingsResponseOptions) => {
  const { userId, provider } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.HospitalRatings, { hospitalId, userId }],
    queryFn: () => {
      const params = new URLSearchParams({
        userTypeId: UserType.Hospital.toString(),
        isFromAdmin: "false",
        hospitalId: hospitalId?.toString() || "",
        [provider.providerType === "VET" ? "vetId" : "techId"]: provider.providerId.toString(),
      });

      return get<HospitalRatingsResponse>(`api/user/${userId}/ratingSummary?${params.toString()}`);
    },
    enabled: !!hospitalId,
    staleTime: 5 * 60 * 1000,
    retry: false,
    ...options,
  });
};
