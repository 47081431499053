import { UseQueryOptions } from "@tanstack/react-query";

export type CustomQueryOptions<TResponse> = Omit<
  UseQueryOptions<TResponse, Error>,
  "queryFn" | "queryKey"
>;

export type CustomQueryOptionsWithData<TResponse, TData> = Omit<
  UseQueryOptions<TResponse, Error, TData>,
  "queryFn" | "queryKey"
>;

export enum CacheKey {
  GeoLocation = "GeoLocation",
  GeoPosition = "GeoPosition", // zipcode, latitude, longitude
  Search = "Search", // boundingBox, zoom, dateRange, rank, filters
  ShiftDetails = "ShiftDetails", // shiftGroupId
  HospitalInfo = "HospitalInfo", // hospitalId
  HospitalRatings = "HospitalRatings", // hospitalId, userId
  HospitalShifts = "HospitalShifts", // hospitalId, userId
  ContractStatus = "ContractStatus", // contractName, enterpriseId, userId
  Favorites = "Favorites", // userId
  BlockedHospitals = "BlockedHospitals", // userId
  RecalculateShiftPricing = "RecalculateShiftPricing", // shiftId, flexParams
  MyShifts = "MyShifts", // userId
  MyEvents = "MyEvents", // userId
  InitialSearchRequest = "InitialSearchRequest",
  AvailableDays = "AvailableDays", // vetId
}
