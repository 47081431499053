import { Button } from "@RooUI";
import React from "react";

import { HStack, RooIcon } from "@RooBeta/components";
import { useNotification } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";

import { useBlockHospital } from "../useBlockHospital";
import { useFavoriteHospital } from "../useFavoriteHospital";

export const HospitalCTA = () => {
  const notification = useNotification();

  const { hospitalId } = useShiftDetailsStore();

  const blockHospital = useBlockHospital({ hospitalId });
  const favoriteHospital = useFavoriteHospital({ hospitalId });

  return (
    <HStack $justifyContent="space-evenly" $gap="m" $padding="m">
      <Button
        $buttonType="secondary"
        loading={favoriteHospital.isLoading}
        onClick={async () => {
          const { action, success } = await favoriteHospital.toggle();
          !success &&
            notification.error({ message: "Error", description: `Failed to ${action} hospital.` });
        }}
        style={{ flex: 1 }}
      >
        <RooIcon
          icon={favoriteHospital.isFavorited ? "favorite_filled" : "favorite"}
          size="input"
        />
        {favoriteHospital.isFavorited ? "Unfavorite" : "Favorite"}
      </Button>
      <Button
        $buttonType="danger-secondary"
        loading={blockHospital.isLoading}
        onClick={async () => {
          const { action, success } = await blockHospital.toggle();
          !success &&
            notification.error({ message: "Error", description: `Failed to ${action} hospital.` });
        }}
        style={{ flex: 1 }}
      >
        <RooIcon icon="block" size="input" />
        {blockHospital.isBlocked ? "Unblock" : "Block"}
      </Button>
    </HStack>
  );
};
