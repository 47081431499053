import React from "react";
import {
  Alert,
  Button,
  Container,
  Heading,
  SmallText,
  spacingXL,
  spacingXXL,
  ThreeColumnGrid,
  TwoColumnGrid,
  TwoColumnLayout,
} from "@RooUI";
import { ControlledPhoneInput } from "../../../../Common/Controllers/ControlledPhoneInput";
import * as Sentry from "@sentry/react";
import {
  ControlledInput,
  ControlledPasswordInput,
  ControlledSelect,
} from "../../../../Common/Controllers";
import styled from "styled-components";
import { ButtonGroup, FooterGroup } from "../styles";
import { EventSignUpFooter } from "../common/EventSignUpFooter";
import { getInputErrorMessage } from "../../../../Common/Form/service";
import { FieldErrors, FormProvider, UseFormReturn } from "react-hook-form";
import { RegistrationForm } from "../../../../RegistrationUpdated/types";
import {
  anticipatedReliefNeedOptions,
  aTypeOfHospitalOptions,
} from "../../../../constants/dropDownConstants";
import { SingleInputAddressAutocomplete } from "@Roo/Common/Form/AddressAutocomplete/SingleInputAddressAutocomplete";
import { ControlledRadioGroup } from "@Roo/Common/Controllers/ControlledRadioGroup";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

const Section = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: spacingXL,
  marginTop: spacingXL,
});

interface Props {
  onSave?: (data: RegistrationForm) => void;
  onCancel?: () => void;
  loading?: boolean;
  methods: UseFormReturn<RegistrationForm>;
  duplicateAddressWarning: boolean;
}

export const EventHospitalSignUpForm: React.FC<Props> = ({
  onSave,
  onCancel,
  loading = false,
  methods,
  duplicateAddressWarning,
}): React.ReactElement => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const handleFormSubmit = async (data: RegistrationForm) => {
    try {
      if (onSave) {
        onSave(data);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getErrorMessage = (error: string | null | undefined): string | undefined => {
    return error || undefined;
  };

  const handleError = (formErrors: FieldErrors) => {
    if (
      formErrors.hospitalAddress ||
      formErrors.hospitalCity ||
      formErrors.hospitalState ||
      formErrors.hospitalZipcode
    ) {
      methods.setError("hospitalFullAddress", {
        message: "Please select an address from the dropdown.",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledContainer data-testid="hospitalSignUpForm">
        <form
          onSubmit={handleSubmit(handleFormSubmit, handleError)}
          id="hospitalSignUpForm"
          noValidate
        >
          <Heading level={3}>About you</Heading>
          <Section>
            <ThreeColumnGrid>
              <ControlledInput
                name="firstName"
                control={control}
                label="First Name"
                error={getErrorMessage(getInputErrorMessage(errors?.firstName?.message))}
              />

              <ControlledInput
                name="lastName"
                control={control}
                label="Last Name"
                error={getErrorMessage(getInputErrorMessage(errors?.lastName?.message))}
              />

              <ControlledPhoneInput
                control={control}
                name="mobilePhoneNumber"
                label="Mobile Phone Number"
                error={getErrorMessage(getInputErrorMessage(errors?.mobilePhoneNumber?.message))}
              />
            </ThreeColumnGrid>
          </Section>
          <TwoColumnGrid>
            <ControlledInput
              name="emailId"
              control={control}
              label="Email"
              type="email"
              error={getErrorMessage(getInputErrorMessage(errors?.emailId?.message))}
            />

            <ControlledPasswordInput
              name="password"
              control={control}
              label="Password"
              password={watch("password")}
              error={getErrorMessage(getInputErrorMessage(errors?.password?.message))}
            />
          </TwoColumnGrid>
          <hr />

          <Heading level={3} style={{ marginTop: spacingXXL }}>
            About your hospital
          </Heading>
          {duplicateAddressWarning && (
            <div style={{ marginTop: spacingXXL }}>
              <Alert
                $alertStatus="warning"
                alertBody="We found a hospital that appears to be similar to the one you're registering. If your hospital is already registered with Roo, please reach out to to your team for an invitation to join the account. If you think this may be an error, a Roo team member will be happy to assist you. "
              />
            </div>
          )}
          <TwoColumnLayout style={{ marginTop: spacingXXL }}>
            <ControlledInput
              name="hospitalName"
              control={control}
              label="Hospital name"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalName?.message))}
            />

            <ControlledPhoneInput
              control={control}
              name="hospitalPhoneNumber"
              label="Hospital phone number"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalPhoneNumber?.message))}
            />

            <SingleInputAddressAutocomplete
              control={control}
              onAddressSelected={({ stateId, city, zipcode, fullAddress }) => {
                setValue("hospitalState", stateId, { shouldValidate: true });
                setValue("hospitalCity", city, { shouldValidate: true });
                setValue("hospitalZipcode", zipcode, { shouldValidate: true });
                setValue("hospitalAddress", fullAddress, { shouldValidate: true });
              }}
              name="hospitalFullAddress"
              error={getErrorMessage(getInputErrorMessage(errors?.hospitalFullAddress?.message))}
            />

            <ControlledInput
              name="selfIdentifiedEnterpriseName"
              control={control}
              label="Corporate group name (if applicable)"
              error={getErrorMessage(
                getInputErrorMessage(errors?.selfIdentifiedEnterpriseName?.message)
              )}
            />
            <div>
              <ControlledSelect
                name="typeOfHospital"
                control={control}
                label="Type of hospital"
                options={aTypeOfHospitalOptions}
                error={getErrorMessage(getInputErrorMessage(errors?.typeOfHospital?.message))}
              />
              <SmallText>
                Please select the correct hospital type as any future updates will have to be
                completed by a Roo rep.
              </SmallText>
            </div>
            <ControlledSelect
              name="anticipatedReliefNeed"
              control={control}
              label="When do you anticipate first needing relief help?"
              options={anticipatedReliefNeedOptions}
              error={getErrorMessage(getInputErrorMessage(errors?.anticipatedReliefNeed?.message))}
            />

            <ControlledRadioGroup
              label="Will you be onboarding with a Roo team member at the booth?"
              name="onboardingWithRooTeamMember"
              control={control}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              type="classic"
              error={getErrorMessage(
                getInputErrorMessage(errors?.onboardingWithRooTeamMember?.message)
              )}
            />

            <ControlledRadioGroup
              label="Is your hospital currently looking to hire a provider? (optional)"
              name="isLookingToHireProvider"
              control={control}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              type="classic"
            />
          </TwoColumnLayout>
          <FooterGroup>
            <EventSignUpFooter />
            <ButtonGroup>
              <Button
                type="submit"
                title="Create My Account"
                $buttonType="primary"
                loading={loading}
                testId="createHospitalAccount"
                form="hospitalSignUpForm"
              />
              <Button title="Cancel" onClick={onCancel} loading={loading} testId="cancel" />
            </ButtonGroup>
          </FooterGroup>
        </form>
      </StyledContainer>
    </FormProvider>
  );
};
