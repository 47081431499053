import _ from "lodash";
import React from "react";

import {
  blueBackground,
  grayBackground,
  grayLight400,
  inputBorderRadius,
  shadowStyle,
} from "../../styles/constants";
import { Chip } from "../Chip";
import { FormLabel } from "../FormLabel";
import { Icon } from "../Icon";
import { SmallText } from "../Text";
import { ErrorText } from "../Text/ErrorText";

import {
  ClearIcon,
  DropdownMenu,
  MultiSelectChipContainer,
  StyledSelect,
  ToggleIcon,
  getArrowColor,
} from "./SelectStyles";
import { getFilterOptionByAdditionalFieldsFunc } from "./service";

export interface BaseOptionType extends Record<string, any> {
  label: string;
  value: unknown;
}

// The generics Multi and Clearable will narrow down the boolean type to either true or false
// Then we can infer whether value should be an array or singular using conditional types
export type SelectProps<
  T extends BaseOptionType,
  Multi extends boolean | undefined = false,
  Clearable extends boolean | undefined = false
> = {
  multi?: Multi;
  value: Multi extends true ? T["value"][] | undefined : T["value"] | undefined;
  defaultValue?: SelectProps<T, Multi, Clearable>["value"];
  label?: string | React.ReactNode;
  options: T[];
  placeholder?: string;
  searchable?: boolean;
  disabled?: boolean;
  "data-testid"?: string;
  id?: string;
  error?: string;
  isHideErrorText?: boolean;
  additionalSearchFilterFields?: [keyof Omit<T, "label">]; // label included automatically
  clearable?: Clearable;
  onChange?: Clearable extends true // the onChange value can be undefined if the select is clearable
    ? (
        option: Multi extends true ? T[] : T, // return array of options when multi is selected
        value: SelectProps<T, Multi, Clearable>["value"] | undefined
      ) => void
    : (
        option: Multi extends true ? T[] : T,
        value: SelectProps<T, Multi, Clearable>["value"] // never undefined
      ) => void;
  onSearch?: (searchValue: string) => void;
};

// Select component definition
export function Select<
  NarrowOptionType extends BaseOptionType,
  // Narrow the types of clearable and multi because they affect the other prop types
  Multi extends boolean | undefined = false,
  Clearable extends boolean | undefined = false
>({
  label,
  defaultValue,
  value,
  options,
  onChange,
  placeholder,
  id,
  searchable,
  clearable,
  disabled,
  "data-testid": testId = "",
  error,
  isHideErrorText,
  multi,
  additionalSearchFilterFields,
  onSearch,
}: SelectProps<NarrowOptionType, Multi, Clearable>) {
  return (
    <div data-testid={`${testId}Dropdown`} id={id}>
      <FormLabel $disabled={disabled} error={!!error}>
        {label}
      </FormLabel>
      <StyledSelect
        optionFilterProp="label"
        // Note: if we ever want filterOption to be a prop directly, it might be cleaner to move the
        // additionalSearchFilterFields logic into Roo-React
        filterOption={
          additionalSearchFilterFields
            ? getFilterOptionByAdditionalFieldsFunc<NarrowOptionType>(additionalSearchFilterFields)
            : undefined
        }
        defaultValue={defaultValue}
        value={value}
        options={options}
        onChange={(newValue, option) => {
          if (onChange) {
            // No point in narrowing types at runtime because we make the same call for both single and multi select
            // The typings for the underlying component aren't narrowing automatically
            onChange(option, newValue);
          }
        }}
        onSelect={(newValue, option) => {
          // Multi automatically handles the clearing of double-selected items
          if (value === newValue && clearable && onChange && !multi) {
            // Since this is always single select, we can pass a singular option value
            onChange(option, undefined);
          }
        }}
        onSearch={(searchValue) => {
          if (onSearch) {
            onSearch(searchValue);
          }
        }}
        placeholder={placeholder}
        showSearch={searchable}
        allowClear={clearable ? { clearIcon: <ClearIcon /> } : undefined}
        disabled={disabled}
        dropdownRender={(menu) => <DropdownMenu menu={menu} data-testid={`${testId}List`} />}
        dropdownStyle={{
          padding: 0,
          boxShadow: shadowStyle,
          borderRadius: inputBorderRadius,
        }}
        size={"large"}
        suffixIcon={
          clearable && !_.isNil(value) ? (
            <ClearIcon />
          ) : (
            <ToggleIcon color={getArrowColor(!!error, disabled)} />
          )
        }
        status={error ? "error" : undefined}
        $status={error ? "error" : undefined}
        mode={multi ? "multiple" : undefined}
        tagRender={({ label, onClose }) => (
          <MultiSelectChipContainer>
            <Chip backgroundColor={disabled ? grayBackground : blueBackground}>
              <>
                <SmallText>{label}</SmallText>
                <Icon
                  name="Close"
                  size="s"
                  {...(disabled ? {} : { onClick: onClose })}
                  color={grayLight400}
                />
              </>
            </Chip>
          </MultiSelectChipContainer>
        )}
        notFoundContent={null}
      />
      {error && !isHideErrorText && <ErrorText>{error}</ErrorText>}
    </div>
  );
}
