import { useMutation } from "@tanstack/react-query";

import { APIError, put } from "../../api/common/rooFetch";
import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";

interface UpdateNotificationSettingsRequest {
  notificationTypeId: number;
  userId: string;
  hospitalId?: string;
  smsEnabled: number;
  emailEnabled: number;
  pnEnabled: number;
}

const baseUrl = `${window.RooConfig.NOTIFICATION_SETTINGS_API_URL}/api/notification`;

export const useUpdateNotificationSettings = ({
  userId,
  hospitalId,
  onSuccess,
  onError,
}: {
  userId: string;
  hospitalId?: string;
  onSuccess?: () => void;
  onError?: (error: APIError<{ message: string }>) => void;
}) => {
  return useMutation<unknown, APIError<{ message: string }>, UpdateNotificationSettingsRequest>(
    async (request) => {
      const response = await put(
        `${baseUrl}/notificationToggles`,
        request,
        false,
        undefined,
        false
      );
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE.GET_NOTIFICATION_SETTINGS, userId, hospitalId],
        });
        onSuccess?.();
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
};
