const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatCurrency = (amount: number = 0) => {
  return currencyFormatter.format(Math.ceil(amount * 100) / 100);
};
