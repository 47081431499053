import { Button } from "@RooUI";
import React from "react";
import { useDispatch } from "react-redux";

import { openChatPanel } from "@Roo/Common/Chat/actions/chatActions";
import { VStack } from "@RooBeta/components";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";

export const ConfirmedShiftCTA = () => {
  const { userId, techId, vetId } = useRequiredAuthorizedUser();
  const dispatch = useDispatch();
  const { shiftDetails, setDrawer } = useShiftDetailsStore();

  return shiftDetails ? (
    <VStack $justifyContent="space-between" $gap="m" $padding="m">
      <Button
        data-testid="messageButton"
        $buttonType="primary"
        onClick={() => {
          dispatch(
            openChatPanel({
              shiftId: shiftDetails.shiftId,
              shiftGroupId: shiftDetails.shiftGroupId,
              hospitalId: shiftDetails.hospitalId,
              contractorUserId: userId,
              vetId: vetId,
              techId: techId,
            })
          );
        }}
        style={{ width: "100%" }}
      >
        Message
      </Button>
      <Button
        data-testid="cancelButton"
        $buttonType="danger-secondary"
        onClick={() => setDrawer("cancel")}
        style={{ width: "100%" }}
      >
        Cancel Shift
      </Button>
    </VStack>
  ) : null;
};
