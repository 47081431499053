import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, CustomQueryOptions, MyEvent } from "@RooBeta/types";

type UseMyEventsOptions = CustomQueryOptions<{
  data: MyEvent[];
}>;

export const useMyEventsQuery = (options: UseMyEventsOptions = {}) => {
  const {
    userId,
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.MyEvents, userId],
    queryFn: () =>
      get(providerType === "VET" ? "api/vet/event/myEvents" : "api/tech/event/myEvents"),
    retry: false,
    ...options,
  });
};
