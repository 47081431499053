import { useCallback } from "react";

import { useFavoriteHospitalsMutation, useFavoriteHospitalsQuery } from "@RooBeta/hooks";

interface UseFavoriteHospitalProps {
  hospitalId?: number;
}

export const useFavoriteHospital = ({ hospitalId }: UseFavoriteHospitalProps) => {
  const { data: favorites, isLoading: isFetching } = useFavoriteHospitalsQuery({
    enabled: !!hospitalId,
  });

  const { mutateAsync: updateFavorites, isLoading: isUpdating } = useFavoriteHospitalsMutation();
  const isLoading = isFetching || isUpdating;
  const isFavorited = hospitalId && favorites?.has(hospitalId);

  const toggle = useCallback(async (): Promise<{
    action: "favorite" | "unfavorite";
    success: boolean;
  }> => {
    const action = isFavorited ? "unfavorite" : "favorite";

    if (!hospitalId || !favorites) {
      return { action, success: false };
    }

    if (isFavorited) {
      favorites?.delete(hospitalId);
    } else {
      favorites?.add(hospitalId);
    }

    const {
      data: { success },
    } = await updateFavorites({ favorites: Array.from(favorites).join(",") });

    return { action, success };
  }, [isFavorited, favorites, hospitalId, updateFavorites]);

  return { isLoading, isFavorited, toggle };
};
