import {
  SmallMediumBodyText,
  blueDark600,
  blueLightest,
  buttonBorderRadius,
  grayWhite,
  purpleLight50,
  spacingM,
  spacingXS,
} from "@RooUI";
import React, { useCallback } from "react";
import styled from "styled-components";

type ChipProps<T> = {
  value?: T;
  label: string;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  onSelect: (value?: T) => void;
  children?: React.ReactNode;
};

export function Chip<T>({
  value,
  label,
  count,
  selected,
  disabled,
  onSelect,
  children,
}: ChipProps<T>) {
  const onClick = useCallback(() => {
    if (!disabled) {
      onSelect(value);
    }
  }, [value, disabled, onSelect]);

  return (
    <ChipContainer $selected={selected} $disabled={disabled} onClick={onClick}>
      <div>{label}</div>
      {typeof count === "number" && <div>({count})</div>}
      {children}
    </ChipContainer>
  );
}

const ChipContainer = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: ${spacingXS};
  white-space: nowrap;
  background-color: ${({ $selected }) => ($selected ? purpleLight50 : grayWhite)};
  border-radius: ${buttonBorderRadius};
  border: 2px solid ${blueLightest};
  padding: ${spacingXS} ${spacingM};
  ${SmallMediumBodyText}
  color: ${blueDark600};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? "normal" : "pointer")};
`;
