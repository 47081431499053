import { useMutation } from "@tanstack/react-query";

import { post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey } from "@RooBeta/types";

type UseContractSignatureRequest = {
  enterpriseContractId: number;
  signature: string;
  address: string;
  phoneNumber: string;
};

export const useContractSignatureMutation = () => {
  const { userId } = useRequiredAuthorizedUser();

  return useMutation({
    mutationFn: async (params: UseContractSignatureRequest) =>
      post("api/enterprise/contract/signature", { userId, ...params }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CacheKey.ContractStatus] });
    },
  });
};
