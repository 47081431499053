import { Modal } from "@RooUI";
import React from "react";

import { NDAForm } from "./NDAForm";

export interface NDAModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  enterpriseContractId: number;
  shiftId: number;
  isInstantBookable: boolean;
  onSignature: () => Promise<void>;
}

export const NDAModal = ({ showModal, onCloseModal, ...props }: NDAModalProps) => (
  <Modal
    $size="l"
    $noPadding
    showModal={showModal}
    onCloseModal={onCloseModal}
    header=""
    body={<NDAForm {...props} onClose={onCloseModal} />}
  />
);
