import React from "react";

import { TabPanelContainer } from "./TabPanelStyles";

export interface TabPanelContainerProps {
  isSelected?: boolean;
  isMobileMenuOpen?: boolean;
  children?: React.ReactNode;
  scrollbarSpace?: boolean;
  style?: React.CSSProperties;
}

export const TabPanel: React.FC<TabPanelContainerProps> = (props) => {
  const { children } = props;
  return <TabPanelContainer {...props}>{children}</TabPanelContainer>;
};
