import { useContext } from "react";

import { AuthorizedUserContext } from "@RooBeta/contexts";

export function useAuthorizedUser() {
  if (!AuthorizedUserContext) {
    throw new Error(
      "Components that call `useAuthorizedUser` must be wrapped in a <AuthorizedUserProvider />"
    );
  }

  const context = useContext(AuthorizedUserContext);
  return context;
}

export function useRequiredAuthorizedUser() {
  const { user } = useAuthorizedUser();

  if (!user) {
    throw new Error("This component requires an authorized user");
  }

  return user;
}
