import { useMutation } from "@tanstack/react-query";

import { APIError, put } from "../../api/common/rooFetch";
import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";

interface UpdateShiftPreferencesRequest {
  frequencyId: number;
  notificationId: number;
  shiftPreferenceSettings: {
    id: number;
    notification_settings_id: number;
    attribute: string;
    value: string;
  }[];
}

const baseUrl = `${window.RooConfig.NOTIFICATION_SETTINGS_API_URL}/api/notification`;

export const useUpdateShiftPreferences = ({
  userId,
  hospitalId,
  onSuccess,
  onError,
}: {
  userId: number;
  hospitalId?: number;
  onSuccess?: () => void;
  onError?: (error: APIError<{ message: string }>) => void;
}) => {
  return useMutation<unknown, APIError<{ message: string }>, UpdateShiftPreferencesRequest>(
    async (request) => {
      const response = await put(`${baseUrl}/shiftPreference`, request, false, undefined, false);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE.GET_NOTIFICATION_SETTINGS, userId, hospitalId],
        });
        onSuccess?.();
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
};
