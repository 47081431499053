import { GroupedShiftList, ShiftMetadata } from "./searchShifts";

export type MyShift = {
  shiftId: number;
  shiftGroupId: number | null;
  userId: number;
  hospitalId: number;
  hospitalName: string | null;
  hospitalImage: string | null;
  latitude: string | null;
  longitude: string | null;
  distance: number | null;
  shiftDate: string;
  startTime: string;
  endTime: string;
  expectedAppointmentTypeId: number;
  surgeryTier: number;
  isSurgeryExpected: number;
  isFlexibleSurgery: number;
  isDEALicenseRequired: number;
  isInstantBookable: number;
  isFearFree: number;
  isFlexibleTime: boolean;
  confirmedVetId?: number | null;
  confirmedTechId?: number | null;
  shiftRequestEndTimestamp: number | null;
  vetShiftAmount: string;
  vetShiftAmountUpdated: string | null;
  techShiftAmount?: string;
  techShiftAmountUpdated?: string | null;
  price: number;
  metadata?: ShiftMetadata[];
  flexStartTimeRange?: string;
  flexEndTimeRange?: string;
  shiftStartDatetime: number;
  shiftEndDatetime: number;
  shiftCreatedOn: number;
  hospitalTimezone: string;
  expectedLunchTime: string | null;
  expectedNoOfAppointments: number | null;
  additionalDoctor: number;
  additionalComments: string | null;
  procedureTypes: string | null;
  appointmentTypes: string | null;
  isWalkInsOrDropOffs: number;
  isNoShow: number;
  isRequestWithoutSurgery: number;
  requestedShiftStartTime: string | null;
  requestedShiftEndTime: string | null;
  requestedVetShiftAmount: number | null;
  requestedHospitalShiftAmount: number | null;
  isVetBid: number;
  isTimeBid: number;
  cancellationReasonId: number | null;
};

export enum MyShiftGroup {
  Requested = "Requested",
  Confirmed = "Confirmed",
  Completed = "Completed",
}

export type MyShiftGroups = Record<MyShiftGroup, GroupedShiftList>;
