import { Alert, SmallText, grayBackground, spacingL } from "@RooUI";
import { Skeleton } from "antd";
import React from "react";
import styled from "styled-components";

import { VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";

import { TabContentContainer } from "./styles";

export const HospitalReviews = () => {
  const { hospitalRatings, loading, errors } = useShiftDetailsStore();

  return hospitalRatings ? (
    <>
      {hospitalRatings.summary.length > 0 ? (
        <VStack>
          {hospitalRatings.summary.map(({ comments }, index) => (
            <Comment key={index}>{comments}</Comment>
          ))}
        </VStack>
      ) : (
        <VStack $padding="l">No reviews yet.</VStack>
      )}
    </>
  ) : loading.hospitalRatings ? (
    <TabContentContainer>
      <Skeleton active />
    </TabContentContainer>
  ) : errors.hospitalRatings ? (
    <TabContentContainer>
      <Alert $alertStatus="error" alertBody={<SmallText>Oops, something went wrong.</SmallText>} />
    </TabContentContainer>
  ) : null;
};

const Comment = styled.div`
  padding: ${spacingL};

  &:not(:last-child) {
    border-bottom: 1px solid ${grayBackground};
  }
`;
