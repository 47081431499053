import React from "react";

import { Color, grayWhite } from "../../styles/constants";
import { Button, ButtonProps } from "../Button";
import { Icon, IconProps } from "../Icon";
import { LinkTextProps } from "../Link";
import { MediumText } from "../Text/TextStyles";

import {
  ActionsContainer,
  IconTextContainer,
  StatusAlertLink,
  StatusAlertStyle,
} from "./StatusAlertStyles";

export interface StatusAlertProps {
  text: string;
  statusIcon?: IconProps["name"];
  secondaryCTAProps?: ButtonProps;
  linkProps?: LinkTextProps;
  backgroundColor?: Color;
  color?: Color;
  linkColor?: Color;
  "data-testid"?: string;
}

export const StatusAlert = ({
  text,
  secondaryCTAProps,
  linkProps,
  statusIcon = "ErrorOutline",
  backgroundColor,
  color = grayWhite,
  linkColor = grayWhite,
  "data-testid": testId,
}: StatusAlertProps) => {
  return (
    <StatusAlertStyle
      justify="space-between"
      align="center"
      $backgroundColor={backgroundColor}
      data-testid={testId}
    >
      <IconTextContainer align="center" gap="l">
        <Icon name={statusIcon} size="xl" color={color} />
        <MediumText color={color}>{text}</MediumText>
      </IconTextContainer>
      <ActionsContainer vertical gap="s" align="center">
        {secondaryCTAProps && <Button {...secondaryCTAProps} size="small" />}
        {linkProps && (
          <StatusAlertLink
            {...linkProps}
            icon={
              linkProps.icon || {
                name: "HelpOutline",
                size: "s",
                color: linkColor,
              }
            }
            iconPosition="right"
            $color={linkColor}
          >
            {linkProps.children}
          </StatusAlertLink>
        )}
      </ActionsContainer>
    </StatusAlertStyle>
  );
};
