import { MediumText, SmallText } from "@RooUI";
import React, { useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";
import { Milestone } from "@roo-dev/roo-node-types";

import { HStack } from "@RooBeta/components";
import {
  NAVIGATION_HEIGHT,
  SEARCH_HEIGHT,
  SHIFT_CARD_HEIGHT,
  STICKY_HEADER_HEIGHT,
  BANNER_HEIGHT,
} from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { GroupedShiftList as List } from "@RooBeta/types";
import { truncate } from "@RooBeta/utils";
import { ShiftCard } from "./ShiftCard";
import { ReliefWorkInterstitial } from "./ReliefWorkInterstitial";
import { StickyHeader, StyledScrollArea, StyledVariableSizeList } from "./styles";
import { useGetMilestoneStatus, MilestoneStatusResponse } from "../../../hooks/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";

type GroupedShiftListProps = {
  shiftList: List;
  fixedHeight?: boolean;
};

export const GroupedShiftList = ({ fixedHeight = true, ...props }: GroupedShiftListProps) => {
  const isFeatureEnabled = useRooFeatureFlagEnabled(FEATURE_FLAGS.CURRENT_SITUATION_PROMPT);
  const { vetId } = useRequiredAuthorizedUser();

  const calculateTotalShifts = (groups: List["groups"]) => {
    return groups.reduce((sum, group) => sum + group.shifts.length, 0);
  };

  const calculatePreviousShifts = (groups: List["groups"], index: number) => {
    return groups.slice(0, index).reduce((sum, group) => sum + group.shifts.length, 0);
  };

  const shouldShowBannerForShift = (
    totalShifts: number,
    previousShifts: number,
    shiftIndex: number
  ) => {
    const currentShiftNumber = previousShifts + shiftIndex + 1;
    return currentShiftNumber === 4 || (totalShifts < 4 && currentShiftNumber === totalShifts);
  };

  const calculateItemSize = (
    groups: List["groups"],
    index: number,
    milestoneStatus?: MilestoneStatusResponse
  ) => {
    const shifts = groups[index].shifts;
    const totalShifts = calculateTotalShifts(groups);
    const previousShifts = calculatePreviousShifts(groups, index);

    const showBanner =
      isFeatureEnabled &&
      typeof vetId === "number" &&
      milestoneStatus?.data?.completed === false &&
      shifts.some((_, shiftIndex) =>
        shouldShowBannerForShift(totalShifts, previousShifts, shiftIndex)
      );
    return (
      STICKY_HEADER_HEIGHT + shifts.length * SHIFT_CARD_HEIGHT + (showBanner ? BANNER_HEIGHT : 0)
    );
  };

  const listRef = useRef<VariableSizeList>(null);
  const { windowSize } = useViewportStore();
  const width = "100%";
  const height = windowSize.height - (NAVIGATION_HEIGHT + SEARCH_HEIGHT);
  const { data: milestoneStatus } = useGetMilestoneStatus({
    milestone: Milestone.UserMilestone.CURRENT_SITUATION_QUESTION,
  });

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [props.shiftList, milestoneStatus]);

  return (
    <StyledScrollArea $height={height} $fixedHeight={fixedHeight}>
      {({ scrollableNodeRef, contentNodeRef }) => (
        <StyledVariableSizeList
          ref={listRef}
          width={width}
          height={height}
          itemCount={props.shiftList.groups.length}
          itemData={{ ...props.shiftList, milestoneStatus, isFeatureEnabled, vetId }}
          itemSize={(index) => calculateItemSize(props.shiftList.groups, index, milestoneStatus)}
          innerRef={contentNodeRef}
          outerRef={scrollableNodeRef}
        >
          {ShiftListRow}
        </StyledVariableSizeList>
      )}
    </StyledScrollArea>
  );
};

function ShiftListRow({
  index,
  style,
  data: { groups, count, milestoneStatus, isFeatureEnabled, vetId },
}: Readonly<{
  index: number;
  style: React.CSSProperties;
  data: List & {
    milestoneStatus?: MilestoneStatusResponse;
    isFeatureEnabled: boolean;
    vetId: number | null;
  };
}>) {
  function shouldShowBanner(shiftIndex: number) {
    if (!isFeatureEnabled || milestoneStatus?.data?.completed || typeof vetId != "number")
      return false;

    const totalShifts = groups.reduce((sum, group) => sum + group.shifts.length, 0);
    const previousShifts = groups
      .slice(0, index)
      .reduce((sum, group) => sum + group.shifts.length, 0);
    const currentShiftNumber = previousShifts + shiftIndex + 1;

    return currentShiftNumber === 4 || (totalShifts < 4 && currentShiftNumber === totalShifts);
  }

  return (
    <div style={style}>
      <StickyHeader>
        <HStack $justifyContent="space-between">
          <MediumText>{truncate(groups[index].label)}</MediumText>
          {groups[index].subLabel && <SmallText>{groups[index].subLabel}</SmallText>}
        </HStack>
      </StickyHeader>
      {groups[index].shifts.map((shift, shiftIndex) => {
        const isBannerPosition = shouldShowBanner(shiftIndex);
        const showBanner = isBannerPosition && milestoneStatus?.data?.completed === false;
        return [
          <ShiftCard key={`shift-${shift.shiftId}`} shift={shift} count={count} variant="list" />,
          showBanner && <ReliefWorkInterstitial key={`banner-${shift.shiftId}`} />,
        ];
      })}
    </div>
  );
}
