import { useEffect, useState } from "react";

export type AsyncFn = (...args: any[]) => Promise<any>;

// Replace uses of this function with react-query/RTK-query/etc. in the future
// Must pass asyncFn to ensure it only fires 1 time
export const useAsync = <NarrowAsyncFn extends AsyncFn>(
  asyncFn: NarrowAsyncFn,
  enabled: boolean = true,
  ...args: Parameters<NarrowAsyncFn>
): [Awaited<ReturnType<NarrowAsyncFn>>, boolean] => {
  const [loading, setLoading] = useState(true);
  const [asyncResult, setAsyncResult] = useState(null);
  // Added this in unit tests were failing
  useEffect(() => {
    if (asyncFn && enabled) {
      asyncFn(...args)
        // eslint-disable-next-line promise/prefer-await-to-then
        ?.then((data) => {
          setAsyncResult(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return [asyncResult, loading];
};
