import * as Sentry from "@sentry/react";

import { rooFetch } from "../../api/common/rooFetch";

export const fetchMediaSourceOptions = async () => {
  try {
    const response = await rooFetch(`${window.RooConfig.API_URL}api/utility/loadCommonData`, {
      method: "POST",
    });
    const data = await response.json();
    const options = data.data.mediaSource.data;

    const mediaSourceOptions = options.map((src: { id: number; source: string }) => ({
      value: src.id,
      label: src.source,
      name: "hearAboutRoo",
    }));

    return mediaSourceOptions;
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error fetching common data" } });
  }
};

export const getInputErrorMessage = (message: string | undefined): string | null => {
  if (message === "Required") {
    return `This field is ${message?.toLowerCase()}`;
  } else if (message !== undefined) return message;
  else return null;
};
