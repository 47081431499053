const isPresent = (value: unknown): boolean =>
  value !== null && value !== undefined && value !== "" && value !== false;

export const sanitizeFormData = <T extends Record<string, any>>(data: T): T =>
  Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      const sanitizedArray = value.filter(isPresent);

      if (sanitizedArray.length > 0) {
        acc[key as keyof T] = sanitizedArray as T[keyof T];
      }

      return acc;
    }

    if (value && typeof value === "object") {
      const sanitizedNested = sanitizeFormData(value);

      if (Object.keys(sanitizedNested).length > 0) {
        acc[key as keyof T] = sanitizedNested as T[keyof T];
      }

      return acc;
    }

    if (isPresent(value)) {
      acc[key as keyof T] = value as T[keyof T];
    }

    return acc;
  }, {} as T);
