export const GET_SHIFT_DETAILS = "GET_SHIFT_DETAILS";
export const GET_SHIFT_DETAILS_FAIL = "GET_SHIFT_DETAILS_FAIL";
export const GET_SHIFT_DETAILS_SUCCESS = "GET_SHIFT_DETAILS_SUCCESS";

export const CONFIRM_VET_SHIFT_REQUEST = "CONFIRM_VET_SHIFT_REQUEST";
export const CONFIRM_VET_SHIFT_FAIL = "CONFIRM_VET_SHIFT_FAIL";
export const CONFIRM_VET_SHIFT_SUCCESS = "CONFIRM_VET_SHIFT_SUCCESS";
export const CONFIRM_VET_SHIFT_FROM_PANEL_SUCCESS = "CONFIRM_VET_SHIFT_FROM_PANEL_SUCCESS";

export const DELETE_SHIFT = "DELETE_SHIFT";
export const DELETE_SHIFT_FAIL = "DELETE_SHIFT_FAIL";
export const DELETE_SHIFT_SUCCESS = "DELETE_SHIFT_SUCCESS";
export const DELETE_SHIFT_FROM_PANEL_SUCCESS = "DELETE_SHIFT_FROM_PANEL_SUCCESS";

export const RENEW_SHIFT = "RENEW_SHIFT";
export const RENEW_SHIFT_FAIL = "RENEW_SHIFT_FAIL";
export const RENEW_SHIFT_SUCCESS = "RENEW_SHIFT_SUCCESS";

export const RENEW_MULTIPLE_SHIFTS = "RENEW_MULTIPLE_SHIFTS";
export const RENEW_MULTIPLE_SHIFTS_FAIL = "RENEW_MULTIPLE_SHIFTS_FAIL";
export const RENEW_MULTIPLE_SHIFTS_SUCCESS = "RENEW_MULTIPLE_SHIFTS_SUCCESS";

export const CLEAR_RENEW_MULTIPLE_SHIFTS_DATA = "CLEAR_RENEW_MULTIPLE_SHIFTS_DATA";

export const GET_HOSPITAL_LATEST_SHIFT_DETAILS = "GET_HOSPITAL_LATEST_SHIFT_DETAILS";
export const GET_HOSPITAL_LATEST_SHIFT_DETAILS_SUCCESS =
  "GET_HOSPITAL_LATEST_SHIFT_DETAILS_SUCCESS";
export const GET_HOSPITAL_LATEST_SHIFT_DETAILS_FAIL = "GET_HOSPITAL_LATEST_SHIFT_DETAILS_FAIL";

export const GET_MY_SHIFTS = "GET_MY_SHIFTS";
export const GET_MY_SHIFTS_FAIL = "GET_MY_SHIFTS_FAIL";
export const GET_MY_SHIFTS_SUCCESS = "GET_MY_SHIFTS_SUCCESS";

export const PASS_ALL_SHIFTS_SEARCH_TO_CALENDAR = "PASS_ALL_SHIFTS_SEARCH_TO_CALENDAR";
export const SET_AVAILABLE_DAY = "SET_AVAILABLE_DAY";
export const SET_AVAILABLE_DAY_SUCCESS = "SET_AVAILABLE_DAY_SUCCESS";
export const SET_AVAILABLE_DAY_FAIL = "SET_AVAILABLE_DAY_FAIL";

export const GET_MY_SHIFTS_DETAILS = "GET_MY_SHIFTS_DETAILS";
export const GET_MY_SHIFTS_DETAILS_FAIL = "GET_MY_SHIFTS_DETAILS_FAIL";
export const GET_MY_SHIFTS_DETAILS_SUCCESS = "GET_MY_SHIFTS_DETAILS_SUCCESS";

export const REQUEST_VET_SHIFT = "REQUEST_VET_SHIFT";
export const REQUEST_VET_SHIFT_SUCCESS = "REQUEST_VET_SHIFT_SUCCESS";
export const REQUEST_VET_SHIFT_NOT_AVAILABLE = "REQUEST_VET_SHIFT_NOT_AVAILABLE";
export const REQUEST_VET_SHIFT_FAIL = "REQUEST_VET_SHIFT_FAIL";

export const UPDATE_SHIFT_REQUEST_END_TIMESTAMP = "UPDATE_SHIFT_REQUEST_END_TIMESTAMP";

export const INITIALIZE_STATE = "INITIALIZE_STATE";
export const INITIALIZE_POPUP_STATE = "INITIALIZE_POPUP_STATE";

export const SHOW_LOADER = "SHOW_LOADER";

export const CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL = "CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL";
export const CLOSE_SHIFT_NOT_AVAILABLE_MODAL = "CLOSE_SHIFT_NOT_AVAILABLE_MODAL";

export const DECLINE_REQUEST = "DECLINE_REQUEST";
export const DECLINE_REQUEST_FAIL = "DECLINE_REQUEST_FAIL";
export const DECLINE_REQUEST_SUCCESS = "DECLINE_REQUEST_SUCCESS";
export const DECLINE_REQUEST_FROM_PANEL_SUCCESS = "DECLINE_REQUEST_FROM_PANEL_SUCCESS";

export const CONFIRM_VET_SHIFT_NO_MODAL = "CONFIRM_VET_SHIFT_NO_MODAL";
export const CONFIRM_VET_SHIFT_NO_MODAL_SUCCESS = "CONFIRM_VET_SHIFT_NO_MODAL_SUCCESS";
export const CONFIRM_VET_SHIFT_NO_MODAL_FAIL = "CONFIRM_VET_SHIFT_NO_MODAL_FAIL";

// --- tech related

export const GET_TECH_SHIFT_DETAILS = "GET_TECH_SHIFT_DETAILS";
export const GET_TECH_SHIFT_DETAILS_FAIL = "GET_TECH_SHIFT_DETAILS_FAIL";
export const GET_TECH_SHIFT_DETAILS_SUCCESS = "GET_TECH_SHIFT_DETAILS_SUCCESS";

export const CONFIRM_TECH_SHIFT_REQUEST = "CONFIRM_TECH_SHIFT_REQUEST";
export const CONFIRM_TECH_SHIFT_FAIL = "CONFIRM_TECH_SHIFT_FAIL";
export const CONFIRM_TECH_SHIFT_SUCCESS = "CONFIRM_TECH_SHIFT_SUCCESS";
export const CONFIRM_TECH_SHIFT_FROM_PANEL_SUCCESS = "CONFIRM_TECH_SHIFT_FROM_PANEL_SUCCESS";

export const DELETE_TECH_SHIFT = "DELETE_TECH_SHIFT";
export const DELETE_TECH_SHIFT_FAIL = "DELETE_TECH_SHIFT_FAIL";
export const DELETE_TECH_SHIFT_SUCCESS = "DELETE_TECH_SHIFT_SUCCESS";
export const DELETE_TECH_SHIFT_FROM_PANEL_SUCCESS = "DELETE_TECH_SHIFT_FROM_PANEL_SUCCESS";

export const GET_MY_TECH_SHIFTS = "GET_MY_TECH_SHIFTS";
export const GET_MY_TECH_SHIFTS_FAIL = "GET_MY_TECH_SHIFTS_FAIL";
export const GET_MY_TECH_SHIFTS_SUCCESS = "GET_MY_TECH_SHIFTS_SUCCESS";

export const GET_MY_TECH_SHIFTS_DETAILS = "GET_MY_TECH_SHIFTS_DETAILS";
export const GET_MY_TECH_SHIFTS_DETAILS_FAIL = "GET_MY_TECH_SHIFTS_DETAILS_FAIL";
export const GET_MY_TECH_SHIFTS_DETAILS_SUCCESS = "GET_MY_TECH_SHIFTS_DETAILS_SUCCESS";

export const REQUEST_TECH_SHIFT = "REQUEST_TECH_SHIFT";
export const REQUEST_TECH_SHIFT_SUCCESS = "REQUEST_TECH_SHIFT_SUCCESS";
export const REQUEST_TECH_SHIFT_NOT_AVAILABLE = "REQUEST_TECH_SHIFT_NOT_AVAILABLE";
export const REQUEST_TECH_SHIFT_FAIL = "REQUEST_TECH_SHIFT_FAIL";

export const GET_CALENDAR_NOTES = "GET_CALENDAR_NOTES";
export const GET_CALENDAR_NOTES_FAIL = "GET_CALENDAR_NOTES_FAIL";
export const GET_CALENDAR_NOTES_SUCCESS = "GET_CALENDAR_NOTES_SUCCESS";

export const DECLINE_TECH_REQUEST = "DECLINE_TECH_REQUEST";
export const DECLINE_TECH_REQUEST_FAIL = "DECLINE_TECH_REQUEST_FAIL";
export const DECLINE_TECH_REQUEST_SUCCESS = "DECLINE_TECH_REQUEST_SUCCESS";
export const DECLINE_TECH_REQUEST_FROM_PANEL_SUCCESS = "DECLINE_TECH_REQUEST_FROM_PANEL_SUCCESS";

export const CONFIRM_TECH_SHIFT_NO_MODAL = "CONFIRM_TECH_SHIFT_NO_MODAL";
export const CONFIRM_TECH_SHIFT_NO_MODAL_SUCCESS = "CONFIRM_TECH_SHIFT_NO_MODAL_SUCCESS";
export const CONFIRM_TECH_SHIFT_NO_MODAL_FAIL = "CONFIRM_TECH_SHIFT_NO_MODAL_FAIL";

export const RESET_PANEL_SHIFT_ACTION_FLAGS = "RESET_PANEL_SHIFT_ACTION_FLAGS";

// --- end tech related
