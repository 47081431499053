import moment from "moment";
import { create } from "zustand";

import { DEFAULT_DATE_RANGE_DAYS, DEFAULT_RANK, DEFAULT_ZOOM } from "@RooBeta/constants";
import { DateRange, LatLng, MapLocation, Rank, UserLocation } from "@RooBeta/types";

interface SearchShiftsState {
  mapLocation: MapLocation;
  userLocation: UserLocation;
  geoLocation?: LatLng;
  rank: Rank;
  dateRange: DateRange;
}

interface SearchShiftsActions {
  setMapLocation: (mapLocation: MapLocation) => void;
  setUserLocation: (userLocation: UserLocation) => void;
  setGeoLocation: (geoLocation?: LatLng) => void;
  setRank: (rank: Rank) => void;
  setDateRange: (dateRange: DateRange) => void;
  setZoom: (zoom: number) => void;
}

type SearchShiftsStore = SearchShiftsState & SearchShiftsActions;

const initialState: SearchShiftsState = {
  mapLocation: {
    boundingBox: {
      min: { latitude: 0, longitude: 0 },
      max: { latitude: 0, longitude: 0 },
    },
    zoom: DEFAULT_ZOOM,
  },
  userLocation: {
    description: "-",
    latitude: 0,
    longitude: 0,
  },
  geoLocation: undefined,
  rank: DEFAULT_RANK,
  dateRange: {
    format: "range",
    startDate: new Date(),
    endDate: moment().add(DEFAULT_DATE_RANGE_DAYS, "days").toDate(),
  },
};

type SetState = (
  partial:
    | SearchShiftsStore
    | Partial<SearchShiftsStore>
    | ((state: SearchShiftsStore) => SearchShiftsStore | Partial<SearchShiftsStore>),
  replace?: boolean | undefined
) => void;

type GetState = () => SearchShiftsStore;

const createStore = (set: SetState, get: GetState) => ({
  ...initialState,

  setMapLocation: (mapLocation: MapLocation) => set({ mapLocation }),
  setUserLocation: (userLocation: UserLocation) => set({ userLocation }),
  setGeoLocation: (geoLocation?: LatLng) => set({ geoLocation }),
  setRank: (rank: Rank) => set({ rank }),
  setZoom: (zoom: number) =>
    set({
      mapLocation: {
        boundingBox: { ...get().mapLocation.boundingBox },
        zoom,
      },
    }),

  setDateRange: (dateRange: DateRange) =>
    set({
      dateRange:
        dateRange.format === "dates"
          ? {
              ...dateRange,
              dates: [...dateRange.dates].sort((a, b) => moment(a).valueOf() - moment(b).valueOf()),
            }
          : dateRange,
    }),
});

export const useSearchShiftsStore = create<SearchShiftsStore>()(createStore);
