import * as Sentry from "@sentry/react";
import io, { Socket } from "socket.io-client";

import { FEATURE_FLAGS } from "../../constants/postHogFeatureFlags";
import { isRooFeatureEnabled } from "../Wrappers/isRooFeatureEnabled";

export enum SocketSource {
  APP_INIT = "app_init",
  LOGIN_SUCCESS = "login_success",
}

const initSocketWithConfig = (token: string, transportOptions: string[]) => {
  const config = {
    transports: transportOptions,
    upgrade: transportOptions.length > 1,
    rememberUpgrade: false,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 10,
    withCredentials: true,
    timeout: 20000,
  };

  // Remove the try-catch to allow errors to propagate up
  return io(window.RooConfig.MESSAGING_SOCKET_URL, {
    auth: { token },
    transports: transportOptions,
    ...(process.env.NODE_ENV === "test" ? {} : config),
  });
};

export const initSocketConnection = async (source: SocketSource) => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      if (source === SocketSource.LOGIN_SUCCESS) {
        throw new Error("Initiating socket after login succeeded, but no token found");
      }
      return;
    }

    let socket: Socket | null = null;
    const forcePolling = isRooFeatureEnabled(FEATURE_FLAGS.FORCE_POLLING_TRANSPORT_FOR_CHAT);
    const transportOptions = forcePolling ? ["polling"] : ["polling", "websocket"];

    try {
      socket = initSocketWithConfig(token, transportOptions);
    } catch (initError) {
      Sentry.captureException(initError, {
        extra: {
          context: "Failed socket initialization",
          transportOptions,
          forcePolling,
        },
      });

      if (!forcePolling) {
        try {
          socket = initSocketWithConfig(token, ["polling"]);
        } catch (retryError) {
          Sentry.captureException(retryError, {
            extra: {
              context: "Failed socket initialization retry with polling",
            },
          });
        }
      }
    }

    if (!socket) {
      Sentry.captureMessage("Socket initialization returned null");
      return;
    }

    // Register event handlers
    socket.on("connect_error", (error) => {
      Sentry.captureException(error, {
        extra: {
          context: "Socket connection error",
          token: token,
          currentTransports: socket?.io.opts.transports,
          connectionTimeout: socket?.io?.opts?.timeout,
          reconnectionAttempts: socket?.io?.opts?.reconnectionAttempts,
        },
      });
    });

    socket.io.on("reconnect_attempt", (attempt) => {
      Sentry.captureMessage("Socket reconnection attempt", {
        extra: {
          attempt,
          currentTransports: socket?.io.opts.transports,
        },
      });
    });

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket?.connect();
      }
      // Log all disconnect reasons for debugging
      Sentry.captureMessage("Socket disconnected", {
        extra: {
          reason,
          currentTransports: socket?.io.opts.transports,
        },
      });
    });

    socket.io.engine?.on("upgradeError", (err) => {
      Sentry.captureException(err, {
        extra: {
          context: "WebSocket upgrade failed",
          currentTransports: socket?.io.opts.transports,
        },
      });
    });

    window.socket = socket;

    const reconnect = () => {
      if (!document.hidden && window.socket) {
        window.socket.connect();
      } else {
        document.removeEventListener("visibilitychange", reconnect);
      }
    };
    document.addEventListener("visibilitychange", reconnect);
  } catch (error) {
    Sentry.captureException(error);
  }
};
