import { CheckboxGroupProps, CustomCheckboxProps } from "@RooUI";
import { Auth } from "aws-amplify";

import { identifyPosthog } from "../Common/Wrappers/identifyPosthog";
import { UserType, VET_USER_TYPE } from "../constants/UserTypeConstants";

import { rooFetch } from "./common/rooFetch";

export interface VetPreferredAreasOfPractice {
  isPrefNoPreference: boolean;
  isPrefGeneral: boolean;
  isPrefEmergency: boolean;
  isPrefHighVol: boolean;
  isPrefLargeAnimal: boolean;
  isPrefShelter: boolean;
  isPrefSurgery: boolean;
  isPrefSpecialty: boolean;
  isPrefChiropractic: boolean;
  isPrefLaserTherapy: boolean;
  isPrefAcupunture: boolean;
  isPrefUrgentCare: boolean;
  isPrefOther: boolean;
  isPrefVaccine: boolean;
}

export interface VetProfile extends VetPreferredAreasOfPractice {
  vetSchool: string;
  graduationYear: number;
  yearsOfPractice: number;
  freeDaysInMonthToWork: number;
  vetAvailability: number;
  wantsFullTime: number;
  prefOtherHospType?: string;
  apptFlow: any;
  clientInteract: any;
  staffInteract: any;
  resumeName?: string;
  resumePath?: string;
  hasDEALicense?: number;
  hasFearFreeCertification?: number;
}

export interface VetAnimalType {
  animalTypeId: number;
  id: number;
}

export interface VetProcedureType {
  procedureTypeId: number;
  id: number;
}

export interface VetHospitalFocusType {
  hospitalFocusId: number;
  id: number;
}

export interface License {
  id?: number;
  licenceId?: string | number;
  licenceNumber?: string | number;
  expirationMonth?: string | number;
  expirationYear?: string | number;
}

export interface VetPreferences {
  yearsOfPractice?: number;
  lookingToStart?: number;
  currentSituation?: number;
  preferredAreasOfPractice:
    | { checked?: boolean; label: string; id: number }[]
    | CheckboxGroupProps<CustomCheckboxProps>["checkboxes"];
  preferredProcedures:
    | {
        label: string;
        name: string;
        id: number;
        checked?: boolean;
      }[]
    | CheckboxGroupProps<CustomCheckboxProps>["checkboxes"];
  typicalAvailableDays: {
    label: string;
    id: number;
    checked: boolean;
  }[];

  // These are used when the user selects the "Other" or "Varies" options
  // on the respective questions.
  typicalAvailableDaysOpenResponse?: string;
  lookingToStartOpenResponse?: string;
}

export interface VetSpecialization {
  accreditionBody?: string | number;
  id?: number;
  specializationId?: number;
  year?: string;
}

export interface WhyAreYouOnRoo {
  reasonForRegistering?: string;
}

export interface MeetYourRep {
  topicsToDiscuss: {
    label: string;
    id: string;
    checked: boolean;
  }[];
  topicsToDiscussOpenResponse?: string;
}

export interface User {
  characterTypeId?: number;
  additionalSkills?: string;
}

export interface UserDetails extends User {
  city: string;
  zipcode: number | string;
  address: string;
  stateId: number;
}

export interface ProfileImage {
  id: number;
  imagePath: string;
  isShiftPhoto?: number;
}
export interface VetProfileResponse {
  user: User[];
  vet: VetProfile[];
  vetAnimalType: VetAnimalType[];
  vetProcedure: VetProcedureType[];
  vetHospitalFocusPreference: VetHospitalFocusType[];
  vetLicences: License[];
  vetSpecialization: VetSpecialization[];
  profileImage: ProfileImage[];
}

type VetProfileCheckbox = {
  id: number;
  label: string;
  checked: boolean;
};

export interface VetProfileForm extends VetProfile, User {
  vetAnimalType?: VetProfileCheckbox[];
  vetProcedure?: VetProfileCheckbox[];
  licenses?: License[];
  deletedLicenses?: [];
  specializations?: VetSpecialization[];
  isLicenseModified?: {
    isAdded: boolean;
    isUpdated: boolean;
  };
  prefOtherHospTypeOptions?: VetProfileCheckbox[];
  // @TODO: Using a string since the first value is 0, which is falsey. Need
  // to clean this up later with a better fix.
  hospitalFocusPreferences?: (Omit<VetProfileCheckbox, "id"> & { id: string })[];
  profileImage?: ProfileImage[];
  accreditations?: (VetProfileCheckbox & { "data-testid": string })[];
}

export const fetchVetProfile = async (
  userTypeId: UserType,
  vetId: string
): Promise<VetProfileResponse> => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/vet/getVetInfoById?userType=${userTypeId}&vetId=${vetId}`
  );

  const { data } = await response.json();
  return data;
};

interface UpdateVetProfileRequest extends VetProfileForm {
  userId: string;
  vetId: string;
  animalTypeIds: number[];
  procedureTypeIds: number[];
  hospitalTypePreferenceIds: number[];
}
export const updateVetProfile = async (
  request: UpdateVetProfileRequest
): Promise<VetProfileResponse> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/vet/vetProfile`, {
    method: "PUT",
    body: JSON.stringify(request),
  });

  if (response.status !== 200) {
    throw new Error("Api failed");
  }

  const { data } = await response.json();
  return data;
};

export type EventVetSignUpForm = {
  userTypeId: UserType;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  phoneNumber?: string;
  zipcode?: string;
  reenterZipcode?: string;
  travelDistance?: number;
  licenseState?: number;
  licenseNumber?: string;
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
  currentSituation?: number;
} & VetPreferences;

export interface CreateVetDataAccountResponse {
  success: boolean;
  error: string;
  data: EventVetSignUpForm & {
    userId: string;
    vetId: string;
  };
  signInRequest: ReturnType<typeof Auth.signIn>;
}

export const createVetDataAccount = async (
  vet: EventVetSignUpForm
): Promise<CreateVetDataAccountResponse> => {
  const { reenterZipcode, preferredAreasOfPractice, preferredProcedures, ...rest } = vet;
  const email = rest.email.toLowerCase();
  const password = rest.password;

  const { userSub: cognitoId } = await Auth.signUp({
    username: email,
    password,
    attributes: {
      "custom:usertype": rest.userTypeId.toString(),
    },
  });

  const signInRequest = await Auth.signIn(email, password);
  const url = `${window.RooConfig.API_URL}api/user/createAccountWithData`;
  const { password: _, ...userData } = rest;
  const body = JSON.stringify({ ...userData, cognitoId });
  const response = await rooFetch(url, { method: "POST", body });

  if (!response.ok) {
    throw Error("api error");
  }

  const { data } = await response.json();

  // Ideally we'd set metroAreaId here.
  identifyPosthog({
    userId: data.data.userId,
    email,
    userType: VET_USER_TYPE,
  });

  return { signInRequest, ...data };
};

export const resetPlaidMDStatus = async (vetId: number): Promise<boolean> => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/vet/resetPlaidMDStatus/${vetId}`,
    {
      method: "PUT",
    }
  );

  const resData = await response.json();
  return resData;
};
