import { Libraries, Loader } from "@googlemaps/js-api-loader";

export const MAPS_LOAD_OPTIONS = {
  apiKey: window.RooConfig.MAP_KEY_FRONT_END,
  id: "maps-api-loaded-script",
  key: window.RooConfig.MAP_KEY_FRONT_END,
  language: "en",
  libraries: ["places"] as Libraries,
  version: "weekly",
};

export const mapsLoader = new Loader(MAPS_LOAD_OPTIONS);
