import imgCheetah from "../static/images/img_cheetah2.png";
import imgDolphin from "../static/images/img_dolphin2.png";
import imgFalcon from "../static/images/img_falcon2.png";
import imgHorse from "../static/images/img_horse2.png";
import imgOctopus from "../static/images/img_octopus.png";
import imgSquirrel from "../static/images/img_squirrel.png";

export const HOSPITAL_INITIATED = [
  { value: 1, label: "Hired fulltime vet/tech and needs to remove shifts" },
  { value: 2, label: "Issues with having vet coming back to future shifts" },
  { value: 3, label: "Changes with internal/current staff" },
  { value: 4, label: "Switching vets or hospital locations" },
  { value: 5, label: "COVID - Positive case, need to shut down" },
  { value: 6, label: "COVID - Minimal appts / cutting staff" },
  { value: 17, label: "Coverage no longer needed" },
  { value: 7, label: "Other" },
];

export const VET_INITIATED = [
  { value: 8, label: "Vet no-showed" },
  { value: 9, label: "Hired fulltime and needs to remove shifts" },
  { value: 10, label: "Accidentally requested and shift got confirmed" },
  { value: 11, label: "Schedule changes/double booked & cannot make Roo relief shift" },
  { value: 12, label: "Personal emergency" },
  { value: 13, label: "Switching hospital locations" },
  { value: 14, label: "COVID – Tested positive / exposed / high-risk" },
  { value: 15, label: "Other" },
];

export const TECH_INITIATED = [
  { value: 8, label: "Tech no-showed" },
  { value: 9, label: "Suspension" },
  { value: 10, label: "Hired fulltime and need to remove shifts" },
  { value: 11, label: "Accidentally requested and shift got confirmed" },
  { value: 12, label: "Schedule changed/double booked & cannot make shift" },
  { value: 13, label: "Personal emergency" },
  { value: 14, label: "Switching hospital locations" },
  { value: 15, label: "COVID – Tested positive / exposed / high-risk" },
  { value: 16, label: "Other" },
];

export const USER_INITIATED_CANCEL_CONFIRMED_SHIFT__REASONS = [
  { value: 10, label: "Accidentally requested shift" },
  { value: 11, label: "Schedule changes" },
  { value: 18, label: "Illness" },
  { value: 12, label: "Personal emergency" },
  { value: 9, label: "Hired fulltime" },
  { value: 15, label: "Other" },
];
export const HOSPITAL_PRICE_CHANGE_REASON = [
  { value: 1, label: "Boosting price for hospital" },
  { value: 2, label: "Other" },
];

export const VET_PRICE_CHANGE_REASON = [
  { value: 3, label: "Vet wanted and agreed to higher price" },
  { value: 4, label: "Vet bonus payout added on" },
  { value: 5, label: "Referral bonus payout added on" },
  { value: 6, label: "RTTR price added on" },
  { value: 7, label: "Other" },
];

export const TECH_PRICE_CHANGE_REASON = [
  { value: 3, label: "Tech wanted and agreed to higher price" },
  { value: 4, label: "Tech bonus payout added on" },
  { value: 5, label: "Referral bonus payout added on" },
  { value: 6, label: "RTTR price added on" },
  { value: 7, label: "Other" },
];

export const SURGERY_TIER = [
  { value: 1, label: "Surgery Tier 1 (Spay/Neuter, Dental)", shortLabel: "Tier 1" },
  {
    value: 2,
    label: "Surgery Tier 2 (Ultrasound, Orthopedics, Soft-tissue, Emergent, Elective)",
    shortLabel: "Tier 2",
  },
];

export const SHIFT_INSTANT_BOOKABLE = [
  {
    value: 3,
    label: "No, I want to review all candidates",
    shortLabel: "No",
    desc: "You’ll need to review and confirm all vets who request this shift. Keep an eye on your inbox!",
  },
  {
    value: 1,
    label: "Yes, book any vet",
    shortLabel: "Any vets",
    desc: "The first vet who requests this shift will be booked instantly. No need to worry about confirming for this shift!",
  },
  {
    value: 2,
    label: "Yes, book only past vets",
    shortLabel: "Only past vets",
    desc: "Any vet who’s worked a past shift and you’ve rated 4 stars or higher can instantly book this shift. You’ll still need to confirm requests from other vets.",
  },
];
export const hospitalCancellationReasonsRadio = [
  {
    value: 1,
    label: "Internal staff coverage found",
  },
  {
    value: 2,
    label: "Shift confirmed in error",
  },
  {
    value: 3,
    label: "Low appointment flow",
  },
  {
    value: 4,
    label: "COVID-19 related",
  },
  {
    value: 5,
    label: "Full-time veterinarian hired",
  },
  {
    value: 6,
    label: "Other",
  },
];
export const hospitalCancellationReasons = [
  {
    value: 1,
    label: "Internal staff coverage found",
    id: 1,
  },
  {
    value: 2,
    label: "Shift confirmed in error",
    id: 2,
  },
  {
    value: 3,
    label: "Low appointment flow",
    id: 3,
  },
  {
    value: 4,
    label: "COVID-19 related",
    id: 4,
  },
  {
    value: 5,
    label: "Full-time veterinarian hired",
    id: 5,
  },
  {
    value: 6,
    label: "Other",
    id: 6,
  },
];

export const characterAnimalTypes = [
  { id: 1, img: imgCheetah, name: "Cheetah", description: "Fast-paced, agile" },
  { id: 2, img: imgHorse, name: "Horse", description: "Hard-working, faithful" },
  { id: 3, img: imgDolphin, name: "Dolphin", description: "Free-flowing, adaptable" },
  { id: 4, img: imgFalcon, name: "Falcon", description: "Laser-focused, determined" },
  { id: 5, img: imgSquirrel, name: "Squirrel", description: "Fun-loving, energetic" },
  { id: 6, img: imgOctopus, name: "Octopus", description: "Multi-tasking, clever" },
];

export const ALERT_FREQUENCY_RADIOS = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "3x a week" },
  { value: 3, label: "Daily" },
  { value: 4, label: "Anytime" },
];

export const SURGERY_EXPECTED_ID = 6;
export const NO_SURGERY_EXPECTED_ID = 7;
