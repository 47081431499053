import { Flex, FlexProps } from "antd";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";

import { grayLightest, grayWhite, purpleBase, secondaryFont } from "../../styles/constants";
import { Button, ButtonProps } from "../Button";

/**
 * StepperContext, useStepper
 */

const StepperContext: React.Context<{
  count: number;
  step?: number;
  onStepChange?: (value: number) => void;
}> = createContext({
  count: 0,
});

export const useStepper = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }

  const { count, step = 0, onStepChange = () => {} } = context;

  const goForward = useCallback(() => {
    step < count - 1 && onStepChange(step + 1);
  }, [step, count, onStepChange]);

  const goBack = useCallback(() => {
    step > 0 && onStepChange(step - 1);
  }, [step, onStepChange]);

  return {
    count,
    step,
    goForward,
    goBack,
  };
};

/**
 * Stepper
 */

export interface StepperProps {
  count: number;
  step?: number;
  onStepChange?: (value: number) => void;
  children: React.ReactNode;
}

export const Stepper: React.FC<StepperProps> = ({ count, step, onStepChange, children }) => {
  const [index, setIndex] = useState(0);

  const value = useMemo(
    () => ({ count, step: step ?? index, onStepChange: onStepChange ?? setIndex }),
    [count, step, onStepChange, index, setIndex]
  );

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};

/**
 * StepperSteps
 */

export type StepperStepsProps = FlexProps;

export const StepperSteps = ({ children, ...props }: StepperStepsProps) => (
  <Flex justify="space-around" align="center" {...props}>
    {children}
  </Flex>
);

/**
 * StepSeparatorStyle
 */

type StepSeparatorStyle = {
  leftFill?: string;
  leftStroke?: string;
  rightFill?: string;
  rightStroke?: string;
};

const stepSeparatorStyles: Record<StepperStatus, StepSeparatorStyle> = {
  active: {
    leftStroke: grayLightest,
    rightFill: purpleBase,
    rightStroke: purpleBase,
  },
  inactive: {
    leftStroke: grayLightest,
    rightStroke: grayLightest,
  },
  previous: {
    leftFill: purpleBase,
    leftStroke: purpleBase,
    rightStroke: grayLightest,
  },
};

/**
 * StepSeparator
 */

type StepSeparatorProps = {
  status: StepperStatus;
};

const StepSeparator = ({ status }: StepSeparatorProps) => {
  const {
    leftFill = grayWhite,
    rightFill = grayWhite,
    leftStroke = grayWhite,
    rightStroke = grayWhite,
  } = stepSeparatorStyles[status];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 45">
      <path fill={leftFill} strokeWidth="0" d="M24 44.5L8 44.5 18.67 22.5 8 0.5 24 0.5 24 44.5z" />
      <path fill={rightFill} strokeWidth="0" d="M0 0.5L5.33 0.5 16 22.5 5.33 44.5 0 44.5 0 0.5z" />
      <path
        fill="none"
        stroke={leftStroke}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M23.5 44.5L7.5 44.5 18.17 22.5 7.5 0.5 23.5 0.5"
      />
      <path
        fill="none"
        stroke={rightStroke}
        strokeLinejoin="round"
        d="M0 0.5L5.33 0.5 16 22.5 5.33 44.5 0 44.5"
      />
    </svg>
  );
};

/**
 * StepperStep
 */

type StepperStatus = "active" | "inactive" | "previous";

export type StepperStepProps = React.HTMLAttributes<HTMLDivElement> & {
  step: number;
};

export const StepperStep = ({ step, style, children, ...props }: StepperStepProps) => {
  const stepper = useStepper();
  const isFirst = step === 0;
  const isLast = step === stepper.count - 1;
  const isCurrent = step === stepper.step;
  const isPrevious = step === stepper.step - 1;
  const status: StepperStatus = isCurrent ? "active" : isPrevious ? "previous" : "inactive";
  const borderColor = isCurrent ? purpleBase : grayLightest;

  const Container = styled.div`
    flex-grow: 1;
    font-family: ${secondaryFont};
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: ${isCurrent ? grayWhite : purpleBase};
    background-color: ${isCurrent ? purpleBase : grayWhite};
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
    padding-top: 7px;
  `;

  return (
    <Flex
      style={{
        flexGrow: 1,
        height: 44,
      }}
    >
      <Container
        style={{
          borderLeft: isFirst ? `1px solid ${borderColor}` : undefined,
          borderRight: isLast ? `1px solid ${borderColor}` : undefined,
          ...style,
        }}
        {...props}
      >
        {children}
      </Container>
      {!isLast && <StepSeparator status={status} />}
    </Flex>
  );
};

/**
 * StepperContent
 */

export type StepperContentProps = FlexProps & { step: number };

export const StepperContent = ({ step, children, ...props }: StepperContentProps) => {
  const stepper = useStepper();

  return step === stepper.step ? (
    <Flex justify="space-around" align="center" {...props}>
      {children}
    </Flex>
  ) : null;
};

/**
 * StepperPrevious
 */

export const StepperPrevious = (props: ButtonProps) => {
  const { step, goBack } = useStepper();
  const isFirst = step === 0;

  return isFirst ? null : (
    <Button
      title="Back"
      {...props}
      onClick={(e) => {
        goBack();
        props.onClick?.(e);
      }}
    />
  );
};

/**
 * StepperNext
 */

export const StepperNext = (props: ButtonProps) => {
  const { count, step, goForward } = useStepper();
  const isLast = step === count - 1;

  return (
    <Button
      $buttonType="primary"
      title={isLast ? "Done" : "Continue"}
      {...props}
      onClick={(e) => {
        goForward();
        props.onClick?.(e);
      }}
    />
  );
};
