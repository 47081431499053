import posthog from "posthog-js";

export const SHIFT_PRICING_SURVEY_ID = (() => {
  switch (window.RooConfig.WHICH_ENV) {
    case "prod":
      return "0191be28-62b5-0000-c730-a94ddc1db326";
    case "stage":
      return "0191e225-0adc-0000-2add-abaafcca5ba4";
    default:
      return "0191c352-25e0-0000-224a-504ff75d6909";
  }
})();

const localStorageKey = (surveyId: string) => {
  return `hasInteractedWithSurvey_${surveyId}`;
};

export const hasSubmittedSurvey = (surveyId: string) => {
  return localStorage.getItem(localStorageKey(surveyId));
};

export const surveyShown = (surveyId: string, surveyName: string) => {
  posthog.capture("survey shown", {
    $survey_id: surveyId,
    $survey_name: surveyName,
  });
};

export const surveyDismissed = (surveyId: string, surveyName: string) => {
  localStorage.setItem(localStorageKey(surveyId), "true");
  posthog.capture("survey dismissed", {
    $survey_id: surveyId,
    $survey_name: surveyName,
  });
};

export const submitSurveyResponse = (
  surveyId: string,
  surveyName: string,
  response: string | string[]
) => {
  localStorage.setItem(localStorageKey(surveyId), "true");
  posthog.capture("survey sent", {
    $survey_id: surveyId,
    $survey_response: response,
    $survey_name: surveyName,
  });
};
