import React, { ReactElement } from "react";

import { CardContainer, LeftCardContainer, RightCardContainer } from "./CardStyles";

export interface CardProps {
  leftContainerInfo?: ReactElement;
  rightContainerInfo?: ReactElement;
  $leftContainerBackgroundColor?: string;
}

export const Card: React.FC<CardProps> = (props) => {
  const { leftContainerInfo, rightContainerInfo } = props;
  return (
    <CardContainer>
      <LeftCardContainer {...props}>{leftContainerInfo}</LeftCardContainer>
      <RightCardContainer>{rightContainerInfo}</RightCardContainer>
    </CardContainer>
  );
};
