import { SectionCard } from "@RooUI";
import React from "react";

import { NewShiftPreferencesForm } from "./NewShiftPreferencesForm";

export const ShiftNotificationPreferencesNew = ({
  shiftPreferencesId,
  frequencyTypeCode,
}: {
  shiftPreferencesId: number;
  frequencyTypeCode: number;
}) => {
  return (
    <SectionCard
      headerIconName="MailOutline"
      headerText="NEW SHIFT ALERT PREFERENCES"
      data-testid="newShiftPreferencesFormContainer"
    >
      <NewShiftPreferencesForm
        shiftPreferencesId={shiftPreferencesId}
        frequencyTypeCode={frequencyTypeCode}
      />
    </SectionCard>
  );
};
