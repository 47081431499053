import { useQuery } from "@tanstack/react-query";

import { POST } from "@roo-dev/roo-node-types";

import { post } from "../../api/common/rooFetch";
import { isFlexParamsModified } from "../../modalBodies/isFlexParamsModified";

interface ShiftPricingChangesParams extends POST.ShiftPricing.RecalculateShiftPricingRequest {
  originalStartTime: string;
  originEndTime: string;
}

export const useShiftPricingChanges = ({
  shiftId,
  isRequestWithoutSurgery,
  requestedStartTime,
  requestedEndTime,
  originalStartTime,
  originEndTime,
}: ShiftPricingChangesParams) =>
  useQuery(
    ["shiftPricing", shiftId, isRequestWithoutSurgery, requestedStartTime, requestedEndTime],
    () => {
      return post<
        POST.ShiftPricing.RecalculateShiftPricingRequest,
        POST.ShiftPricing.ShiftPricingResponse
      >("api/pricing/recalculateShiftPricing", {
        shiftId,
        isRequestWithoutSurgery,
        requestedStartTime,
        requestedEndTime,
      });
    },
    {
      enabled:
        !!shiftId &&
        isFlexParamsModified(
          requestedStartTime,
          originalStartTime,
          requestedEndTime,
          originEndTime,
          isRequestWithoutSurgery
        ),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
