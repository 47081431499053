import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { BaseOptionType, Select, SelectProps } from "@RooUI";

import { ControlledComponentProps } from "./types";

interface ControlledSelectProps<T extends FieldValues>
  extends Omit<SelectProps<BaseOptionType>, "onChange" | "value" | "defaultValue">,
    ControlledComponentProps<T> {}

export const ControlledSelect = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...inputProps
}: ControlledSelectProps<T>) => (
  <Controller<T>
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <Select value={field.value} onChange={({ value }) => field.onChange(value)} {...inputProps} />
    )}
  />
);
