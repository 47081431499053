import { useMemo } from "react";
import { create } from "zustand";

import { Pin, SearchResponse, ShiftList, ShiftWithIndex } from "@RooBeta/types";

import { useSearchShiftsStore } from "./useSearchShiftsStore";

type ShiftsData = Pick<
  SearchResponse,
  "shiftList" | "chips" | "unclusteredCount" | "clusteredCount" | "shiftCount" | "checksum"
>;

type ShiftsState = ShiftsData & {
  selectedPin: Pin | null;
  isLoading: boolean;
};

interface ShiftsActions {
  setShiftsData: (shiftsData: ShiftsData) => void;
  setSelectedPin: (pin?: Pin | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const initialState: ShiftsState = {
  shiftList: { format: "ungrouped", shifts: [], count: 0 },
  chips: [],
  unclusteredCount: 0,
  clusteredCount: 0,
  shiftCount: 0,
  checksum: "",
  selectedPin: null,
  isLoading: false,
};

export const useShiftsStore = create<ShiftsState & ShiftsActions>((set) => ({
  ...initialState,
  setShiftsData: (shiftsData) => set(shiftsData),
  setSelectedPin: (selectedPin) => set({ selectedPin }),
  setIsLoading: (isLoading) => set({ isLoading }),
}));

type SelectedHospital = {
  name: string;
  count: number;
  shifts: ShiftWithIndex[];
  shiftList: ShiftList;
};

export const useSelectedHospital = (): SelectedHospital | undefined => {
  const { rank } = useSearchShiftsStore();
  const { selectedPin, shiftList } = useShiftsStore();

  return useMemo(() => {
    if (!selectedPin) {
      return undefined;
    }

    const shifts = (
      shiftList.format === "grouped"
        ? shiftList.groups.flatMap(({ shifts }) => shifts)
        : shiftList.shifts
    )
      .filter(({ hospitalId }) => hospitalId === selectedPin.hospitalId)
      .map((shift, index) => ({ ...shift, index }));

    const name = selectedPin.hospitalName;
    const count = shifts.length;

    switch (rank) {
      case "date": {
        const groups = Object.entries(
          shifts.reduce(
            (groups, shift) => ({
              ...groups,
              [shift.shiftDateFormatted]: [...(groups[shift.shiftDateFormatted] ?? []), shift],
            }),
            {} as Record<string, ShiftWithIndex[]>
          )
        ).map(([date, shifts]) => ({ label: date, shifts }));

        return {
          name,
          count,
          shifts,
          shiftList: { format: "grouped", groupBy: "date", groups, count },
        };
      }
      case "distance":
        return {
          name,
          count,
          shifts,
          shiftList: {
            format: "ungrouped",
            shifts: shifts.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0)),
            count,
          },
        };
      case "price":
        return {
          name,
          count,
          shifts,
          shiftList: {
            format: "ungrouped",
            shifts: shifts.sort((a, b) => b.price - a.price),
            count,
          },
        };
    }
  }, [selectedPin, shiftList, rank]);
};

export const useHospitals = (): Map<number, string> => {
  const { shiftList } = useShiftsStore(({ shiftList }) => ({ shiftList }));

  return useMemo(
    () =>
      (shiftList.format === "grouped"
        ? shiftList.groups.flatMap(({ shifts }) => shifts)
        : shiftList.shifts
      ).reduce((map, { hospitalId, hospitalName }) => {
        map.set(hospitalId, hospitalName);
        return map;
      }, new Map<number, string>()),
    [shiftList]
  );
};
