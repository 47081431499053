import { forEach } from "lodash";

export function filterExternships(externships) {
  return externships.map((o) => {
    o.appliedStudentsDetails = [];
    o.hiredStudentsDetails = [];
    o.contactedStudentsDetails = [];
    if (o.externshipStatusDetails) {
      const externshipStatusDetails = JSON.parse(`[${o.externshipStatusDetails}]`);
      if (externshipStatusDetails && externshipStatusDetails.length) {
        externshipStatusDetails.forEach((itm) => {
          if (itm.confirmedOn && itm.confirmedBy) {
            o.hiredStudentsDetails.push(itm);
          } else if (itm.contactedOn && itm.contactedBy) {
            o.contactedStudentsDetails.push(itm);
          } else {
            o.appliedStudentsDetails.push(itm);
          }
        });
      }
    } else {
      o.hiredStudentsDetails = [];
      o.appliedStudentsDetails = [];
      o.contactedStudentsDetails = [];
    }
    return o;
  });
}

export function sortExternshipsForHospital(externships) {
  return externships
    .sort((ext1, ext2) => new Date(ext1.createdOn) - new Date(ext2.createdOn))
    .map((externship) => ({
      ...externship,
      externshipRequestDetails: externship.externshipRequestDetails
        ? JSON.parse(`[${externship.externshipRequestDetails}]`)
        : [],
    }));
}

export function sortSearchedExternships(externships) {
  const confirmedExternships = [];
  const contactedExternships = [];
  const appliedExternships = [];
  const deniedExternships = [];
  const openExternships = [];
  const completedExternships = [];
  forEach(externships, (o) => {
    if (o.confirmedOn && o.isStudentData === 1 && o.completed === 0) {
      o.isConfirmed = true;
      confirmedExternships.push(o);
    } else if (o.confirmedOn && o.isStudentData === 1 && o.completed === 1) {
      o.isCompleted = true;
      completedExternships.push(o);
    } else if (o.contactedOn && o.isStudentData === 1) {
      o.isContacted = true;
      contactedExternships.push(o);
    } else if (o.deniedOn && o.isStudentData === 1) {
      o.isDenied = true;
      deniedExternships.push(o);
    } else if (o.requestId && o.isStudentData === 1) {
      o.isApplied = true;
      appliedExternships.push(o);
    } else {
      o.isOpen = true;
      openExternships.push(o);
    }
  });

  openExternships.sort((a, b) => {
    return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
  });

  return {
    sortedExternships: [
      ...openExternships,
      ...confirmedExternships,
      ...contactedExternships,
      ...appliedExternships,
      ...completedExternships,
      ...deniedExternships,
    ],
    externshipsByStatus: {
      confirmed: confirmedExternships,
      contacted: contactedExternships,
      applied: appliedExternships,
      denied: deniedExternships,
      open: openExternships,
      completed: completedExternships,
    },
  };
}
