import React from "react";

import { useHeaderHeight } from "@RooBeta/hooks";

import { PageContainer } from "./styles";

interface PageProps {
  children: React.ReactNode;
  className?: string;
}

export const Page = ({ children, className }: PageProps) => {
  const headerHeight = useHeaderHeight();
  return (
    <PageContainer $headerHeight={headerHeight} className={className}>
      {children}
    </PageContainer>
  );
};
