import { SelectProps } from "@RooUI";
import React, { useEffect, useState } from "react";
import { Control, useFormContext } from "react-hook-form";

import { RegistrationForm } from "../../RegistrationUpdated/types";
import { ControlledInput, ControlledSelect } from "../Controllers";
import { MediaSourceOption } from "../Form/hooks/useMediaSourceOptions";
import { fetchMediaSourceOptions } from "../Form/service";

interface HearAboutRooSelectProps
  extends Omit<SelectProps<MediaSourceOption>, "options" | "value"> {
  control: Control<{ hearAboutRoo?: number; referralCodeId?: string }>;
  defaultValue?: number;
}

const DIRECT_REFERRAL_SELECTION_ID = 6;

export const ControlledHearAboutRooSelect = (props: HearAboutRooSelectProps) => {
  const [mediaSourceOptions, setMediaSourceOptions] = useState<MediaSourceOption[]>([]);
  const { watch } = useFormContext<RegistrationForm>();

  useEffect(() => {
    const fetchOptions = async () => {
      const options = await fetchMediaSourceOptions();
      setMediaSourceOptions(options);
    };

    fetchOptions();
  }, []);

  const hearAboutRooSelection = watch("hearAboutRoo");

  return (
    <>
      <ControlledSelect
        name="hearAboutRoo"
        control={props.control}
        label={props.label || "Source"}
        options={mediaSourceOptions}
        defaultValue={props.defaultValue}
        data-testid="hearAboutRoo"
      />
      {hearAboutRooSelection === DIRECT_REFERRAL_SELECTION_ID && (
        <ControlledInput
          name="referralCodeId"
          control={props.control}
          label="Referral code (Optional)"
        />
      )}
    </>
  );
};
