import { purpleBase } from "@RooUI";
import React from "react";

import { RooIcon } from "@RooBeta/components";
import { Icon } from "@RooBeta/types";

import {
  DrawerHeaderGroup,
  DrawerNavigationItem,
  LinkStyled,
  ListItemStyled,
  UnorderedListStyled,
} from "./styles";

export interface NavItemProps {
  icon: Icon;
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  active?: boolean;
}

const Item = ({ icon, to, onClick, children, active }: NavItemProps) => {
  const content = (
    <ListItemStyled>
      <DrawerNavigationItem $active={active} data-active={active}>
        <RooIcon icon={icon} size="l" />
        {children}
      </DrawerNavigationItem>
    </ListItemStyled>
  );

  if (to) {
    return (
      <LinkStyled to={to} onClick={onClick}>
        {content}
      </LinkStyled>
    );
  }

  return (
    <div role="button" onClick={onClick} style={{ cursor: "pointer" }}>
      {content}
    </div>
  );
};

export interface GroupProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

const Group = ({ label, children, className }: GroupProps) => (
  <div className={className}>
    <DrawerHeaderGroup level={4} color={purpleBase}>
      {label}
    </DrawerHeaderGroup>
    <UnorderedListStyled>{children}</UnorderedListStyled>
  </div>
);

export interface MainProps {
  children: React.ReactNode;
  label: string;
}

const Main = ({ children, label }: MainProps) => {
  return (
    <nav aria-label={label}>
      <UnorderedListStyled>{children}</UnorderedListStyled>
    </nav>
  );
};

// Attach sub-components
const DrawerNavigation = {
  Item,
  Group,
  Main,
};

// Export everything
export { DrawerNavigation };
