import { Input } from "antd";
import styled from "styled-components";

import {
  BodyText,
  blueDark600,
  blueLightest,
  disabledTextDark,
  grayBackground,
  grayBase,
  grayLight300,
  grayWhite,
  inputBorderRadius,
  redBase,
} from "../../styles/constants";

interface CustomInputStyledProps {
  $status?: string;
  $allowClear?: boolean;
  $suffix?: string;
}

export const CustomInput = styled(Input)<CustomInputStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 8px;
  width: clamp(90px, 100%, 700px);
  height: 48px;
  background: ${(props) => (props.disabled ? grayBackground : grayWhite)};
  border: 2px solid ${(props) => `${props.$status === "error" ? redBase : blueLightest}`};
  border-radius: ${inputBorderRadius};
  ${BodyText}
  color: ${grayBase};
  ::placeholder {
    ${BodyText}
    color: ${(props) => `${props.$status === "error" ? redBase : grayLight300}`};
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: ${redBase};
    color: ${redBase};
  }
  &.ant-input-disabled,
  &.ant-input[disabled] {
    background-color: ${grayBackground};
    border-color: ${blueLightest};
    color: ${disabledTextDark};
  }
  &:hover,
  &:focus,
  &:focus-within {
    border: 2px solid ${blueDark600};
  }
  &.ant-input-affix-wrapper > input.ant-input {
    ${BodyText}
    color: ${grayBase};
    line-height: ${(props) => `${props.$suffix || props.$allowClear ? "20px" : ""}`};
    ::placeholder {
      ${BodyText}
      color: ${(props) => `${props.$status == "error" ? redBase : grayLight300}`};
    }
  }
  &.ant-input-affix-wrapper > span.ant-input-suffix {
    align-items: flex-start;
    > span.ant-input-clear-icon {
      position: relative;
      bottom: 4px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
