export const isFlexParamsModified = (
  requestedShiftStartTime: string,
  startTime: string,
  requestedShiftEndTime: string,
  endTime: string,
  isRequestWithoutSurgery: boolean
) => {
  return !!(
    (requestedShiftStartTime && requestedShiftStartTime !== startTime) ||
    (requestedShiftStartTime && requestedShiftEndTime !== endTime) ||
    isRequestWithoutSurgery
  );
};
