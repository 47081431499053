import { useMutation } from "@tanstack/react-query";

import { post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey } from "@RooBeta/types";

interface UseUnblockHospitalResponse {
  data: { success: boolean };
}

type BaseUnblockHospitalRequest = {
  hospitalId: number;
  userId: number;
};

type UseUnblockHospitalRequest =
  | (BaseUnblockHospitalRequest & { vetId: number })
  | (BaseUnblockHospitalRequest & { techId: number });

export const useUnblockHospitalMutation = () => {
  const { userId, provider } = useRequiredAuthorizedUser();

  return useMutation({
    mutationFn: async (params: Pick<UseUnblockHospitalRequest, "hospitalId">) =>
      post<UseUnblockHospitalRequest, UseUnblockHospitalResponse>(
        provider.providerType === "VET"
          ? "api/vet/removeBlockedHospital"
          : "api/tech/removeBlockedHospital",
        provider.providerType === "VET"
          ? { ...params, userId, vetId: provider.providerId }
          : { ...params, userId, techId: provider.providerId }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CacheKey.BlockedHospitals, userId] });
    },
  });
};
