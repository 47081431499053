import {
  FlexLayout,
  Link,
  SmallBodyText,
  SmallText,
  containerBorderRadius,
  grayDarkest,
  grayLight300,
  grayWhite,
  greenBase,
  shadowStyle,
  spacingL,
  spacingS,
  yellowBackground,
} from "@RooUI";
import { styled } from "styled-components";

/**
 * Tab Content
 */

export const TabContentContainer = styled.div`
  padding: ${spacingL};
`;

export const FooterContainer = styled.div`
  padding: ${spacingS} ${spacingL};
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  z-index: 2;
  background-color: ${grayWhite};
`;

/**
 * Carousel
 */

export const CarouselContainer = styled.div`
  position: relative;
  border-radius: ${containerBorderRadius};
  overflow: hidden;
  margin: ${spacingL} 0 ${spacingL} ${spacingL};
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: ${grayDarkest};
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  box-shadow: ${shadowStyle};

  &:hover {
    background-color: ${grayWhite};
  }

  &:active {
    opacity: 0.5;
  }
`;

export const LeftArrow = styled(Arrow)`
  left: 32px;
`;

export const RightArrow = styled(Arrow)`
  right: 32px;
`;

export const CarouselDotsOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
`;

export const CarouselImage = styled.img`
  height: 200px;
  object-fit: cover;
`;

/**
 * Metadata
 */

export const PreviousPrice = styled(SmallText)`
  text-decoration: line-through;
  color: ${grayLight300};
`;

/**
 * Warnings
 */

export const WarningContainer = styled.div`
  ${SmallBodyText}
  background-color: ${yellowBackground};
  padding: ${spacingL};
`;

/**
 * Footer
 */

export const Highlight = styled.span`
  color: ${greenBase};
  font-weight: bold;
`;

export const HospitalWebsiteLinkStyled = styled(Link)`
  word-break: break-word;
`;

export const FlexLayoutStyled = styled(FlexLayout)`
  width: 100%;
`;
