import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

import { identifyPosthog } from "../../Common/Wrappers/identifyPosthog";
import { UserType } from "../../constants/UserTypeConstants";
import { cognitoDeleteUser } from "../aws/cognitoDeleteUser";
import { ErrorResponse } from "../common/error";
import { APIResponse } from "../common/response";
import { rooFetch } from "../common/rooFetch";

export type Request = {
  emailId: string;
  userTypeId: UserType;
  userRoleId: number;
  isFromMobile: number;
} & Partial<{
  firstName: string;
  lastName: string;
  mobilePhoneNumber: string;
  cognitoId: string; // Lifted in the createAccount function
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
}>;

export type Response<RequestBody> =
  | APIResponse<
      RequestBody & {
        userId: number;
        signInRequest: ReturnType<typeof Auth.signIn>;
      }
    >
  | ErrorResponse;

export const createUser = async <NarrowRequest extends Request>(
  request: NarrowRequest & { password: string }
): Promise<Response<NarrowRequest>> => {
  const emailId = request.emailId.toLowerCase();
  const firstName = request.firstName.trim();
  const lastName = request.lastName.trim();

  // Sign up with AWS
  let signInRequest;
  try {
    const signupIdp = await Auth.signUp({
      username: emailId,
      password: request.password,
      attributes: {
        "custom:usertype": request.userTypeId.toString(),
      },
    });
    signInRequest = await Auth.signIn(emailId, request.password);
    delete request.password;
    request.cognitoId = signupIdp.userSub;
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while signing up: " } });
    return {
      status: 409,
      message: error.message,
    };
  }

  // Create account with backend
  let response;
  try {
    response = await rooFetch(`${window.RooConfig.API_URL}api/user`, {
      method: "POST",
      body: JSON.stringify({ ...request, emailId, firstName, lastName }),
    });

    if (response.status !== 200) {
      throw new Error(`failed to create user account for ${emailId}`);
    }
  } catch (error) {
    // Rollback cognito sign up
    Sentry.captureException(error, { extra: { context: "Unable to create roo user account: " } });
    await cognitoDeleteUser();
    return {
      status: response?.status,
      message: "An error occured while creating the account",
    };
  }
  const createUserResponse = await response.json();
  const {
    data: { userId },
  } = createUserResponse;
  // Handle posthog.
  identifyPosthog({
    userId,
    userType: request.userTypeId,
    email: emailId,
    firstName,
    lastName,
  });

  window.dataLayer?.push({
    event: "IDENTIFY",
    email: request.emailId,
    ui: userId,
  });

  return { ...createUserResponse, signInRequest };
};
