import { Heading, Link, MediumText, Text } from "@RooUI";
import React from "react";

import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";

export const MarsContract = ({
  isHospitalView,
  vetId,
}: {
  isHospitalView: boolean;
  vetId: number;
}) => {
  return (
    <>
      <div className="heading-container">
        {!isHospitalView && (
          <Link
            icon="Download"
            iconPosition="right"
            href={`https://assets.roo.vet/enterprise-contract/mars-vsa.pdf`}
            target="_blank"
            download
            rel="noopener noreferrer"
            onClick={() => {
              EventTracker.send({
                eventName: Event.Name.REVIEW_AGREEMENT_DOWNLOAD,
                eventType: Event.Type.CLICK,
                entityType: Event.Entity.VET,
                entityId: vetId,
                context: {
                  marsNDAFlowV2: true,
                },
              });
            }}
          >
            Download
          </Link>
        )}
        <Heading level={5}>MVH Veterinary Services Agreement</Heading>
      </div>
      <div className="contract-container">
        <Text>
          This Veterinary Services, Release and Waiver of Liability, Confidentiality and
          Non-Solicitation Agreement ("Agreement"), dated and effective as of
          ________________________________, is between the temporary contractor who has signed below
          (“I”) and Vicar Operating, Inc., a Delaware corporation doing business as Mars Veterinary
          Health and its Affiliates ("Client”) (as defined in Section 14 below). As a condition of
          temporary placement/services, I agree as follows:{" "}
        </Text>
        <div>
          <MediumText>
            1. Representation and Warranties.
            <Text>I represent and warrant to Client the following: </Text>
          </MediumText>
          <Text>
            I am a licensed veterinarian duly licensed as indicated by the applicable governmental
            authority in the appropriate jurisdiction in which I will be performing veterinary
            services at Client’s hospital under this Agreement (“Services”). I am in possession of
            all other licenses, permits, registrations, or other authorizations necessary to perform
            Services in the appropriate jurisdiction. I am duly licensed with the United States Drug
            Enforcement Agency, and any comparable state agency, to order, possess, handle, and
            administer controlled substances and other pharmaceuticals. I am in good standing with
            the veterinary medical board (or comparable regulatory authority) in the state in which
            I will be performing Services and have not had any disciplinary action in the past five
            (5) years in any state. I will perform Services in a professional, competent and timely
            manner, in accordance with all applicable laws, regulations, self-governing professional
            organizational rules and best industry practices. I will arrive on time for my shift and
            perform the Services for the entire shift, unless otherwise agreed with Client. I will,
            at my sole expense, procure and maintain in full force and effect while performing
            Services, health insurance and professional liability insurance in an amount that
            complies with industry standard and applicable state law. I acknowledge and agree that
            Client will not be responsible for (a) making any insurance contributions, including for
            unemployment or disability, or obtaining workers' compensation insurance on my behalf,
            or (b) paying or withholding any income, payroll, Social Security, or other federal,
            state, or local taxes. Further, I acknowledge and agree that in the event of any
            disputes, claims, or issues related to workers compensation claims, payment, or taxes, I
            shall direct such matters to the veterinary staffing agency or placement service through
            which I have been assigned Services (“Agency”), as Agency is responsible for handling
            these aspects of the services relationship. I will not demand or request, and will not
            accept, any payment or compensation from Client for Services or other activity performed
            during my shift, otherwise alter the scope, nature or duration of the Services to be
            performed during my shift. I am an independent contractor working as a sole proprietor,
            LLC, or agency worker. I acknowledge and agree that this Agreement shall not be
            construed to create any association, partnership, joint venture, employment, or agency
            relationship between myself and Client for any purpose. I am not an employee of Client
            and I am not entitled to holidays, vacations, disability, insurance, pensions or
            retirement plans, or any other benefits offered or provided by Client to its employees.
            I am not: (i) a current associate of Client; or (ii) a former associate of Client that
            is ineligible for rehire. My temporary assignment with Client does not and will not
            breach any prior agreement that I may have with another Person, as defined in Section
            15. I have not and will not improperly use or disclose during the course of my temporary
            assignment with Client a previous/concurrent employer's or third party's proprietary
            information or trade secrets unless the previous/concurrent employer or third party has
            consented to that use and/or disclosure in writing. I have not entered into and will not
            enter into any agreement, either written or oral, that conflict with this Agreement.
          </Text>
        </div>
        <MediumText>
          2. RELEASE AND WAIVER OF LIABILITY.{" "}
          <Text>
            I AM AWARE AND UNDERSTAND THAT THE SERVICES TO BE PERFORMED UNDER THIS AGREEMENT
            INVOLVES THE RISK OF PERSONAL INJURY AND MAY BE DANGEROUS. NOTWITHSTANDING THE RISK, I
            ACKNOWLEDGE THAT I AM KNOWINGLY AND VOLUNTARILY PERFORMING THE SERVICES WITH AN EXPRESS
            UNDERSTANDING OF THE DANGER INVOLVED AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND ALL
            RISKS OF INJURY, DISABILITY, DEATH, AND/OR PROPERTY DAMAGE ARISING FROM MY PERFORMANCE
            OF THE SERVICES, WHETHER ARISING OUT OF THE ORDINARY NEGLIGENCE OF CLIENT OR OTHERWISE.
            I AGREE TO RELEASE, WAIVE, DISCHARGE, AND COVENANT NOT TO SUE CLIENT AND ITS AFFILIATES,
            AND EACH OF THEIR RESPECTIVE MEMBERS, MANAGERS, DIRECTORS, OFFICER, SHAREHOLDERS,
            AGENTS, EMPLOYEES, AND REPRESENTATIVES (“RELEASEES”) FOR ANY INJURY, DISABILITY, DEATH,
            AND/OR PROPERTY DAMAGE THAT I MAY SUFFER AS A CONSEQUENCE OF MY PERFORMANCE OF SERVICES
            AT CLIENT’S PREMISES, WHETHER ARISING OUT OF THE ORDINARY NEGLIGENCE OF CLIENT OR
            RELEASEES OR OTHERWISE.{" "}
          </Text>
        </MediumText>

        <MediumText>
          3. Access to Information.{" "}
          <Text>
            I acknowledge that Client is engaged in the business of providing pet healthcare and
            veterinary medicine services, developing and selling software for veterinary facilities,
            and conducting clinical trials related to the development or production of veterinary
            medication or pet products, and acquiring veterinary facilities. I further acknowledge
            that, as a temporary contractor of Client, I will receive and/or have access to Client's
            confidential and proprietary information ("Confidential Information") related to those
            products and services. I further acknowledge that it is prudent and reasonable for
            Client to take certain measures to ensure the protection of such information for the
            present and future benefit of Client.
          </Text>
        </MediumText>

        <MediumText>
          4. Confidential Information.{" "}
          <Text>
            As used in this Agreement, Confidential Information refers to an item of information, or
            a compilation of information, in any form, related to Client's business that is not
            generally known to the public or to other Persons who might obtain value or competitive
            advantage from its disclosure or use. Confidential Information will not lose its
            protected status under this Agreement if it becomes generally known to the public or to
            other Persons through improper means such as the unauthorized use or disclosure of the
            information by me or another Person. Confidential Information includes, but is not
            limited to, computer software (source and object code), Client Data, Client forms,
            research and development, clients' preferences, clients' pets, client lists, patient
            information, marketing and business plans and analyses, veterinary facility acquisition
            policies, processes, procedures, strategies, and business development, forms of
            agreements and other legal documents, business procedures, systems and methods,
            financial statements and projections, operational data, techniques, technical data, know
            how, training materials, treatment protocols, innovations, unpatented inventions, trade
            secrets, associate information, and information about the business affairs of other
            Persons (including, but not limited to, clients and acquisition targets) that such third
            parties provide to Client in confidence. Confidential Information includes trade
            secrets, but an item of Confidential Information need not qualify as a trade secret to
            be protected by this Agreement.
          </Text>
        </MediumText>

        <MediumText>
          5. Protection of Confidential Information.{" "}
          <Text>
            At all times during my temporary placement and after my temporary assignment with Client
            terminates for any reason, I will not disclose any Confidential Information to any
            Person regardless of the form or manner in which I obtain such information. I will not
            use any Confidential Information for my own benefit or for the benefit of any Person and
            I will take any steps necessary to protect and maintain its confidentiality. I may
            disclose Confidential Information to the government or an attorney for the sole purpose
            of reporting a suspected violation of law or in a court filing under seal. During my
            temporary assignment with Client, I will not access Confidential Information which I
            have no legitimate need to know. I will become familiar with and abide by all Client
            policies and protocols designed to safeguard its Confidential Information.
          </Text>
        </MediumText>

        <MediumText>
          6. Assignment of Inventions.{" "}
          <Text>
            I acknowledge that Client is engaged in research, development, innovation, invention and
            production of ideas, training, protocols, products and services ("Inventions"). I assign
            to Client all of my rights, title and interest in all Inventions, whether or not
            patentable, copyrighted, published, or reduced to practice, which are made, invented,
            created or conceived by me (whether solely or jointly with others) during my temporary
            assignment with Client, or during the one (1) year period after my temporary assignment
            with Client terminates, if such Invention was made with or based upon any Confidential
            Information of Client. I agree that all such Inventions and copyrights are and will be
            the sole property of Client. At the request of Client, I will execute all documents
            necessary to evidence Client's title to or ownership of the Inventions. I will render
            reasonable assistance to Client in attempting to patent, copyright, trademark, or
            otherwise register ownership of the Inventions. If the Choice of Law Section 16
            controls, or for temporary contractor who reside or works in California, Delaware,
            Illinois, North Carolina, Kansas, Utah, Minnesota, and Washington, no provision in this
            Agreement requires the temporary contractor to assign any of his or her rights to an
            Invention for which no equipment, supplies, facility, or trade secret information of
            Client was used and which was developed entirely on the temporary contractor's own time,
            unless (a) the invention relates at the time of conception or reduction to practice of
            the Invention (i) directly to the business of the employer, or (ii) to the employer's
            actual or demonstrably anticipated research or development, or (b) the invention results
            from any work performed by the temporary contractor for the employer.
          </Text>
        </MediumText>

        <MediumText>
          7. Return of Confidential Material.{" "}
          <Text>
            Upon my termination of temporary assignment with Client for any reason, I will promptly
            return all Confidential Information to Client, including all copies, notes and extracts
            therefrom. After returning a complete copy of all such Confidential Information to
            Client, I will erase or cause to be erased all Confidential Information from any
            personal computer or electronic memory or storage device. Following my termination of
            temporary assignment, I will not take or copy any property, document, or information,
            whether electronic or otherwise, belonging to Client.
          </Text>
        </MediumText>

        <MediumText>
          8. Non-Solicitation of Associates.{" "}
          <Text>
            I understand that Client has used its resources to educate, train and develop its
            workforce. I agree that during my temporary assignment and for two (2) years after my
            temporary assignment with Client terminates for any reason, I will not directly or
            indirectly employ, solicit, request or induce (i) any associate of Client to seek
            employment with any Person other than Client or any of its affiliates or related
            entities, or (ii) any associate of Client to terminate employment and accept employment
            with another Person.
          </Text>
        </MediumText>

        <MediumText>
          9. Non-Solicitation of Clients.{" "}
          <Text>
            Because Client has spent time, money and effort to develop and retain its client base,
            during my temporary assignment and for two (2) years after my temporary assignment with
            Client terminates for any reason, I will not directly or indirectly solicit or transact
            business with, including accept business from any Client clients, except immediate
            family members, with whom I had contact or for whom I provided services during the
            eighteen (18) month period before termination of my temporary assignment with MMI. I
            will not induce or assist any Person to engage in the same acts that I am restricted
            from performing under this Section 9. If the Choice of Law provision in Section 16 does
            not control, and the temporary contractor resides in California, Montana, Colorado,
            Oklahoma, North Dakota, or Alabama (but in Alabama only as a veterinarian or other
            professional), then the post-termination restrictions set forth in this Section 9 will
            not apply to the extent such restrictions are impermissible under those states' law.
          </Text>
        </MediumText>

        <MediumText>
          10. Reasonableness.{" "}
          <Text>
            I acknowledge that the confidentiality and non-solicitation provisions of this Agreement
            are reasonable in view of the interests of Client in protecting the value of its
            business and goodwill, Confidential Information, investment in clients and client
            relationships, and investment in its workforce.
          </Text>
        </MediumText>

        <MediumText>
          11. Severability.{" "}
          <Text>
            If any provision of this Agreement is ruled to be invalid, illegal or unenforceable by a
            court, such ruling will not affect any other provision of this Agreement. If any
            restriction is ruled by a court to be unenforceable, then the court may modify the
            restriction to the minimum extent necessary to make it enforceable.
          </Text>
        </MediumText>

        <MediumText>
          12. Remedies.{" "}
          <Text>
            I acknowledge that any breach or threatened breach of this Agreement is likely to cause
            Client irreparable harm for which money damages will be difficult to calculate and will
            not be an adequate remedy. Therefore, if I breach or threaten to breach this Agreement,
            Client will be entitled to equitable remedies, including an injunction and/or specific
            performance, without proof of money damages. I waive any requirement that Client post a
            bond or other security to obtain equitable relief. Seeking equitable relief will not
            prevent Client from also obtaining money damages or other available remedies for the
            same breach. All Client's rights or remedies shall be cumulative and in addition to all
            other rights and remedies of Client under this Agreement or under Applicable Law. In the
            event of any litigation (including appeals) under or in connection with this Agreement,
            the prevailing party will be entitled to recover its attorney fees and costs.
          </Text>
        </MediumText>

        <MediumText>
          13. Modification; Waiver.{" "}
          <Text>
            This agreement may not be amended or waived except in writing. Any waiver of any breach
            of this Agreement shall not operate as a waiver of any subsequent breaches. I have no
            right to assign and will not assign any rights or obligations under this Agreement.
          </Text>
        </MediumText>

        <MediumText>
          14. Successors and Assigns; Enforcement by Client.{" "}
          <Text>
            This Agreement is binding on me and my heirs, legal representatives, and successors.
            This Agreement shall inure to the benefit of Client, including its Affiliates, officers,
            directors, agents, successors and assigns. The term "Affiliates" includes all
            subsidiaries of Client and also includes the Affiliates and licensees identified in
            Appendix A attached hereto.
          </Text>
        </MediumText>

        <MediumText>
          15. Person Defined.{" "}
          <Text>
            "Person" means any individual, group of individuals, corporation, partnership, limited
            liability company, private equity firm, or other entity of any nature, public or
            private, other than temporary contractor and Client.
          </Text>
        </MediumText>

        <MediumText>
          16. Governing Law; Jurisdiction and Venue.{" "}
          <Text>
            This Agreement is governed by the laws of Delaware, without regard to its conflict of
            law principles.
          </Text>
        </MediumText>

        <Text>
          By signing, I acknowledge that I have read and understood all terms of this Agreement,
          including the release and waiver of liability, that I am giving up substantial legal
          rights, and that I have had a reasonable opportunity to review this Agreement and have
          either sought or waived legal counsel's review of this Agreement.
        </Text>
      </div>
    </>
  );
};
