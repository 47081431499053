import React from "react";

import { NAVIGATION_HEIGHT, SEARCH_HEIGHT, SHIFT_CARD_HEIGHT } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { UngroupedShiftList } from "@RooBeta/types";

import { ShiftCard } from "./ShiftCard";
import { StyledFixedSizeList, StyledScrollArea } from "./styles";

type FlatShiftListProps = {
  shiftList: UngroupedShiftList;
  fixedHeight?: boolean;
};

export const FlatShiftList = ({ fixedHeight = true, ...props }: FlatShiftListProps) => {
  const { windowSize } = useViewportStore();
  const width = "100%";
  const height = windowSize.height - (NAVIGATION_HEIGHT + SEARCH_HEIGHT);

  return (
    <StyledScrollArea $height={height} $fixedHeight={fixedHeight}>
      {({ scrollableNodeRef, contentNodeRef }) => (
        <StyledFixedSizeList
          width={width}
          height={height}
          itemCount={props.shiftList.shifts.length}
          itemData={props}
          itemSize={SHIFT_CARD_HEIGHT}
          innerRef={contentNodeRef}
          outerRef={scrollableNodeRef}
        >
          {Row}
        </StyledFixedSizeList>
      )}
    </StyledScrollArea>
  );
};

const Row = ({
  index,
  style,
  data: {
    shiftList: { shifts, count },
  },
}: {
  index: number;
  style: React.CSSProperties;
  data: FlatShiftListProps;
}) => (
  <div style={style}>
    <ShiftCard shift={shifts[index]} count={count} variant="list" />
  </div>
);
