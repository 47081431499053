export enum PROMO_TYPE {
  ACTIVATION = "Activation",
  ACQUISITION = "Acquisition",
  LIFECYCLE = "Lifecycle",
  STREAK = "Streak",
  RAFFLE = "Sweepstakes",
  MOMENTUM = "Momentum",
}

export enum PROMO_UTM_SOURCE {
  WEB_PROMO_BANNER = "web_promo_banner",
  EMAIL = "email",
  SMS = "sms",
  BRAZE_IN_APP = "braze_in_app",
}

export enum PROMO_UTM_MEDIUM {
  WEB = "web",
}
