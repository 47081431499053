import { groupBy } from "lodash";
import moment from "moment";

import {
  GroupedShiftList,
  MyShift,
  MyShiftGroup,
  MyShiftGroups,
  Shift,
  ShiftStatus,
} from "@RooBeta/types";

import { formatCurrency } from "./formatCurrency";

const getShiftStatus = ({
  confirmedVetId,
  shiftEndDatetime,
  confirmedTechId,
}: MyShift): ShiftStatus =>
  !confirmedVetId && !confirmedTechId
    ? ("REQUESTED" as const)
    : shiftEndDatetime > Math.floor(new Date().getTime() / 1000)
    ? ("CONFIRMED" as const)
    : ("COMPLETED" as const);

const getFormattedShiftDate = ({ shiftDate }: MyShift) =>
  moment(shiftDate).format("ddd, MMM D, YYYY");

const groupShiftsByDate = (shifts: MyShift[]) => groupBy(shifts, getFormattedShiftDate);

const groupShiftsByStatus = (shifts: MyShift[]) => groupBy(shifts, getShiftStatus);

export const groupMyShifts = (shifts: MyShift[]): MyShiftGroups => {
  const shiftsByStatus = groupShiftsByStatus(shifts);

  const statuses = {
    [MyShiftGroup.Requested]: "REQUESTED",
    [MyShiftGroup.Confirmed]: "CONFIRMED",
    [MyShiftGroup.Completed]: "COMPLETED",
  };

  return Object.values(MyShiftGroup).reduce((acc, key) => {
    const shifts = shiftsByStatus[statuses[key]] || [];

    const groups = Object.entries(groupShiftsByDate(shifts)).map(([label, shifts]) => ({
      label,
      shifts: shifts.map((shift, index) => ({ ...decorateMyShift(shift), index })),
    }));

    const count = shifts.length;
    const value: GroupedShiftList = { format: "grouped", groupBy: "date", groups, count };
    return { ...acc, [key]: value };
  }, {} as MyShiftGroups);
};

/**
 * Converts a `MyShift` to a `Shift` instance.
 * @TODO: This should be moved to the API.
 */

export const decorateMyShift = (shift: MyShift): Shift => {
  const shiftAmount = shift.techShiftAmount ?? shift.vetShiftAmount;
  const shiftAmountUpdated = shift.techShiftAmountUpdated ?? shift.vetShiftAmountUpdated;

  return {
    ...shift,
    shiftGroupId: shift.shiftGroupId!,
    hospitalName: shift.hospitalName!,
    latitude: parseFloat(shift.latitude ?? "0"),
    longitude: parseFloat(shift.longitude ?? "0"),
    shiftDateFormatted: getFormattedShiftDate(shift),
    price: parseFloat(shiftAmountUpdated ?? shiftAmount),
    priceFormatted: formatCurrency(parseFloat(shiftAmountUpdated ?? shiftAmount)),
    previousPriceFormatted: shiftAmountUpdated ? formatCurrency(parseFloat(shiftAmount)) : null,
    shiftStatus: getShiftStatus(shift),
    isFavorite: false,
    isWorkedBefore: false,
    metadata: shift.metadata || [],
    bookmarkId: null,
  };
};
