import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

import { CacheKey } from "@RooBeta/types";

import { APIError, del } from "../../api/common/rooFetch";
import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";
import { ToastContext } from "../../Common/Toasts/context/ToastContext";
import { removeBookmark } from "../../constants/apiRoutes";

export const useRemoveBookmark = (onSuccess?: () => void) => {
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  return useMutation<void, APIError<{ message: string }>, { bookmarkId: number }>(
    (params) => {
      return del(removeBookmark(params.bookmarkId));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [CACHE.BOOKMARKS] });
        queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
        onSuccess?.();
        showSuccessToast({
          description: "Shift was successfully removed from share list.",
          message: "Shift removed",
        });
      },
      onError: (error) => {
        showErrorToast({ description: error?.message || "Error adding bookmark" });
      },
    }
  );
};
