import React from "react";

import { Color } from "../../styles/constants";

import { H1, H2, H3, H4, H5, H6 } from "./HeadingStyles";

export type HeadingProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children?: React.ReactNode;
  color?: Color;
  "data-testid"?: string;
} & Omit<React.HTMLAttributes<HTMLHeadingElement>, "children">;

// use named export for the component to apply typescript docgen
export const Heading: React.FC<HeadingProps> = (props) => {
  const HeadingTag = {
    1: H1,
    2: H2,
    3: H3,
    4: H4,
    5: H5,
    6: H6,
  }[props.level || 1];

  return (
    <HeadingTag color={props.color} data-testid={props["data-testid"]} {...props}>
      {props.children}
    </HeadingTag>
  );
};
