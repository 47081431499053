import isEqual from "lodash/isEqual";
import React, { ComponentProps, createContext, useCallback, useMemo, useState } from "react";

interface ModalItem {
  component: React.ReactElement;
  id?: TypedModalIds;
}

interface TypedModalIds {
  HospitalRatingModal?: number;
}

const ModalRenderer = <T,>({
  component,
  props,
}: {
  component: React.FunctionComponent<T>;
  props: ComponentProps<typeof component>;
}) => component(props);

type ModalContextValue = {
  closeModal: () => void;
  openModal: <T>(
    modalKey: React.FunctionComponent<T>,
    props: ComponentProps<typeof modalKey>,
    id?: TypedModalIds
  ) => void;
};

export const ModalContext = createContext<ModalContextValue>({
  closeModal: () => {},
  openModal: () => {},
});

export const ModalProvider = (props: { children: React.ReactElement }) => {
  const [modalList, setModalList] = useState<ModalItem[]>([]);

  const closeModal = useCallback(() => setModalList((prevModalList) => prevModalList.slice(1)), []);

  const openModal = useCallback(
    <T,>(
      modal: React.FunctionComponent<T>,
      modalProps: ComponentProps<typeof modal>,
      id?: TypedModalIds
    ) => {
      setModalList((currentModalList) => {
        if (
          id &&
          currentModalList.some((modal) => {
            return isEqual(modal.id, id);
          })
        ) {
          return currentModalList;
        }

        return [
          ...currentModalList,
          {
            component: (
              <ModalRenderer component={modal} props={modalProps} key={`modal-${modal.name}`} />
            ),
            id,
          },
        ];
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      closeModal,
      openModal,
    }),
    [closeModal, openModal]
  );

  return (
    <ModalContext.Provider value={value}>
      {modalList.length > 0 && modalList[0].component}
      {props.children}
    </ModalContext.Provider>
  );
};
