import { DatePicker, FormLabel, Link, Modal, RadioGroup, Select, Text } from "@RooUI";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { submitHireRequest } from "../../../api/hiring";
import { GetWorkedWithProvidersResponse, getWorkedWithProviders } from "../../../api/hospital";
import { showToast } from "../../../Common/Toasts/action/ToastActions";
import { RootState } from "../../../store";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";
import { getBlockedContractorListAction } from "../../actions/hospitalActions";

import { ModalContentWrapper, ModalDescriptionWrapper } from "./styles";

interface FullTimePlacementModalProps {
  hospitalId: number;
  showModal: boolean;
  isPendingStatus: boolean;
  isFromPoliciesPage?: boolean;
  onCloseModal: () => void;
  setShowFTPDuplicateWarningModalData: ({
    isShowModal,
    requestSubmittedOn,
    isVet,
  }: {
    isShowModal: boolean;
    requestSubmittedOn: Date | null;
    isVet: boolean;
  }) => void;
}

interface FullTimePlacementFormData {
  providerId?: number;
  providerName?: string;
  providerType?: "vet" | "tech";
  hasDiscussedOpportunity?: "Yes" | "No";
  intendedStartDate?: string;
}

interface Provider {
  label: string;
  value: string;
  name: string;
  vetId?: number;
  techId?: number;
}

export const FullTimePlacementModal = ({
  hospitalId,
  showModal,
  isPendingStatus,
  isFromPoliciesPage,
  onCloseModal,
  setShowFTPDuplicateWarningModalData,
}: FullTimePlacementModalProps) => {
  const dispatch = useDispatch();

  const blockedVetList = useSelector((state: RootState) => state.hospitalSettings.blockedVetList);
  const blockedTechList = useSelector((state: RootState) => state.hospitalSettings.blockedTechList);

  const [workedWithProviders, setWorkedWithProviders] = useState<GetWorkedWithProvidersResponse>();
  const [loadProvidersError, setLoadProvidersError] = useState("");

  const [vetProviders, setVetProviders] = useState<Provider[]>([]);
  const [techProviders, setTechProviders] = useState<Provider[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FullTimePlacementFormData>({
    defaultValues: {
      providerId: undefined,
      providerName: undefined,
      providerType: undefined,
      hasDiscussedOpportunity: undefined,
      intendedStartDate: undefined,
    },
  });

  const providerType = watch("providerType");
  const hasDiscussedOpportunity = watch("hasDiscussedOpportunity");

  const providerOptions = (() => {
    switch (providerType) {
      case "vet":
        return vetProviders;
      case "tech":
        return techProviders;
      default:
        return [];
    }
  })();

  useEffect(() => {
    const fetchProviders = async () => {
      if (!hospitalId || !showModal || isPendingStatus) {
        return;
      }

      try {
        setLoadProvidersError("");
        const response = await getWorkedWithProviders(hospitalId);
        setWorkedWithProviders(response);

        dispatch(getBlockedContractorListAction({ hospitalId, isVet: true, isTech: true }));
      } catch (error) {
        Sentry.captureException(new Error("Error fetching providers for full-time placement"), {
          extra: {
            hospitalId,
            error,
          },
        });
        setLoadProvidersError("Unable to load providers. Please try again later.");
      }
    };

    fetchProviders();
  }, [hospitalId, showModal, isPendingStatus, dispatch]);

  useEffect(() => {
    if (workedWithProviders && blockedVetList && blockedTechList) {
      const blockedUserIds = new Set([
        ...blockedVetList.map((vet: { userId: number }) => vet.userId),
        ...blockedTechList.map((tech: { userId: number }) => tech.userId),
      ]);

      const filteredVets = workedWithProviders.vets
        .filter((vet) => !blockedUserIds.has(vet.userId))
        .map((vet) => ({
          label: vet.fullName,
          value: String(vet.vetId),
          name: vet.fullName,
          vetId: vet.vetId,
        }));

      const filteredTechs = workedWithProviders.techs
        .filter((tech) => !blockedUserIds.has(tech.userId))
        .map((tech) => ({
          label: tech.fullName,
          value: String(tech.techId),
          name: tech.fullName,
          techId: tech.techId,
        }));

      setVetProviders(filteredVets);
      setTechProviders(filteredTechs);
    }
  }, [workedWithProviders, blockedVetList, blockedTechList]);

  const onSubmit = async ({
    providerType,
    providerId,
    providerName,
    hasDiscussedOpportunity,
    intendedStartDate,
  }: FullTimePlacementFormData) => {
    if (!providerType || !providerId || !providerName || hasDiscussedOpportunity === undefined) {
      return;
    }

    setIsSubmitting(true);
    try {
      const { success, data } = await submitHireRequest({
        hospitalId,
        providerId,
        providerName,
        providerType,
        hasDiscussedOpportunity: hasDiscussedOpportunity === "Yes",
        intendedStartDate,
      });

      handleClose();
      if (success) {
        dispatch(
          showToast({
            variant: "success",
            message: "Full-Time Placement request submitted!",
            description: "We'll reach out to you within 3 business days.",
          })
        );
      }
      if (data?.requestSubmittedOn) {
        setShowFTPDuplicateWarningModalData({
          isShowModal: true,
          requestSubmittedOn: data.requestSubmittedOn,
          isVet: providerType === "vet",
        });
      }
    } catch (error) {
      handleClose();
      dispatch(
        showToast({
          variant: "error",
          message: "Failed to submit request",
          description: "Please try again later.",
        })
      );
    } finally {
      setIsSubmitting(false);
      reset();
      EventTracker.send({
        eventName: Event.Name.FTP_MODAL_SUBMITTED,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.HOSPITAL,
        entityId: hospitalId,
      });
    }
  };

  const handleClose = () => {
    onCloseModal();
    EventTracker.send({
      eventName: Event.Name.FTP_MODAL_CLOSED,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.HOSPITAL,
      entityId: hospitalId,
    });
  };

  const isVetEmpty = providerType === "vet" && vetProviders.length === 0;
  const isTechEmpty = providerType === "tech" && techProviders.length === 0;
  const isDisabled = !providerType || isVetEmpty || isTechEmpty;

  return (
    <Modal
      showModal={showModal}
      onCloseModal={handleClose}
      $size="m"
      $tabNameArr={[
        {
          index: 1,
          name: "Match Made by Roo",
          panelContent: (
            <ModalContentWrapper>
              <ModalDescriptionWrapper>
                <Text data-testid="ftpModalDescription">
                  We're happy you've found a Roo relief pro that you'd like to hire full-time. Let
                  us know who you're interested in hiring to begin our full-time placement process
                  by submitting this form. We'll follow up with you for next steps.
                </Text>
              </ModalDescriptionWrapper>

              <div data-testid="ftpModalPolicyText">
                <FormLabel>Review our full-time placement policy</FormLabel>
                <Text>
                  {"Review our "}
                  {isFromPoliciesPage ? (
                    <Link onClick={handleClose}>policies</Link>
                  ) : (
                    <Link
                      href="/hospital/policies/full-time-placement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      policies
                    </Link>
                  )}
                  {" to learn more."}
                </Text>
              </div>

              <Controller
                name="providerType"
                control={control}
                rules={{ required: "Please select provider type" }}
                render={({ field }) => (
                  <RadioGroup
                    label="Tell us who you're looking to hire"
                    data-testid="ftpModalProviderType"
                    radios={[
                      {
                        label: "Veterinarian",
                        value: "vet",
                        type: "informational",
                        icon: "StethoscopeOutlined",
                        size: "small",
                      },
                      {
                        label: "Technician",
                        value: "tech",
                        type: "informational",
                        icon: "UrgentCare",
                        size: "small",
                      },
                    ]}
                    flex
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("providerId", undefined);
                      setValue("providerName", undefined);
                      EventTracker.send({
                        eventName: Event.Name.FTP_MODAL_CONTRACTOR_TYPE,
                        eventType: Event.Type.CLICK,
                        entityType: Event.Entity.HOSPITAL,
                        entityId: hospitalId,
                        context: { providerType: value },
                      });
                    }}
                    error={errors.providerType?.message}
                    singleColumn
                  />
                )}
              />

              <Controller
                name="providerId"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!providerType) return true;
                    return !!value || "Please select a provider";
                  },
                }}
                render={({ field }) => {
                  const placeholderText =
                    isVetEmpty || isTechEmpty ? "No providers found" : "Select an option";
                  return (
                    <Select
                      placeholder={placeholderText}
                      label="Full name"
                      options={providerOptions}
                      searchable={true}
                      value={field.value ? String(field.value) : undefined}
                      onChange={({ value, name }) => {
                        field.onChange(Number(value));
                        setValue("providerName", name);
                        EventTracker.send({
                          eventName: Event.Name.FTP_MODAL_CONTRACTOR_SELECTED,
                          eventType: Event.Type.CLICK,
                          entityType: Event.Entity.HOSPITAL,
                          entityId: hospitalId,
                          context: {
                            providerName: name,
                            [providerType === "vet" ? "vetId" : "techId"]: Number(value),
                          },
                        });
                      }}
                      disabled={isDisabled}
                      error={errors.providerId?.message ?? loadProvidersError}
                    />
                  );
                }}
              />

              <Controller
                name="hasDiscussedOpportunity"
                control={control}
                rules={{ required: "Please indicate whether you've discussed this opportunity" }}
                render={({ field }) => (
                  <RadioGroup
                    label="I've already discussed this opportunity with the vet or tech"
                    radios={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    flex
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      if (value === "No") {
                        setValue("intendedStartDate", undefined);
                      }
                      EventTracker.send({
                        eventName: Event.Name.FTP_MODAL_DISCUSS_OPPORTUNITY_TOGGLE,
                        eventType: Event.Type.CLICK,
                        entityType: Event.Entity.HOSPITAL,
                        entityId: hospitalId,
                        context: { hasDiscussedOpportunity: value },
                      });
                    }}
                    error={errors.hasDiscussedOpportunity?.message}
                  />
                )}
              />

              <Controller
                name="intendedStartDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    picker="date"
                    placeholder="Select date"
                    label="Vet or tech's intended start date (optional)"
                    style={{ width: "100%" }}
                    isShowArrows={false}
                    isAllowEmptyState
                    disablePastDates
                    value={field.value}
                    onChange={(date) => {
                      const formattedDate = date?.format("YYYY-MM-DD");
                      field.onChange(formattedDate);
                      EventTracker.send({
                        eventName: Event.Name.FTP_MODAL_INTENDED_START_DATE,
                        eventType: Event.Type.CLICK,
                        entityType: Event.Entity.HOSPITAL,
                        entityId: hospitalId,
                        context: { date: formattedDate },
                      });
                    }}
                    disabled={!hasDiscussedOpportunity || hasDiscussedOpportunity === "No"}
                  />
                )}
              />
            </ModalContentWrapper>
          ),
        },
      ]}
      defaultSelectedTabIndex={1}
      $primaryButtonProps={{
        title: "Submit",
        $buttonType: "primary",
        size: "small",
        onClick: handleSubmit(onSubmit),
        disabled: isDisabled || isSubmitting,
        loading: isSubmitting,
      }}
      $secondaryButtonProps={{
        title: "Cancel",
        $buttonType: "secondary",
        size: "small",
        onClick: handleClose,
      }}
      $centerButtons
    />
  );
};
