import styled from "styled-components";

import { grayWhite, purpleBase } from "../../styles/constants";

import { CardProps } from ".";

export const CardContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 16px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    height: 280px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const LeftCardContainer = styled.div<CardProps>`
  min-width: 290px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${(props) => props.$leftContainerBackgroundColor ?? purpleBase};
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    min-height: 84px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
  }
`;

export const RightCardContainer = styled.div`
  width: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${grayWhite};
  @media (max-width: 768px) {
    height: 100%;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 0px;
  }
`;
