import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

export const cognitoDeleteUser = async () => {
  try {
    await Auth.deleteUser();
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error deleting current cognito user" } });
  }
};
