import { create } from "zustand";

import { useShiftDetailsStore } from "@RooBeta/store";
import { isWithinBusinessDays } from "@RooBeta/utils";

interface ShiftCancellationState {
  isCancellationPolicyAcknowledged: boolean;
  setIsCancellationPolicyAcknowledged: (value: boolean) => void;
}

const useShiftCancellationStore = create<ShiftCancellationState>((set) => ({
  isCancellationPolicyAcknowledged: false,
  setIsCancellationPolicyAcknowledged: (value) => set({ isCancellationPolicyAcknowledged: value }),
}));

export const useShiftCancellation = () => {
  const { shiftDetails } = useShiftDetailsStore();

  const { isCancellationPolicyAcknowledged, setIsCancellationPolicyAcknowledged } =
    useShiftCancellationStore();

  const isShiftConfirmed = !!shiftDetails && shiftDetails.shiftStatus === "CONFIRMED";
  const isShiftSoon = !!shiftDetails && isWithinBusinessDays(shiftDetails.shiftDate, 4);

  const isCancellationPolicyAcknowledgementRequired =
    isShiftConfirmed && !isCancellationPolicyAcknowledged;

  const isCancellationRequestRequired = isShiftConfirmed && isShiftSoon;

  return {
    isShiftConfirmed,
    isShiftSoon,
    isCancellationPolicyAcknowledgementRequired,
    isCancellationRequestRequired,
    isCancellationPolicyAcknowledged,
    setIsCancellationPolicyAcknowledged,
  };
};
