import { useQuery } from "@tanstack/react-query";

import { Hospital } from "@roo-dev/roo-node-types";

import { get } from "../../api/common/rooFetch";
import { shiftBidEnablement } from "../../constants/apiRoutes";

interface BiddingSettingsParams {
  shiftId: number;
  vetId: number;
  enabled: boolean;
}

const STALE_TIME = 1000 * 60;

export const useBiddingSettings = ({ shiftId, vetId, enabled }: BiddingSettingsParams) =>
  useQuery(
    ["bidding", shiftId, vetId],
    () => get<Hospital.Setting.BiddingSetting>(shiftBidEnablement(shiftId)),
    {
      refetchOnWindowFocus: false,
      enabled: !!shiftId && !!vetId && !!enabled,
      staleTime: STALE_TIME,
    }
  );
