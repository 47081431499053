import { FormLabel, Radio, spacingM, ErrorText } from "@RooUI";
import type { CustomRadioProps } from "../../RooUI/components/Radio";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { ControlledComponentProps } from "./types";

interface RadioOption {
  label: string;
  value: string | number | boolean;
  icon?: CustomRadioProps["icon"];
  subText?: string;
}

interface ControlledRadioGroupProps<T extends FieldValues>
  extends Omit<CustomRadioProps, "name" | "checked" | "onChange" | "value" | "icon" | "subText">,
    ControlledComponentProps<T> {
  options: RadioOption[];
  type?: "classic" | "informational";
}

export const ControlledRadioGroup = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  options,
  type = "classic",
  ...props
}: ControlledRadioGroupProps<T>) => (
  <Controller<T>
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <div data-testid={`${name}RadioGroup`}>
        <FormLabel error={!!props.error}>{props.label}</FormLabel>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: spacingM,
            marginBottom: spacingM,
            marginTop: spacingM,
          }}
        >
          {options.map((option) => (
            <Radio
              key={String(option.value)}
              name={field.name}
              checked={field.value === option.value}
              onChange={() => field.onChange(option.value)}
              label={option.label}
              value={option.value}
              icon={option.icon}
              subText={option.subText}
              type={type}
            />
          ))}
        </div>
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </div>
    )}
  />
);
