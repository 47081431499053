import { useMutation } from "@tanstack/react-query";

import { APIResponse, post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey } from "@RooBeta/types";

interface UseAvailableDayRequest {
  vetId: number;
  date: string;
  isAvailable: boolean;
}

export const useAvailableDayMutation = () => {
  const {
    provider: { providerId, providerType },
  } = useRequiredAuthorizedUser();

  return useMutation({
    mutationFn: (request: UseAvailableDayRequest) =>
      post<UseAvailableDayRequest, APIResponse<{ success: boolean }>>(
        `api/${providerType.toLowerCase()}/${providerId}/setAvailableDay`,
        request
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CacheKey.AvailableDays] });
    },
  });
};
