import React from "react";

import { HStack, RooIcon } from "@RooBeta/components";
import { formatUrl } from "@RooBeta/utils";

import { HospitalWebsiteLinkStyled } from "./styles";

type HospitalWebsiteProps = {
  website: string;
};

export const HospitalWebsite = ({ website }: HospitalWebsiteProps) => {
  return (
    <HStack data-testid="hStack" $alignItems="center" $gap="xs">
      <RooIcon data-testid="rooIcon" icon="navigation" />
      <HospitalWebsiteLinkStyled href={formatUrl(website)} target="_blank" linkSize="small">
        {website}
      </HospitalWebsiteLinkStyled>
    </HStack>
  );
};
