import { useMutation } from "@tanstack/react-query";
import { updateVet } from "../../constants/apiRoutes";
import { APIError, post } from "../../api/common/rooFetch";
import { queryClient } from "@Roo/api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";
import { Milestone } from "@roo-dev/roo-node-types";
import { z } from "zod";

const updateVetRequestSchema = z.object({
  currentSituationId: z.number(),
});

const updateVetResponseSchema = z.object({
  data: z.object({
    success: z.boolean(),
  }),
});

type UpdateVetRequest = z.infer<typeof updateVetRequestSchema>;
type UpdateVetResponse = z.infer<typeof updateVetResponseSchema>;

interface UseUpdateVetOptions {
  onSuccess?: () => void;
  onError?: (error: APIError<{ message: string }>) => void;
}

export const useUpdateVet = (options?: UseUpdateVetOptions) => {
  const mutation = useMutation<UpdateVetResponse, APIError<{ message: string }>, UpdateVetRequest>({
    mutationFn: async (params) => {
      const validatedParams = updateVetRequestSchema.parse(params);
      const response = await post<UpdateVetRequest, UpdateVetResponse>(
        updateVet(),
        validatedParams
      );
      return updateVetResponseSchema.parse(response);
    },
    onSuccess: () => {
      options?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [CACHE.MILESTONE_STATUS, Milestone.UserMilestone.CURRENT_SITUATION_QUESTION],
      });
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  return {
    ...mutation,
    isLoading: mutation.isLoading,
  };
};
