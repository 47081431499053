import { Modal } from "antd";
import styled from "styled-components";

import { grayWhite, h2Headlines, purpleBase } from "../../styles/constants";
import {
  mediumMediaBreakpoint,
  smallMediaBreakpoint,
} from "../../styles/constants/mediaBreakpoints";

import { ModalProps } from ".";

export type ModalSize = "s" | "m" | "l";

export type ModalType = "browse" | "fullscreen" | "photo upload";

export const getModalWidth = (size: string | undefined) => {
  let width;
  if (size == "s") width = "345px";
  else if (size == "l") width = mediumMediaBreakpoint;
  else width = smallMediaBreakpoint;
  return width;
};

export const StyledModal = styled(Modal)<ModalProps>`
  margin: 0;
  max-width: 100%;
  top: ${(props) => `${props.$topPosition}px`};

  .ant-modal-content {
    max-width: ${(props) => `${getModalWidth(props.$size)}`};
    width: 100vw;
    padding: 0 !important;
    margin: 0 auto;
  }
  @media (max-width: ${smallMediaBreakpoint}) {
    height: 100vh;
    width: 100vw !important;
    margin: 0;
    top: 0;
    .ant-modal-content {
      max-width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  }
`;

const modalContentHeights: Record<ModalType, string> = {
  browse: "541px",
  fullscreen: "100%",
  "photo upload": "auto",
};

export const ModalContent = styled.div<
  Pick<ModalProps, "$modalType" | "$tabNameArr" | "$noPadding">
>`
  position: relative;
  width: 100%;
  height: ${(props) => `${props.$modalType ? modalContentHeights[props.$modalType] : "auto"}`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${grayWhite};
  border-radius: 20px;
  padding: ${(props) => (props.$noPadding ? "0" : props.$tabNameArr ? "24px" : "44px")};
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
    border-radius: 0;
  }
`;

export const CloseIconContainer = styled.div<
  Pick<ModalProps, "$noClose" | "$noPadding" | "$tabNameArr">
>`
  display: ${(props) => `${props.$noClose ? "none" : "flex"}`};
  justify-content: end;
  width: 100%;
  margin-bottom: -12px;
  ${({ $noPadding, $tabNameArr }) => {
    if (!$noPadding) return "padding: 0;";
    const padding = $tabNameArr ? "24px" : "44px";
    return `padding: ${padding} ${padding} 0 ${padding};`;
  }}
  z-index: 1;
`;
export const ButtonContainer = styled.div<
  Pick<
    ModalProps,
    "$primaryButtonProps" | "$secondaryButtonProps" | "$tabNameArr" | "$centerButtons"
  >
>`
  display: flex;
  justify-content: ${(props) => (props.$centerButtons ? "center" : "flex-start")};
  gap: 16px;
  padding-top: ${(props) =>
    `${props.$primaryButtonProps || props.$secondaryButtonProps ? "16px" : "0px"}`};
  width: 100%;
  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
  }
  & button {
    font-size: ${(props) => `${props.$tabNameArr ? "" : "24px"}`};
    line-height: ${(props) => `${props.$tabNameArr ? "" : "32px"}`};
    height: ${(props) => `${props.$tabNameArr ? "" : "48px"}`};
  }
`;

export const ModalHeader = styled.div`
  ${h2Headlines}
  font-weight: 600;
  color: ${purpleBase};
  margin-bottom: 12px;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
`;

export const PanelContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
