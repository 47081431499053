import * as actionTypes from "../../constants/constants";

interface CommonData {
  isFromAdmin?: boolean;
  hospitalId?: number;
  hospitalName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  website?: string;
  description?: string;
  techId?: number;
  techName?: string;
  email?: string;
  metroArea?: string;
  specialties?: string[];
  certifications?: string[];
  experience?: number;
  availability?: {
    days: string[];
    shifts: string[];
  };
  userId?: number;
  userTypeId?: number;
  registrationType?: string;
  status?: string;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

interface CommonDataAction {
  type: typeof actionTypes.FETCH_COMMON_DATA;
  payload: CommonData;
}

export function fetchCommonDataAction(data: CommonData): CommonDataAction {
  return {
    type: actionTypes.FETCH_COMMON_DATA,
    payload: data,
  };
}
