import * as userStatusTypes from "@Roo/constants/userStatusTypes";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

export const useAccountStatus = () => {
  const { isActive } = useRequiredAuthorizedUser();

  return {
    isPendingActivation: isActive === userStatusTypes.PENDING,
    isBlocked: isActive === userStatusTypes.BLOCKED,
  };
};
