export const options = {
  fields: ["formatted_address"],
  strictBounds: false,
  types: ["address"],
};

export const optionsAddressComponents = {
  fields: ["address_components"],
  strictBounds: false,
  types: ["address"],
};
