import { z } from "zod";

export const bookmarkSchema = z.object({
  hospitalName: z.string().nullable(),
  hospitalTimezone: z.string().nullable(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
  stateId: z.number().nullable(),
  userId: z.number(),
  reputation: z.number().nullable(),
  userStatus: z.number().nullable(),
  confirmedVetId: z.number().nullable(),
  shiftDate: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  shiftStartDatetime: z.number().nullable(),
  shiftCreatedOn: z.number().nullable(),
  shiftEndDatetime: z.number().nullable(),
  hospitalId: z.number(),
  shiftRequestedOn: z.number().nullable(),
  shiftType: z.string().nullable(),
  expectedLunchTime: z.string().nullable(),
  expectedNoOfAppointments: z.string().nullable(),
  surgeryTier: z.number().nullable(),
  isSurgeryExpected: z.number().nullable(),
  isFlexibleSurgery: z.number().nullable(),
  totalShiftBoostAmount: z.number().nullable(),
  totalPromoBoostAmount: z.number().nullable(),
  shiftAmount: z.number().nullable(),
  vetShiftAmount: z.number().nullable(),
  vetShiftAmountUpdated: z.number().nullable(),
  shiftGroupId: z.number().nullable(),
  additionalDoctor: z.number().nullable(),
  expectedAppointmentTypeId: z.number().nullable(),
  avgWellnessApptLen: z.number().nullable(),
  isWalkInsOrDropOffs: z.number().nullable(),
  isDEALicenseRequired: z.number().nullable(),
  additionalComments: z.string().nullable(),
  isWithin24Hours: z.number().nullable(),
  flexStartTimeRange: z.string().nullable(),
  flexEndTimeRange: z.string().nullable(),
  instantBookable: z.number(),
  isRequestWithoutSurgery: z.number().nullable(),
  requestedShiftStartTime: z.string().nullable(),
  requestedShiftEndTime: z.string().nullable(),
  requestedVetShiftAmount: z.number().nullable(),
  requestedHospitalShiftAmount: z.number().nullable(),
  shiftRequestEndTimestamp: z.number().nullable(),
  hospitalImage: z.string().nullable(),
  hasFearFreeCertification: z.number().nullable(),
  requestedOn: z.number().nullable(),
  shiftId: z.number(),
  hospitalType: z.number().nullable(),
  procedureTypeIds: z.string().nullable(),
  shiftFulfillmentProbability: z.number().nullable(),
});

export type Bookmark = z.infer<typeof bookmarkSchema>;
