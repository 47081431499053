import React from "react";

import {
  Color,
  grayLight400,
  redDark700,
  tealDark600,
  yellowDark700,
} from "../../styles/constants";
import { Button, ButtonProps } from "../Button";
import { DropDown, DropDownPropTypes } from "../DropDown";
import { Icon, IconProps } from "../Icon";

import { AlertActionArea, AlertLeftContainer, Alert as AlertStyle, AlertText } from "./AlertStyles";

export interface AlertProps {
  alertBody?: React.ReactNode;
  actionButtonProps?: ButtonProps;
  $alertStatus?: "warning" | "error" | "info";
  avatar?: string;
  dropDownItems?: DropDownPropTypes["items"];
}

const STATUS_COLORS: Record<NonNullable<AlertProps["$alertStatus"]>, Color> = {
  warning: yellowDark700,
  error: redDark700,
  info: tealDark600,
};

const ICONS: Record<NonNullable<AlertProps["$alertStatus"]>, IconProps["name"]> = {
  warning: "Error",
  error: "Error",
  info: "LightbulbOutline",
};

export const Alert: React.FC<AlertProps> = (props) => {
  const { alertBody, actionButtonProps, $alertStatus, avatar, dropDownItems } = props;
  return (
    <AlertStyle {...props}>
      <AlertLeftContainer>
        {avatar ? (
          avatar
        ) : (
          <div>
            <Icon
              name={$alertStatus ? ICONS[$alertStatus] : "Error"}
              color={$alertStatus ? STATUS_COLORS[$alertStatus] : grayLight400}
            />
          </div>
        )}
        <AlertText>{alertBody}</AlertText>
      </AlertLeftContainer>
      <AlertActionArea>
        {actionButtonProps && <Button {...actionButtonProps} $buttonType="primary" size="small" />}
        {dropDownItems && (
          <DropDown items={dropDownItems}>
            <div>
              <Icon name="MoreHoriz" />
            </div>
          </DropDown>
        )}
      </AlertActionArea>
    </AlertStyle>
  );
};
