import { Alert, SmallText } from "@RooUI";
import { Carousel, Skeleton } from "antd";
import { CarouselRef } from "antd/es/carousel";
import React, { useCallback, useRef } from "react";

import { RooIcon, VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";

import { ShiftDetailsMetadata } from "./ShiftDetailsMetadata";
import {
  CarouselContainer,
  CarouselDotsOverlay,
  CarouselImage,
  LeftArrow,
  RightArrow,
  TabContentContainer,
} from "./styles";
import { Notes } from "./Notes";

export const HospitalInfo = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const { hospitalInfo, loading, errors } = useShiftDetailsStore();

  const handlePrev = useCallback(() => {
    carouselRef.current?.prev();
  }, []);

  const handleNext = useCallback(() => {
    carouselRef.current?.next();
  }, []);

  return hospitalInfo ? (
    <div data-testid="hospitalInfo">
      <ShiftDetailsMetadata metadata={hospitalInfo.metadata} />
      <VStack $gap="m" $padding="l" $paddingTop="xs" data-testid="hospitalNotes">
        <Notes label="Amenities" content={hospitalInfo.amenitiesNotes} />
        <Notes label="More information" content={hospitalInfo.additionalComments} />
        <Notes label="Parking" content={hospitalInfo.parkingNotes} />
      </VStack>
      {hospitalInfo.images && hospitalInfo.images.length > 0 && (
        <CarouselContainer data-testid="hospitalImages">
          <Carousel
            ref={carouselRef}
            arrows
            draggable
            style={{ margin: "0 auto", overflow: "hidden" }}
            prevArrow={
              <div onClick={handlePrev}>
                <LeftArrow>
                  <RooIcon icon="chevron_left" />
                </LeftArrow>
              </div>
            }
            nextArrow={
              <div onClick={handleNext}>
                <RightArrow>
                  <RooIcon icon="chevron_right" />
                </RightArrow>
              </div>
            }
          >
            {hospitalInfo.images.map(({ id, imagePath }) => (
              <CarouselImage key={id} src={imagePath} />
            ))}
          </Carousel>
          <CarouselDotsOverlay />
        </CarouselContainer>
      )}
    </div>
  ) : loading.hospitalInfo ? (
    <TabContentContainer data-testid="hospitalInfoLoading">
      <Skeleton active />
    </TabContentContainer>
  ) : errors.hospitalInfo ? (
    <TabContentContainer data-testid="hospitalInfoError">
      <Alert $alertStatus="error" alertBody={<SmallText>Oops, something went wrong.</SmallText>} />
    </TabContentContainer>
  ) : null;
};
