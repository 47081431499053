import { useCallback } from "react";

import {
  useBlockHospitalMutation,
  useBlockedHospitalsQuery,
  useUnblockHospitalMutation,
} from "@RooBeta/hooks";

interface UseBlockedHospital {
  hospitalId?: number;
}

export const useBlockHospital = ({ hospitalId }: UseBlockedHospital) => {
  const { mutateAsync: blockHospital, isLoading: isBlocking } = useBlockHospitalMutation();
  const { mutateAsync: unblockHospital, isLoading: isUnblocking } = useUnblockHospitalMutation();
  const { data: blockedHospitals, isLoading: isFetching } = useBlockedHospitalsQuery();

  const isLoading = isFetching || isBlocking || isUnblocking;

  const isBlocked = blockedHospitals?.data.blockedHospitalList.some(
    (blocked) => blocked.hospitalId === hospitalId
  );

  const toggle = useCallback(async (): Promise<{
    action: "block" | "unblock";
    success: boolean;
  }> => {
    const action = isBlocked ? "block" : "unblock";

    if (!hospitalId) {
      return { action, success: false };
    }

    const {
      data: { success },
    } = isBlocked
      ? await unblockHospital({ hospitalId })
      : await blockHospital({ hospitalId, internalNote: " " });

    return { action, success };
  }, [hospitalId, isBlocked, blockHospital, unblockHospital]);

  return { isLoading, isBlocked, toggle };
};
