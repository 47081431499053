import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ResponseSchema } from "@roo-dev/roo-node-types/dist/utils/schemas";

import { getRatingSummary } from "../../constants/apiRoutes";
import { get } from "../../api/common/rooFetch";
import { CACHE } from "../../api/utility/constants";

interface UseRatingSummaryParams {
  userId?: number;
  userTypeId?: number;
  isFromAdmin?: boolean;
  hospitalId?: number;
  vetId?: number;
  techId?: number;
}

export interface ReportingSummary {
  comments: string;
  rating: number;
  reviewSubmittedOn: string;
  submittedOn: string;
}

export interface RatingResult {
  summary: ReportingSummary[];
  average: string;
  totalRatingCount: number;
  totalRating: number;
  reliability?: number;
}

const ReportingSummarySchema: z.ZodType<ReportingSummary> = z.object({
  comments: z.string(),
  rating: z.number(),
  reviewSubmittedOn: z.string(),
  submittedOn: z.string(),
});

const RatingResultSchema: z.ZodType<RatingResult> = z.object({
  summary: z.array(ReportingSummarySchema),
  average: z.string(),
  totalRatingCount: z.number(),
  totalRating: z.number(),
  reliability: z.number().optional(),
});

const getRatingSummaryResponseSchema = ResponseSchema(RatingResultSchema);

type GetRatingSummaryResponse = z.infer<typeof getRatingSummaryResponseSchema>;

export const getQueryKey = (
  userId?: number,
  userTypeId?: number,
  hospitalId?: number,
  vetId?: number,
  techId?: number
) => {
  return [CACHE.RATING_SUMMARY, userId, userTypeId, hospitalId, vetId, techId];
};

export const useRatingSummary = ({
  userId,
  userTypeId,
  isFromAdmin,
  hospitalId,
  vetId,
  techId,
}: UseRatingSummaryParams) =>
  useQuery(
    getQueryKey(userId, userTypeId, hospitalId, vetId, techId),
    () =>
      get<GetRatingSummaryResponse>(
        getRatingSummary(userId, userTypeId, isFromAdmin, hospitalId, vetId, techId)
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!isFromAdmin,
    }
  );
