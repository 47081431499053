import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { CustomToggleProps, Toggle } from "@RooUI";

import { ControlledComponentProps } from "./types";

interface ControlledToggleProps<T extends FieldValues>
  extends Omit<CustomToggleProps, "onChange" | "value" | "name" | "defaultValue">,
    ControlledComponentProps<T> {}

export const ControlledToggle = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...inputProps
}: ControlledToggleProps<T>) => (
  <Controller<T>
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <Toggle checked={field.value as boolean} onChange={field.onChange} {...inputProps} />
    )}
  />
);
