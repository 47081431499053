import { BoldText, Heading, Text } from "@RooUI";
import React from "react";

import { VStack } from "@RooBeta/components";

export const ShiftCancellationPolicy = () => (
  <>
    <BoldText>Please review our cancellation policies below.</BoldText>
    <VStack $gap="s">
      <Heading level={6}>First cancellation</Heading>
      <Text>You are permitted one cancellation per 30 days.</Text>
    </VStack>
    <VStack $gap="s">
      <Heading level={6}>Second cancellation</Heading>
      <Text>
        A second cancellation within a 30 day period (starting from the date of the first
        cancellation) may result in your account being restricted and from requesting new shifts
        until you speak with your Account Manager.
      </Text>
      <Text>
        Please note: you will still keep any confirmed shifts and should complete those as you
        normally would.
      </Text>
    </VStack>
    <VStack $gap="s">
      <Heading level={6}>Third cancellation</Heading>
      <Text>
        A third cancellation within this time period may result in your account being suspended for
        30 days (starting from the date of the third cancellation). During this time, you won't be
        able to request new shifts, and you'll be removed from any confirmed shifts within this
        30-day suspension period.
      </Text>
    </VStack>
  </>
);
