import * as Sentry from "@sentry/react";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

import { authenticate } from "@RooBeta/services";
import { AuthenticatedUser, AuthorizedUserContextValue, UserStatus } from "@RooBeta/types";

export const AuthorizedUserContext = createContext<AuthorizedUserContextValue>({
  status: UserStatus.Loading,
  isLoading: true,
  isLoggedIn: false,
  user: undefined,
  resetUser: () => {},
});

type AuthorizedUserProviderProps = {
  initialUser?: AuthenticatedUser | null;
  children: React.ReactNode;
};

export function AuthorizedUserProvider({ initialUser, ...props }: AuthorizedUserProviderProps) {
  const [user, setUser] = useState<AuthenticatedUser | null | undefined>(initialUser);

  const status: UserStatus =
    user === null
      ? UserStatus.LoggedOut
      : user === undefined
      ? UserStatus.Loading
      : UserStatus.LoggedIn;

  const resetUser = useCallback(() => {
    setUser(undefined);
  }, []);

  const value = useMemo(
    () => ({
      status,
      isLoading: status === UserStatus.Loading,
      isLoggedIn: status === UserStatus.LoggedIn,
      user,
      resetUser,
    }),
    [status, user, resetUser]
  );

  useEffect(() => {
    authenticate()
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(setUser)
      .catch((error) => {
        Sentry.captureException(error, {
          extra: { context: "AuthorizedUserProvider" },
        });

        setUser(null);
      });
  }, []);

  return <AuthorizedUserContext.Provider value={value} {...props} />;
}
