import * as Sentry from "@sentry/react";
import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { get } from "../common/rooFetch";

import { CACHE } from "./constants";

const getRestrictedEmailDomainsResponseSchema = z.object({
  domains: z.array(z.string()),
});

type GetRestrictedEmailDomainsResponse = z.infer<typeof getRestrictedEmailDomainsResponseSchema>;

const getRestrictedEmailDomains = async () => {
  const response = await get("api/utility/restrictedEmailDomains");
  const result = getRestrictedEmailDomainsResponseSchema.safeParse(response);

  if (result.success) {
    return result.data;
  } else {
    Sentry.captureException(result.error);
    throw result.error;
  }
};

export const useRestrictedEmailDomains = (
  useQueryOptions: Omit<
    UseQueryOptions<GetRestrictedEmailDomainsResponse>,
    "queryFn" | "queryKey"
  > = {}
) =>
  useQuery<GetRestrictedEmailDomainsResponse>({
    queryFn: getRestrictedEmailDomains,
    queryKey: [CACHE.RESTRICTED_EMAIL_DOMAINS],
    ...useQueryOptions,
  });
