import { Modal, Text } from "@RooUI";
import React from "react";
import { useHistory } from "react-router-dom";

import { useModal } from "@Roo/Common/Modals/hooks/useModal";

export const PendingModal = ({ isVet }: { isVet: boolean }) => {
  const { closeModal } = useModal();
  const history = useHistory();

  const routeToProfile = () => {
    history.push(`${isVet ? "/vet" : "/tech"}/editprofile`);
    closeModal();
  };

  return (
    <>
      <Modal
        showModal={true}
        $noClose
        defaultSelectedTabIndex={1}
        $centerButtons
        header=""
        $tabNameArr={[
          {
            index: 1,
            name: "Complete your activation call",
            panelContent: (
              <Text>
                Your activation call is scheduled. Once you complete the call you can request
                shifts. In the meantime, get a hop start by completing your profile to improve your
                chances of landing top shifts.
              </Text>
            ),
          },
        ]}
        $primaryButtonProps={{
          title: "Complete Profile",
          onClick: routeToProfile,
        }}
      />
    </>
  );
};
