import { useQuery } from "@tanstack/react-query";

import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";
import { getTechShiftDetails, getVetShiftDetails } from "../../HospitalProfilePanels/service";

interface ShiftDetailsParams {
  shiftGroupId: number;
  vetId?: number;
  techId?: number;
  isGetRatingDetails?: number;
  latitude?: number;
  longitude?: number;
}

const STALE_TIME = 1000 * 60 * 2;

export const invalidateShiftDetailsCache = async (shiftGroupId: number) =>
  queryClient.invalidateQueries({ queryKey: [CACHE.SHIFT, shiftGroupId] });

export const useShiftDetails = ({
  vetId,
  techId,
  shiftGroupId,
  latitude,
  longitude,
}: ShiftDetailsParams) =>
  useQuery(
    ["shift", shiftGroupId],
    () =>
      !!vetId
        ? getVetShiftDetails({
            shiftGroupId,
            latitude,
            longitude,
            contractorId: vetId,
            isGetRatingDetails: 1,
          })
        : getTechShiftDetails({
            shiftGroupId,
            latitude,
            longitude,
            contractorId: techId,
            isGetRatingDetails: 1,
          }),
    {
      refetchOnWindowFocus: false,
      enabled: (!!techId || !!vetId) && !!shiftGroupId,
      staleTime: STALE_TIME,
    }
  );
