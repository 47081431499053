import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey, ContractStatus, CustomQueryOptions } from "@RooBeta/types";

type ContractStatusResponse = { data: ContractStatus };

type UseContractStatusOptions = CustomQueryOptions<ContractStatusResponse> & {
  contractName: string;
  enterpriseId?: number | null;
};

export const useContractStatusQuery = ({
  contractName,
  enterpriseId,
  ...options
}: UseContractStatusOptions) => {
  const { userId } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.ContractStatus, contractName, enterpriseId, userId],
    queryFn: async () =>
      get<ContractStatusResponse>(
        `api/enterprise/contract/signature/${contractName}/${enterpriseId}/${userId}`
      ),
    enabled: !!enterpriseId,
    staleTime: 1000 * 60 * 5,
    retry: false,
    ...options,
  });
};
