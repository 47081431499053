import {
  PreferencesType,
  ProfilePanelDataType,
  ShiftBoostDataType,
  ShiftStatus,
  SnapshotType,
} from "../ProfilePanelTypes";
import {
  CHANGE_PANEL_TYPE,
  CHANGE_SHIFT_STATUS_TYPE,
  CLOSE_BOOST_WARNING_MODAL,
  CLOSE_MOBILE_ACTION_SHEET,
  CLOSE_PROFILE_PANEL,
  CLOSE_RATING_MODAL,
  GET_SHIFT_DETAILS_FOR_PANEL_FAIL,
  GET_SHIFT_DETAILS_FOR_PANEL_REQUEST,
  GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS,
  OPEN_PROFILE_PANEL,
  RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  RESET_BOOST_WARNING_DATA,
  RESET_CONTRACTOR_PROFILE_DATA,
  RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  SET_CONTRACTOR_PROFILE_DATA,
  SET_PANEL_SHIFT_DATA,
  SET_SELECTED_SHIFT_REQUEST,
  SHOW_BOOST_WARNING_MODAL,
  SHOW_MOBILE_ACTION_SHEET,
  SHOW_RATING_MODAL,
  UPDATE_UNFILLED_SHIFT_DETAILS,
} from "../constants";
import {
  getContractorBasicDetails,
  getFormattedShiftData,
  getPreferences,
  getTechCredentialsAndLicenses,
  getTechExpereinceDetails,
  getVetExperienceDetails,
} from "../service";

export interface InitialStateProps {
  isOpenNewProfilePanel: boolean;
  profilePanelData: ProfilePanelDataType;
  snapshot: SnapshotType;
  preferenceDetails: PreferencesType;
  isContractorInfoLoaded: boolean;
  selectedShiftRequest: any;
  isShowRatingModal: boolean;
  panelShiftDetails?: any;
  hasPanelShiftDetailsFetched: boolean;
  unfilledShiftDetails?: any;
  isShowActionSheet: boolean;
  isActionPerformed: boolean;
  isReloadShiftsAfterPanelClosed: boolean;
  isShowBoostWarningModal: boolean;
  isBoostShiftAfterWarning: boolean;
  shiftBoostData: ShiftBoostDataType;
}

export interface Action {
  type: string;
  payload?: any;
}

export const initialState: InitialStateProps = {
  isContractorInfoLoaded: false,
  isOpenNewProfilePanel: false,
  profilePanelData: {} as ProfilePanelDataType,
  snapshot: {
    basicInfo: {
      profileImage: null,
      fullName: "",
      yearsOfPractice: null,
      techTier: null,
      hasSurgery: false,
    },
    experienceDetails: [],
    techCredentials: {
      credentialTypes: [],
      credentialStates: [],
      licenseList: [],
    },
  },
  preferenceDetails: {
    preferences: {},
  },
  selectedShiftRequest: {},
  panelShiftDetails: {},
  hasPanelShiftDetailsFetched: false,
  unfilledShiftDetails: {},
  isShowRatingModal: false,
  isShowActionSheet: false,
  isActionPerformed: false,
  isReloadShiftsAfterPanelClosed: false,
  isShowBoostWarningModal: false,
  isBoostShiftAfterWarning: false,
  shiftBoostData: {
    boostPercent: 0,
    boostAmount: 0,
  } as ShiftBoostDataType,
};

export const profilePanelReducer = (
  state: InitialStateProps | undefined,
  action: Action
): InitialStateProps => {
  const currentState = state || initialState;

  switch (action.type) {
    case OPEN_PROFILE_PANEL:
      const formattedShiftData = action.payload?.shiftData
        ? getFormattedShiftData(
            action.payload.isVet,
            action.payload.shiftData,
            action.payload.shiftStatus === ShiftStatus.AllRequestExpired
          )
        : {};
      return {
        ...currentState,
        isOpenNewProfilePanel: true,
        profilePanelData: {
          ...action.payload,
          localShiftData: formattedShiftData,
        },
      };

    case CLOSE_PROFILE_PANEL:
      return {
        ...currentState,
        ...initialState,
      };

    case SET_CONTRACTOR_PROFILE_DATA:
      const {
        isVet,
        vet,
        vetLicences,
        vetProcedure,
        vetSpecialization,
        vetAnimalType,
        tech,
        user,
        states,
      } = action.payload;

      const contractorData = isVet ? vet[0] : tech[0];
      const contractorUserDetails = user[0];

      const basicInfo = getContractorBasicDetails(isVet, action.payload);
      const experienceDetails = isVet
        ? getVetExperienceDetails(
            {
              vet,
              vetLicences,
              vetProcedure,
              vetSpecialization,
              vetAnimalType,
            },
            states?.length ? states : []
          )
        : getTechExpereinceDetails(contractorData);

      const preferences = getPreferences(isVet, {
        ...contractorData,
        characterTypeId: contractorUserDetails.characterTypeId,
      });

      let techCredentials = {};
      if (!isVet) {
        techCredentials = getTechCredentialsAndLicenses(
          {
            ...action.payload.tech[0],
            techLicences: tech[0]?.licenses,
          },
          states?.length ? states : []
        );
      }

      return {
        ...currentState,
        isContractorInfoLoaded: true,
        snapshot: {
          basicInfo,
          experienceDetails,
          techCredentials,
        },
        preferenceDetails: {
          preferences,
        },
      };

    case RESET_CONTRACTOR_PROFILE_DATA:
      return { ...currentState, ...initialState };

    case SET_SELECTED_SHIFT_REQUEST:
      return { ...currentState, selectedShiftRequest: action.payload };

    case CHANGE_PANEL_TYPE:
      return {
        ...currentState,
        profilePanelData: {
          ...currentState.profilePanelData,
          panelType: action.payload.panelType,
          contractorId: action.payload.contractorId || currentState.profilePanelData.contractorId,
        },
      };

    case CHANGE_SHIFT_STATUS_TYPE:
      return {
        ...currentState,
        profilePanelData: {
          ...currentState.profilePanelData,
          shiftStatus: action.payload,
        },
      };

    case SHOW_RATING_MODAL:
      return { ...currentState, isShowRatingModal: true };

    case CLOSE_RATING_MODAL:
      return { ...currentState, isShowRatingModal: false };

    case SHOW_MOBILE_ACTION_SHEET:
      return { ...currentState, isShowActionSheet: true };

    case CLOSE_MOBILE_ACTION_SHEET:
      return { ...currentState, isShowActionSheet: false };

    case GET_SHIFT_DETAILS_FOR_PANEL_REQUEST:
      return {
        ...currentState,
        hasPanelShiftDetailsFetched: false,
        panelShiftDetails: {},
      };

    case GET_SHIFT_DETAILS_FOR_PANEL_FAIL:
      return {
        ...currentState,
        hasPanelShiftDetailsFetched: false,
        panelShiftDetails: {},
      };

    case GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS:
      const formattedPanelShiftDetails = getFormattedShiftData(
        action.payload.isVet,
        action.payload.shiftDetails,
        action.payload.isAllRequestExpired
      );
      return {
        ...currentState,
        panelShiftDetails: formattedPanelShiftDetails,
        hasPanelShiftDetailsFetched: true,
      };

    case SET_PANEL_SHIFT_DATA:
      return {
        ...currentState,
        unfilledShiftDetails: action.payload,
      };

    case UPDATE_UNFILLED_SHIFT_DETAILS:
      return {
        ...currentState,
        isActionPerformed: true,
        unfilledShiftDetails: {
          ...currentState.unfilledShiftDetails,
          ...action.payload,
        },
      };

    case RELOAD_SHIFTS_AFTER_PANEL_CLOSED:
      return { ...currentState, isReloadShiftsAfterPanelClosed: true };

    case RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED:
      return { ...currentState, isReloadShiftsAfterPanelClosed: false, isActionPerformed: false };

    case SHOW_BOOST_WARNING_MODAL:
      return { ...currentState, isShowBoostWarningModal: true, shiftBoostData: action.payload };

    case CLOSE_BOOST_WARNING_MODAL:
      return {
        ...currentState,
        isShowBoostWarningModal: false,
        shiftBoostData: action?.payload?.isBoostShiftAfterWarning
          ? currentState.shiftBoostData
          : ({
              boostPercent: 0,
              boostAmount: 0,
            } as ShiftBoostDataType),
        isBoostShiftAfterWarning: action?.payload?.isBoostShiftAfterWarning,
      };

    case RESET_BOOST_WARNING_DATA:
      return {
        ...currentState,
        isShowBoostWarningModal: false,
        shiftBoostData: {
          boostPercent: 0,
          boostAmount: 0,
        } as ShiftBoostDataType,
        isBoostShiftAfterWarning: false,
      };

    default:
      return currentState;
  }
};
