import { create } from "zustand";

import { ViewportSize } from "@RooBeta/types";

interface ViewportState {
  viewportSize: ViewportSize;
  windowSize: { width: number; height: number };
  setViewportSize: (size: ViewportSize) => void;
  setWindowSize: (size: { width: number; height: number }) => void;
}

export const useViewportStore = create<ViewportState>((set) => ({
  viewportSize: ViewportSize.l,
  windowSize: { width: 0, height: 0 },
  setViewportSize: (size) => set({ viewportSize: size }),
  setWindowSize: (size) => set({ windowSize: size }),
}));

export const useViewport = () => useViewportStore((state) => state.viewportSize);
