import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { useGooglePlacesService } from "./useGooglePlacesService";

type UseGooglePlacesPredictions = {
  input: string;
  debounce?: number;
  minLengthAutocomplete?: number;
  enabled?: boolean;
};

export const useGooglePlacesPredictions = ({
  input,
  debounce = 500,
  minLengthAutocomplete = 3,
  enabled = true,
}: UseGooglePlacesPredictions) => {
  const { autocompleteService } = useGooglePlacesService();

  const [predictions, setPredictions] = useState<google.maps.places.QueryAutocompletePrediction[]>(
    []
  );

  const debounced = useDebouncedCallback(async (value: string) => {
    const isDisabled = !autocompleteService || value.length < minLengthAutocomplete;

    if (isDisabled) {
      setPredictions([]);
      return;
    }

    try {
      const result = await autocompleteService.getPlacePredictions({
        input,
        componentRestrictions: { country: "US" },
      });

      setPredictions(result.predictions);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, debounce);

  useEffect(() => {
    if (enabled) {
      debounced(input);
    }
  }, [input, enabled, debounced]);

  return predictions;
};
