import { Auth } from "aws-amplify";

import { identifyPosthog } from "../Common/Wrappers/identifyPosthog";
import { STUDENT_USER_TYPE, UserType } from "../constants/UserTypeConstants";

import { rooFetch } from "./common/rooFetch";
import { User } from "./vet";

export interface StudentProfile {
  studentSchool: string;
  graduationYear: number;
}

export interface EventStudentSignUpForm {
  userTypeId: UserType;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  phoneNumber?: string;
  zipcode?: string;
  studentSchool: string;
  graduationYear: string;
  acceptedTerms: boolean;
  acceptedSmsMessages: boolean;
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
}

export interface CreateStudentResponseData {
  userId: string;
  studentId: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

export interface CreateStudentDataAccountResponse {
  success: boolean;
  error: string;
  data: CreateStudentResponseData;
  signInRequest: ReturnType<typeof Auth.signIn>;
}

export const createStudentDataAccount = async (
  student: EventStudentSignUpForm
): Promise<CreateStudentDataAccountResponse> => {
  const email = student.email.toLowerCase();
  const password = student.password;

  // First, create Cognito user
  const { userSub: cognitoId } = await Auth.signUp({
    username: email,
    password,
    attributes: { "custom:usertype": student.userTypeId.toString() },
  });

  const signInRequest = await Auth.signIn(email, password);

  // Extract only the fields needed for basic user creation
  const { password: _, acceptedTerms, acceptedSmsMessages, ...basicUserData } = student;

  // Create basic user account
  const url = `${window.RooConfig.API_URL}api/user/createAccountWithData`;
  const body = JSON.stringify({ ...basicUserData, cognitoId });
  const response = await rooFetch(url, { method: "POST", body });

  if (!response.ok) {
    throw Error("api error");
  }

  const { data } = await response.json();

  identifyPosthog({
    userId: data.data.userId,
    email,
    userType: STUDENT_USER_TYPE,
  });

  return { signInRequest, ...data };
};

export interface StudentProfileResponse {
  user: User[];
  student: StudentProfile[];
}
