import React from "react";
import { Link } from "react-router-dom";

import rooLogo from "@Roo/static/images/roo_logo_updated.svg";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

export const Logo = () => {
  const {
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  const path = providerType.toLowerCase();

  return (
    <Link to={`/${path}`}>
      <img src={rooLogo} width={112} />
    </Link>
  );
};
