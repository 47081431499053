import { BoldText, Button, SmallText, grayLight300 } from "@RooUI";
import React, { useState } from "react";

import { HStack, RooIcon, VStack } from "@RooBeta/components";
import { useRequiredAuthorizedUser, useShiftRequestMutation } from "@RooBeta/hooks";
import { useShiftDetailsStore, useToastStore } from "@RooBeta/store";

import { NDAModal } from "../../NDA/NDAModal";
import { shiftNotAvailableToasts } from "@Roo/Common/Toasts/constants";

export const UnfilledShiftCTA = () => {
  const user = useRequiredAuthorizedUser();
  const { show } = useToastStore();
  const [isNDAModalOpen, setIsNDAModalOpen] = useState(false);

  const { shiftDetails, contractStatus, setDrawer } = useShiftDetailsStore();
  const isDEALicenseSatisfied = !shiftDetails?.isDEALicenseRequired || user.hasDEA;

  const shiftRequest = useShiftRequestMutation(shiftDetails);

  const isShiftModifiable = shiftDetails?.canModifyShift && isDEALicenseSatisfied;

  return shiftDetails ? (
    <HStack $justifyContent="space-between" $padding="m">
      <VStack>
        <BoldText>{shiftDetails.priceFormatted}</BoldText>
        <SmallText color={grayLight300}>Total pay</SmallText>
      </VStack>
      <HStack $justifyContent="flex-end" $gap="s">
        {contractStatus?.enterpriseContractId && !contractStatus.signature ? (
          <>
            <Button
              $buttonType="primary"
              onClick={() => setIsNDAModalOpen(true)}
              disabled={shiftDetails.isStateLicensedRequired}
            >
              Review Agreement
            </Button>
            <NDAModal
              showModal={isNDAModalOpen}
              onCloseModal={() => setIsNDAModalOpen(false)}
              enterpriseContractId={contractStatus.enterpriseContractId}
              shiftId={shiftDetails.shiftId}
              isInstantBookable={shiftDetails.isInstantBookable}
              onSignature={async () => {
                const { success, message } = await shiftRequest.mutateAsync(undefined);
                if (!success) {
                  show({
                    variant: "error",
                    title: shiftNotAvailableToasts(message, "").toastMessage,
                    message: shiftNotAvailableToasts(message, "").toastDescription,
                    region: "panel",
                  });
                }
              }}
            />
          </>
        ) : (
          <>
            {isShiftModifiable && (
              <Button
                $buttonType="secondary"
                style={{ padding: 0, width: 40, flexShrink: 0 }}
                onClick={() => setDrawer("adjust")}
                disabled={shiftDetails.isStateLicensedRequired}
              >
                <RooIcon icon="edit" size="l" title="Edit" />
              </Button>
            )}
            <Button
              $buttonType="primary"
              onClick={async () => {
                const { success, message } = await shiftRequest.mutateAsync(undefined);
                if (!success) {
                  show({
                    variant: "error",
                    title: shiftNotAvailableToasts(message, "").toastMessage,
                    message: shiftNotAvailableToasts(message, "").toastDescription,
                    region: "panel",
                  });
                }
              }}
              disabled={!isDEALicenseSatisfied || shiftDetails.isStateLicensedRequired}
              loading={shiftRequest.isLoading}
              style={{ minWidth: 150 }}
            >
              {shiftDetails.isInstantBookable ? "Book Now" : "Request"}
            </Button>
          </>
        )}
      </HStack>
    </HStack>
  ) : null;
};
