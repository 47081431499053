import { Button, RadioGroup } from "@RooUI";
import React from "react";
import {
  QuestionInterstitialContainer,
  RadioGroupContainer,
  QuestionInterstitialButtonContainer,
} from "./styles";

export enum QuestionType {
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DROPDOWN = "dropdown",
  RATING = "rating",
}

interface Option {
  label: string;
  value: number;
}

interface QuestionInterstitialProps {
  body?: React.ReactNode;
  questionType: QuestionType;
  options?: Option[];
  label?: string;
  value: number | null;
  onChange: (value: number) => void;
  onSubmit: (value: number) => void;
}

export const QuestionInterstitial = ({
  body,
  questionType,
  options = [],
  label,
  value = null,
  onChange,
  onSubmit,
}: QuestionInterstitialProps) => {
  const renderContent = () => {
    switch (questionType) {
      case QuestionType.RADIO:
        return (
          <>
            <RadioGroupContainer>
              <RadioGroup
                label={label}
                radios={options}
                value={value}
                onChange={(val) => onChange(Number(val))}
                flex
                data-testid="questionInterstitialRadioGroup"
              />
            </RadioGroupContainer>
            <QuestionInterstitialButtonContainer>
              <Button
                onClick={() => value !== null && onSubmit(value)}
                disabled={value === null}
                data-testid="questionInterstitialSubmitButton"
              >
                Save
              </Button>
            </QuestionInterstitialButtonContainer>
          </>
        );
      default:
        return body;
    }
  };

  return <QuestionInterstitialContainer>{renderContent()}</QuestionInterstitialContainer>;
};
