import { z } from "zod";

export type FlexParams = {
  isRequestWithoutSurgery?: boolean;
  requestedStartTime?: string;
  requestedEndTime?: string;
  bidPrice?: number;
  shiftPricingChanges?: { vetShiftAmount?: number; shiftAmount?: number };
};

export const shiftAdjustmentsSchema = z
  .object({
    requestedWithoutSurgery: z.boolean().optional(),
    bidPrice: z.coerce.number().optional(),
    bidCeiling: z.number().optional(),
    requestedStartTime: z.string().optional(),
    requestedEndTime: z.string().optional(),
    rateBidMinimum: z.number().optional(),
  })
  .superRefine(({ bidPrice, rateBidMinimum, bidCeiling }, ctx) => {
    if (bidPrice && rateBidMinimum && bidCeiling) {
      if (bidPrice < rateBidMinimum * 0.7) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Value should be greater than 70% of total pay.`,
          path: ["bidPrice"],
        });
      } else if (bidPrice > bidCeiling) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please enter a value up to ${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(bidCeiling)}.`,
          path: ["bidPrice"],
        });
      }
    }
  });

export type ShiftAdjustments = z.infer<typeof shiftAdjustmentsSchema>;
