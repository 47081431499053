import qs from "qs";

import { VET_ROUTES, TECH_ROUTES } from "@RooBeta/constants";

export const getShiftDetailsPath = (shiftGroupId: string | number): string => {
  const isVet = location.pathname.includes("/vet");
  const routes = isVet ? VET_ROUTES : TECH_ROUTES;
  const baseRoute = location.pathname.includes("/schedule") ? routes.SCHEDULE : routes.SHIFTS;
  return `${baseRoute}/shifts/${shiftGroupId}`;
};

export const getReturnPath = () => {
  const isVet = location.pathname.includes("/vet");
  const routes = isVet ? VET_ROUTES : TECH_ROUTES;
  return location.pathname.includes("/schedule") ? routes.SCHEDULE : routes.SHIFTS;
};

export const extractShiftGroupId = (search: string): string | null => {
  const LEGACY_PARAM = "shiftGroupId=";
  if (!search || !search.includes(LEGACY_PARAM)) return null;

  const afterParam = search.split(LEGACY_PARAM)[1];
  const shiftGroupId = afterParam.split("&")[0];

  return shiftGroupId && !isNaN(+shiftGroupId) ? shiftGroupId : null;
};

export const extractChatPanelParams = (search: string): number | null => {
  const params = qs.parse(search.split("?")[1]);

  const openChat = params?.openChat;
  const hospitalId = params?.hospitalId;

  return openChat && hospitalId && !isNaN(+hospitalId) ? +hospitalId : null;
};
