import { useCallback, useEffect, useRef } from "react";

import { Event, EventTracker } from "@RooBeta/types";

interface UseShiftCardImpressionProps {
  isIntersecting: boolean;
  position: number;
  count: number;
  shiftId: number;
  price: number;
}

export const useShiftImpression = ({
  isIntersecting,
  position,
  count,
  shiftId,
  price,
}: UseShiftCardImpressionProps) => {
  const timeout = useRef<number | null>(null);

  const logImpression = useCallback(() => {
    const event = {
      eventName: Event.Name.VET_SHIFT_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET_SHIFT,
      entityId: shiftId,
      context: { position, page: 1, price, view: "map", isNewExperience: true },
    };

    EventTracker.send(event);

    if (position === count - 1) {
      EventTracker.send({ ...event, eventName: Event.Name.VET_VIEW_SEARCH_END_OF_RESULT });
    }

    timeout.current = null;
  }, [position, count, shiftId, price]);

  useEffect(() => {
    if (isIntersecting) {
      timeout.current = window.setTimeout(logImpression, 1000);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [isIntersecting, logImpression]);
};
