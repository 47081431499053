import { Collapse } from "antd";
import style from "styled-components";

import { blueLight100, grayLight50, spacingXS } from "../../styles/constants";

export const StyledAccordion = style(Collapse)`
    width: 100%;
    background-color: ${grayLight50};
    padding: ${spacingXS};
    border-radius: 0;
`;

export const StyledAccordionItem = style(Collapse.Panel)`
    &:not(:last-child) {
        border-bottom: 2px solid ${blueLight100};
    }
`;
