import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { BlockedHospitals, CacheKey, CustomQueryOptions } from "@RooBeta/types";

interface UseBlockedHospitalsResponse {
  data: BlockedHospitals;
}

type UseBlockedHospitalsOptions = CustomQueryOptions<UseBlockedHospitalsResponse>;

export const useBlockedHospitalsQuery = (options: UseBlockedHospitalsOptions = {}) => {
  const { userId, provider } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.BlockedHospitals, userId],
    queryFn: () =>
      get<UseBlockedHospitalsResponse>(
        provider.providerType === "VET"
          ? `api/vet/getBlockedHospitalList/${provider.providerId}`
          : `api/tech/getBlockedHospitalList/${provider.providerId}`
      ),
    staleTime: 1000 * 60 * 5,
    retry: false,
    ...options,
  });
};
