import styled from "styled-components";

export const CalendlyWrapper = styled.div<{ $tight?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $tight }) => ($tight ? "680px" : "780px")};
  max-width: 100% !important;

  @media (max-width: 768px) {
    height: 780px;
  }
`;

export const CalendlyIframe = styled.iframe<{ $tight?: boolean }>`
  width: 100% !important;
  max-height: ${({ $tight }) => ($tight ? "680px" : "780px")};
  height: 100%;
  max-width: 100% !important;

  @media (max-width: 768px) {
    max-height: 780px;
  }
`;
