import React, { useState } from "react";

import { Icon } from "../Icon";
import { BaseOptionType, Select, SelectProps } from "../Select";

import { Card, CardContainer, StyledMultiSelect } from "./MultiSelectStyles";

interface MultiSelectProps<T extends BaseOptionType> extends Omit<SelectProps<T>, "value"> {
  selectedOptions: string[];
  handleRemoveValue: (index: number) => void;
  handleNewValueAdded: (value: T["value"]) => void;
}

export const MultiSelect = <T extends BaseOptionType>({
  selectedOptions,
  handleRemoveValue,
  handleNewValueAdded,
  ...selectProps
}: MultiSelectProps<T>) => {
  const [value, setValue] = useState<T["value"]>(null);
  return (
    <StyledMultiSelect>
      <Select
        {...selectProps}
        value={value}
        onChange={(_, value) => {
          handleNewValueAdded(value);
          setValue(null);
        }}
        onSearch={(newValue) => {
          if (newValue) {
            setValue(newValue);
          }
          if (value && !newValue) {
            handleNewValueAdded(value);
            setValue(null);
          }
        }}
      />
      {!!selectedOptions.length && (
        <CardContainer>
          {selectedOptions.map((option, index) => (
            <Card key={option}>
              {option}
              <Icon
                data-testid={option + index}
                name="Close"
                onClick={() => handleRemoveValue(index)}
              />
            </Card>
          ))}
        </CardContainer>
      )}
    </StyledMultiSelect>
  );
};
