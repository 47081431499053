import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { AntInputProps, Input } from "@RooUI";

import { ControlledComponentProps } from "./types";

interface ControlledPhoneInputProps<T extends FieldValues>
  extends Omit<AntInputProps, "onChange" | "value" | "name" | "defaultValue">,
    ControlledComponentProps<T> {}

export const ControlledPhoneInput = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...inputProps
}: ControlledPhoneInputProps<T>) => {
  const mobileMask = (value: string) => {
    if (!value) {
      return "";
    }
    // Remove non-numeric characters
    const numbersOnly = value.toString().replace(/\D/g, "");

    // Format the number
    let formattedValue = "";
    for (let i = 0; i < numbersOnly.length; i++) {
      if (i === 0) {
        formattedValue += "(" + numbersOnly[i];
      } else if (i === 3) {
        formattedValue += ") " + numbersOnly[i];
      } else if (i === 6) {
        formattedValue += "-" + numbersOnly[i];
      } else {
        formattedValue += numbersOnly[i];
      }
    }
    return formattedValue;
  };
  return (
    <Controller<T>
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          name={field.name}
          inputMode="numeric"
          maxLength={14} // Maximum length of formatted phone number
          value={mobileMask(field.value)}
          onChange={(e) => {
            // Extract only digits from the input value
            const rawPhoneNumber = e.target.value.replace(/\D/g, "");
            field.onChange(rawPhoneNumber);
          }}
          {...inputProps}
        />
      )}
    />
  );
};
