import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { CustomQueryOptions } from "../../Common/hookUtils";
import { rooResponseSchema } from "../common/response";
import { post } from "../common/rooFetch";
import { CACHE } from "../utility/constants";

export enum CalendlyStatus {
  NotScheduled = "NotScheduled",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  Completed = "Completed",
  NoShow = "NoShow",
}

export const userInfoSchema = z.object({
  userId: z.number(),
  userTypeId: z.number(),
  emailId: z.string(),
  vetId: z.number().nullish(),
  techId: z.number().nullish(),
  latitude: z.coerce.number().nullish(),
  longitude: z.coerce.number().nullish(),
  isActive: z.number().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  zipcode: z.string().nullish(),
  stateId: z.number().nullish(),
  metroAreaId: z.number().nullish(),
  phoneNumber: z.string().nullish(),
  isRegistrationComplete: z.number().nullish(),
  isManualApprovalPending: z.number().nullish(),
  icAgreementAcceptedOn: z.string().nullish(),
  referralCode: z.string().nullish(),
  termsAccepted: z.number().nullish(),
  termsAcceptedOn: z.string().nullish(),
  calendlyCallStartDatetime: z.string().nullish(),
  calendlyStatus: z.nativeEnum(CalendlyStatus).nullish(),
  yearsOfPractice: z.number().nullish(),
  reasonForRegistering: z.string().nullish(),
  hasDEA: z.coerce.boolean().nullish(),
  licenses: z
    .array(
      z.object({
        id: z.number().nullish(),
        licenceId: z.number().or(z.string()).nullish(),
        licenceNumber: z.string().nullish(),
        expirationMonth: z.string().or(z.number()).nullish(),
        expirationYear: z.string().or(z.number()).nullish(),
      })
    )
    .optional(),
  utmSource: z.string().nullish(),
});

export type UserInfo = z.infer<typeof userInfoSchema>;

/**
 * GetUserInfoResponse
 */

export const getUserInfoResponseSchema = rooResponseSchema.extend({
  data: z.object({
    success: z.boolean(),
    results: z.array(userInfoSchema),
  }),
});

export type GetUserInfoResponse = z.infer<typeof getUserInfoResponseSchema>;

/**
 * getUserInfo
 */

export type GetUserInfoRequest = {
  userTypeId: number;
  email: string;
  hospitalId?: number;
};

export const getUserInfo = async (request: GetUserInfoRequest) => {
  const response = await post<GetUserInfoRequest, GetUserInfoResponse>(
    "api/user/getUserInfo",
    request
  );

  const result = getUserInfoResponseSchema.safeParse(response);

  if (result.success) {
    return result.data;
  } else {
    Sentry.captureException(result.error);
    return response;
  }
};

/**
 * useGetUserInfo
 */

type UseGetUserInfoOptions = CustomQueryOptions<GetUserInfoResponse> & {
  userTypeId: number;
  email: string;
  hospitalId?: number;
};

export const useGetUserInfo = ({
  userTypeId,
  email,
  hospitalId,
  enabled = true,
  ...options
}: UseGetUserInfoOptions) =>
  useQuery<GetUserInfoResponse>({
    queryFn: () => getUserInfo({ userTypeId, email, hospitalId }),
    queryKey: [CACHE.GET_USER_INFO, userTypeId, email, hospitalId],
    enabled: enabled && !!userTypeId && !!email,
    ...options,
  });
