import { Button } from "@RooUI";
import React from "react";

import { VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";
import { getTimeBefore } from "@RooBeta/utils";

import { Highlight } from "../styles";

export const RequestedShiftCTA = () => {
  const { shiftDetails, setDrawer } = useShiftDetailsStore();

  return shiftDetails ? (
    <VStack $alignItems="center" $gap="m" $padding="m">
      {shiftDetails.shiftRequestEndTimestamp && (
        <div>
          The hospital has{" "}
          <Highlight>{getTimeBefore(shiftDetails.shiftRequestEndTimestamp)}</Highlight> to confirm.
        </div>
      )}
      <Button
        $buttonType="danger-secondary"
        onClick={() => setDrawer("cancel")}
        style={{ width: "100%" }}
      >
        Request Shift Cancellation
      </Button>
    </VStack>
  ) : null;
};
