import { Input, purpleLight50, spacingS, spacingXS } from "@RooUI";
import { AutoComplete as AntdAutoComplete, AutoCompleteProps as AntdAutoCompleteProps } from "antd";
import React from "react";
import { styled } from "styled-components";

import { HStack, VStack } from "./Flex";

type AutoCompleteProps<T> = Omit<AntdAutoCompleteProps<T>, "placeholder"> & {
  placeholder?: string;
  selectedValues?: { key: string; value: React.ReactNode }[];
};

export const AutoComplete = <T,>({
  placeholder,
  disabled,
  selectedValues,
  ...props
}: AutoCompleteProps<T>) => (
  <>
    <StyledAutoComplete {...props}>
      <Input placeholder={placeholder} disabled={disabled} />
    </StyledAutoComplete>
    {selectedValues && (
      <VStack $gap="s">
        {selectedValues.map(({ key, value }) => (
          <SelectedValue key={key}>{value}</SelectedValue>
        ))}
      </VStack>
    )}
  </>
);

const StyledAutoComplete = styled(AntdAutoComplete)`
  height: 48px;
`;

const SelectedValue = styled(HStack)`
  justify-content: space-between;
  background-color: ${purpleLight50};
  border-radius: ${spacingXS};
  padding: ${spacingXS} ${spacingS};
  min-width: 0;

  > *:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
  }

  > *:last-child {
    flex-shrink: 0;
    margin-left: ${spacingXS};
  }
`;
