import { useMutation } from "@tanstack/react-query";

import { post, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { CacheKey } from "@RooBeta/types";

interface UseFavoriteHospitalsResponse {
  data: { success: boolean };
}

type UseFavoriteHospitalsRequest =
  | {
      favorites: string;
      vetId: number;
    }
  | {
      favorites: string;
      techId: number;
    };

export const useFavoriteHospitalsMutation = () => {
  const { userId, provider } = useRequiredAuthorizedUser();

  return useMutation({
    mutationFn: async (params: Pick<UseFavoriteHospitalsRequest, "favorites">) =>
      post<UseFavoriteHospitalsRequest, UseFavoriteHospitalsResponse>(
        provider.providerType === "VET" ? `api/vet/updateFavorites` : `api/tech/updateFavorites`,
        provider.providerType === "VET"
          ? { ...params, vetId: provider.providerId }
          : { ...params, techId: provider.providerId }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.Favorites, userId] });
    },
  });
};
