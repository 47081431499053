import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useSearchFilterStore, useSearchShiftsStore } from "@RooBeta/store";
import { SearchShifts } from "@RooBeta/types";

export const useSearchRequestBody = (): SearchShifts => {
  const { provider } = useRequiredAuthorizedUser();
  const {
    mapLocation: { boundingBox, zoom },
    userLocation,
    geoLocation,
    rank,
    dateRange,
  } = useSearchShiftsStore();

  const { filters } = useSearchFilterStore();

  return {
    ...provider,
    currentLocation: {
      ...(geoLocation || userLocation),
      description: userLocation.description ?? "Unknown",
    },
    boundingBox,
    zoom,
    dateRange,
    rank,
    filters,
  };
};
