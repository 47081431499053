import { useCallback, useEffect, useRef } from "react";

import { Event, EventTracker } from "@RooBeta/types";

interface UseReliefWorkInterstitialImpressionProps {
  isIntersecting: boolean;
  vetId?: number;
}

export const useReliefWorkInterstitialImpression = ({
  isIntersecting,
  vetId,
}: UseReliefWorkInterstitialImpressionProps) => {
  const timeout = useRef<number | null>(null);

  const logImpression = useCallback(() => {
    const event = {
      eventName: Event.Name.VET_VIEW_CURRENT_SITUATION,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId,
    };

    EventTracker.send(event);
    timeout.current = null;
  }, [vetId]);

  useEffect(() => {
    if (isIntersecting) {
      timeout.current = window.setTimeout(logImpression, 1000);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [isIntersecting, logImpression]);
};
