import { COOKIE_STORAGE_TIME, STORAGE_KEYS } from "@RooBeta/constants";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import _refiner from "refiner-js";
import { useRequiredAuthorizedUser } from "./useAuthorizedUser";

export const useNewExperienceAnnouncement = () => {
  const { isSuperUser } = useRequiredAuthorizedUser();
  const [cookies, setCookie] = useCookies([
    STORAGE_KEYS.HAS_SEEN_NEW_EXPERIENCE,
    STORAGE_KEYS.HAS_RATED_NEW_DESIGN,
    STORAGE_KEYS.NEW_DESIGN_VISIT_COUNT,
  ]);
  const [showNewExperienceModal, setShowNewExperienceModal] = useState(
    !cookies[STORAGE_KEYS.HAS_SEEN_NEW_EXPERIENCE]
  );

  useEffect(() => {
    if (!cookies[STORAGE_KEYS.HAS_RATED_NEW_DESIGN] && !showNewExperienceModal && !isSuperUser) {
      const currentCount = +(cookies[STORAGE_KEYS.NEW_DESIGN_VISIT_COUNT] || 0);
      const nextCount = currentCount + 1;

      setCookie(STORAGE_KEYS.NEW_DESIGN_VISIT_COUNT, nextCount, {
        path: "/",
        maxAge: COOKIE_STORAGE_TIME,
      });

      if (nextCount > 2) {
        _refiner("showForm", "1293a270-b998-11ef-b03c-d98df68e44f1");
        setCookie(STORAGE_KEYS.HAS_RATED_NEW_DESIGN, true, {
          path: "/",
          maxAge: COOKIE_STORAGE_TIME,
        });
      }
    }
  }, [cookies, setCookie, showNewExperienceModal, isSuperUser]);

  return {
    showNewExperienceModal: showNewExperienceModal && !isSuperUser,
    closeNewExperienceModal: () => {
      setShowNewExperienceModal(false);
      setCookie(STORAGE_KEYS.HAS_SEEN_NEW_EXPERIENCE, true, {
        path: "/",
        maxAge: COOKIE_STORAGE_TIME,
      });
    },
  };
};
