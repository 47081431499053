import { useQuery } from "@tanstack/react-query";

import { POST } from "@roo-dev/roo-node-types";
import { post } from "@RooBeta/api";
import { CacheKey, CustomQueryOptions, FlexParams, ShiftDetails } from "@RooBeta/types";
import { isFlexParamsModified } from "@RooBeta/utils";

type RecalculateShiftPricingRequest = POST.ShiftPricing.RecalculateShiftPricingRequest;

type RecalculateShiftPricingResponse = POST.ShiftPricing.ShiftPricingResponse;

type UseRecalculateShiftPricingOptions = CustomQueryOptions<RecalculateShiftPricingResponse> &
  Omit<RecalculateShiftPricingRequest, "shiftId"> & {
    shiftDetails?: ShiftDetails;
    flexParams: Pick<
      FlexParams,
      "requestedStartTime" | "requestedEndTime" | "isRequestWithoutSurgery"
    >;
  };

export const useRecalculateShiftPricingQuery = ({
  shiftDetails,
  flexParams: originalFlexParams,
  ...options
}: UseRecalculateShiftPricingOptions) => {
  const { shiftId, startTime = "", endTime = "", isFlexibleTiming = false } = shiftDetails || {};
  const requestedStartTime = isFlexibleTiming ? originalFlexParams.requestedStartTime : undefined;
  const requestedEndTime = isFlexibleTiming ? originalFlexParams.requestedEndTime : undefined;
  const isRequestWithoutSurgery = originalFlexParams.isRequestWithoutSurgery;
  const flexParams = { requestedStartTime, requestedEndTime, isRequestWithoutSurgery };

  return useQuery({
    queryKey: [CacheKey.RecalculateShiftPricing, flexParams],
    queryFn: () =>
      post<RecalculateShiftPricingRequest, RecalculateShiftPricingResponse>(
        "api/pricing/recalculateShiftPricing",
        { shiftId: shiftId!, ...flexParams }
      ),
    enabled: !!shiftId && isFlexParamsModified({ startTime, endTime, ...flexParams }),
    retry: false,
    ...options,
  });
};
