import { grayBackground, purpleBase } from "@RooUI";
import React, { CSSProperties, HTMLAttributes } from "react";
import styled, { keyframes } from "styled-components";

import { RooIconSize, rooIconSizes } from "@RooBeta/types";

type LoaderIconProps = HTMLAttributes<SVGElement> & {
  speed?: number;
  strokeWidth?: number;
  primaryColor?: CSSProperties["color"];
  secondaryColor?: CSSProperties["color"];
  size?: RooIconSize | number;
};

export const LoaderIcon = ({
  speed = 100,
  strokeWidth = 6,
  primaryColor = purpleBase,
  secondaryColor = grayBackground,
  size = "l",
  ...props
}: LoaderIconProps = {}) => (
  <svg
    fill="none"
    {...props}
    viewBox="0 0 66 66"
    width={typeof size === "number" ? size : rooIconSizes[size]}
    height={typeof size === "number" ? size : rooIconSizes[size]}
  >
    <circle cx="33" cy="33" fill="none" r="28" stroke={secondaryColor} strokeWidth={strokeWidth} />
    <AnimatedCircle
      $speed={speed}
      cx="33"
      cy="33"
      fill="none"
      r="28"
      stroke={primaryColor}
      strokeDasharray="1, 174"
      strokeDashoffset="306"
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

const spin = keyframes`
  0% {
    stroke-dashoffset: 306;
  }
  50% {
    stroke-dasharray: 40, 134;
  }
  100% {
    stroke-dasharray: 1, 174;
    stroke-dashoffset: 132;
  }
`;

const AnimatedCircle = styled.circle<{ $speed: number }>`
  animation: ${spin} ${({ $speed }) => 140 / $speed}s linear infinite;
`;
