import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { get } from "@RooBeta/api";
import { CacheKey, InitialSearchRequest, initialSearchRequestSchema } from "@RooBeta/types";

const searchShifts = async (request?: string | null) => {
  const data = await get<InitialSearchRequest>(`api/shiftSearch/initial${request ? request : ""}`);
  const parsedData = initialSearchRequestSchema.parse(data);

  return parsedData;
};

export const useSearchRequestQuery = () => {
  const { search } = useLocation();

  return useQuery({
    queryKey: [CacheKey.InitialSearchRequest],
    queryFn: () => searchShifts(search.includes("sharedSearch") ? search : null),
    refetchOnWindowFocus: false,
    retry: false,
  });
};
