import { MediumText } from "@RooUI";
import { Skeleton } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import { HStack, RooIcon, VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";
import { getReturnPath } from "@RooBeta/utils";

import { HospitalAddress } from "./HospitalAddress";
import { HospitalRating } from "./HospitalRating";
import { HospitalWebsite } from "./HospitalWebsite";
import { useFavoriteHospital } from "./useFavoriteHospital";

export const ShiftDetailsHeader = () => {
  const { hospitalId, hospitalInfo, hospitalRatings, loading, reset } = useShiftDetailsStore();
  const history = useHistory();

  const favoriteHospital = useFavoriteHospital({ hospitalId });
  const { hospitalAddress, hospitalWebsite } = hospitalInfo || {};

  const handleClose = () => {
    reset();
    history.push(getReturnPath());
  };

  return (
    <VStack $gap="s" $padding="l">
      <HStack $justifyContent="space-between" $gap="s">
        <MediumText>{hospitalInfo?.hospitalName}</MediumText>
        <HStack $gap="l">
          <RooIcon
            icon={favoriteHospital.isFavorited ? "favorite_filled" : "favorite"}
            size="xl"
            onClick={favoriteHospital.toggle}
            style={{ cursor: "pointer" }}
          />
          <RooIcon icon="clear" size="xl" onClick={handleClose} style={{ cursor: "pointer" }} />
        </HStack>
      </HStack>
      {hospitalRatings ? (
        <HospitalRating rating={hospitalRatings} />
      ) : loading.hospitalRatings ? (
        <Skeleton.Button active size="small" block />
      ) : null}
      {hospitalInfo ? (
        <>
          {hospitalAddress && <HospitalAddress address={hospitalAddress} />}
          {hospitalWebsite && <HospitalWebsite website={hospitalWebsite} />}
        </>
      ) : loading.hospitalInfo ? (
        <>
          <Skeleton.Button active size="small" block />
          <Skeleton.Button active size="small" block />
        </>
      ) : null}
    </VStack>
  );
};
