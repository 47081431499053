import { FlexLayout, SectionCard, spacingL } from "@RooUI";
import React from "react";

import { NotificationCategory as NotificationCategoryType } from "../../../constants/notificationConstants";

import { NotificationCategory } from "./NotificationCategory";
import { ShiftNotificationPreferencesNew } from "./ShiftNotificationPreferences";

interface VetNotificationsProps {
  notificationCategories: NotificationCategoryType[];
  shiftPreferencesId: number;
  frequencyTypeCode: number;
}

export const VetOrTechNotifications = ({
  notificationCategories,
  shiftPreferencesId,
  frequencyTypeCode,
}: VetNotificationsProps) => {
  const categories = notificationCategories.map((category) => (
    <NotificationCategory key={category.title} notificationCategory={category} />
  ));
  return (
    <FlexLayout gap="xxl" style={{ flexDirection: "column" }} data-testid="vetOrTechNotifications">
      <ShiftNotificationPreferencesNew
        shiftPreferencesId={shiftPreferencesId}
        frequencyTypeCode={frequencyTypeCode}
      />
      <SectionCard
        headerText="ALL NOTIFICATIONS"
        headerIconName="NotificationsNone"
        data-testid="allNotificationsSectionCard"
      >
        <div style={{ padding: spacingL, width: "100%" }}>{categories}</div>
      </SectionCard>
    </FlexLayout>
  );
};
