import {
  BodyText,
  BoldBodyText,
  Link,
  SmallBodyText,
  blueLight400,
  blueLightest,
  grayBackground,
  grayLight100,
  grayLight300,
  grayLight50,
  grayWhite,
  greenBase,
  greenLightest,
  h6Headlines,
  inputBorderRadius,
  purpleBackground,
  purpleBase,
  spacingL,
  spacingS,
  spacingXS,
  spacingXXL,
  yellowDark600,
  yellowLightest,
  spacingM,
  spacingXL,
} from "@RooUI";
import { Flex } from "antd";
import { FixedSizeList, VariableSizeList } from "react-window";
import styled from "styled-components";

import { ScrollArea, VStack } from "@RooBeta/components";
import {
  NAVIGATION_HEIGHT,
  SEARCH_HEIGHT,
  SHIFT_CARD_HEIGHT,
  STICKY_HEADER_HEIGHT,
} from "@RooBeta/constants";
import { ShiftStatus } from "@RooBeta/types";

import { ShiftCardVariant } from "./useShiftMetadata";

export const QuestionInterstitialContainer = styled.div`
  background: ${purpleBackground};
  padding: ${spacingXXL} 0 ${spacingM} ${spacingL};
  position: relative;
  border-bottom: 1px solid ${grayLight100};
  z-index: 2;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: ${spacingM};
    padding: ${spacingL} ${spacingL} ${spacingXL};
    background: ${purpleBackground};
  }
`;

export const QuestionInterstitialButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: ${spacingXXL};
  transform: translateY(-50%);

  @media (max-width: 767px) {
    position: static;
    transform: none;
    display: flex;
    justify-content: flex-end;
  }
`;

export const RadioGroupContainer = styled.div`
  label {
    margin-bottom: ${spacingM};
  }
`;

export const ShareShiftButtonContainer = styled.div<{ $bookmarked: boolean }>`
  display: ${({ $bookmarked }) => ($bookmarked ? "inline" : "none")};
  position: absolute;
  top: ${spacingS};
  right: ${spacingXXL};
`;

export const StyledScrollArea = styled(ScrollArea)<{ $height: number; $fixedHeight: boolean }>`
  width: 100%;
  height: ${({ $height, $fixedHeight }) => ($fixedHeight ? `${$height}px` : "auto")};
`;

export const EmptyListContainer = styled(VStack)`
  justify-content: center;
  align-items: center;
  height: calc(100dvh - ${NAVIGATION_HEIGHT + SEARCH_HEIGHT}px);

  @supports not (height: 100dvh) {
    height: calc(100vh - ${NAVIGATION_HEIGHT + SEARCH_HEIGHT}px);
  }
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: ${STICKY_HEADER_HEIGHT}px;
  box-sizing: border-box;
  background-color: ${grayBackground};
  padding: 0 ${spacingXXL};
`;

export const Card = styled.div<{ $variant: ShiftCardVariant }>`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  gap: ${spacingL};
  min-width: 0;
  height: ${({ $variant }) => ($variant === "drawer" ? "auto" : `${SHIFT_CARD_HEIGHT}px`)};
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${grayWhite};
  border-bottom: ${({ $variant }) =>
    $variant === "carousel" ? "none" : `1px solid ${grayBackground}`};
  border-radius: ${({ $variant }) => ($variant === "carousel" ? inputBorderRadius : "0")};
  padding: ${({ $variant }) => ($variant === "drawer" ? spacingXXL : `0 ${spacingXXL}`)};
  cursor: pointer;
  box-shadow: ${({ $variant }) =>
    $variant === "carousel"
      ? "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
      : "none"};

  &:hover {
    background-color: #f9f9f9;
  }
  &:hover ${ShareShiftButtonContainer} {
    display: inline;
    position: absolute;
    right: ${spacingXXL};
  }
`;

export const CardDateTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90px;
  overflow: hidden;
  border-right: 1px solid ${grayBackground};
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  flex: 1;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  gap: ${spacingL};
  min-width: 0;
`;

export const CardLogo = styled.div`
  position: relative;
`;

export const CardLogoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${purpleBase};
  background-color: ${grayWhite};
`;

export const CardMetadata = styled.div`
  flex: 1;
  min-width: 0;
`;

export const CardPricing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${spacingXS};
  min-width: 60px;
  overflow: hidden;
`;

export const CardChevron = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * LEGACY BELOW
 */

export const DateText = styled.div`
  ${SmallBodyText};
  color: ${purpleBase};
  white-space: nowrap;
`;

export const TimeText = styled.div`
  ${BodyText};
`;

export const HospitalImage = styled.img`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  flex-shrink: 0;
  align-self: start;
  border-radius: 50%;
`;

export const DefaultHospitalIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  align-self: start;
  background-color: ${grayLight50};
  border: 1px solid ${grayLight100};
  border-radius: 50%;
`;

export const HospitalName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${h6Headlines};
  line-height: 20px;
  color: ${purpleBase};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${spacingXS};
`;

export const ShiftMetadata = styled.div`
  ${SmallBodyText};
  align-items: center;
  gap: ${spacingS};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Price = styled.div`
  ${BoldBodyText};
`;

export const CrossedOutPrice = styled.div`
  ${SmallBodyText};
  text-decoration-line: line-through;
  color: ${grayLight300};
`;

export const Tags = styled(Flex)`
  display: flex;
  flex-direction: row;
  gap: ${spacingS};
  margin-top: ${spacingS};
`;

export const Tag = styled(Flex)`
  ${SmallBodyText};
  padding: 1px 4px;
  align-items: center;
  gap: 3px;
  border: 1px solid #dbdffa;
  border-radius: 4px;
  white-space: nowrap;
`;

export const StatusTag = styled(Flex)<{ $status: ShiftStatus | "VIEWING" }>`
  ${SmallBodyText};
  padding: 1px 4px;
  color: ${({ $status }) =>
    `${
      $status === "CONFIRMED" ? greenBase : $status === "VIEWING" ? blueLight400 : yellowDark600
    }`};
  background-color: ${({ $status }) =>
    `${
      $status === "CONFIRMED"
        ? greenLightest
        : $status === "VIEWING"
        ? blueLightest
        : yellowLightest
    }`};
  border-radius: 4px;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const StyledFixedSizeList = styled(FixedSizeList)`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledVariableSizeList = styled(VariableSizeList)`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShareLink = styled(Link)<{ $bookmarkId: number | null }>`
  align-self: flex-start;
  margin-top: ${spacingS};
`;
