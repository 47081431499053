import { Button, Container, Heading } from "@RooUI";
import React from "react";

import { HeaderContainer, SuccessContainer } from "../styles";

export const EventSignupSuccessContainer = ({
  children,
  onClickButton,
}: {
  children: React.ReactNode;
  onClickButton?: () => void;
}) => (
  <>
    <HeaderContainer>
      <Heading level={1}>Roo-Hoo!</Heading>
    </HeaderContainer>
    <Container>
      <SuccessContainer>
        {children}
        <Button
          title="Take Me Back"
          $buttonType="primary"
          onClick={onClickButton}
          testId="takeMeBackBtn"
        />
      </SuccessContainer>
    </Container>
  </>
);
