import React from "react";

import { ShiftDetails } from "@RooBeta/types";

import { NDAWarning } from "./NDAWarning";
import { useShiftCTAToasts } from "./useShiftCTAToasts";
interface WarningSectionProps {
  shiftDetails: ShiftDetails;
  isDEALicenseSatisfied?: boolean | null;
  isNDASatisfied?: string | true | null;
}

export const WarningSection = ({
  shiftDetails,
  isDEALicenseSatisfied,
  isNDASatisfied,
}: WarningSectionProps) => {
  useShiftCTAToasts({
    needsDEALicense: !isDEALicenseSatisfied,
    isStateLicensedRequired: shiftDetails?.isStateLicensedRequired,
    hospitalStateCode: shiftDetails?.hospitalStateCode,
    shiftGroupId: shiftDetails?.shiftGroupId,
  });

  return <>{!isNDASatisfied && <NDAWarning shiftId={shiftDetails.shiftId} />}</>;
};
