import { find } from "lodash";

import { getConvertedTimeZone } from "../../Common/dateUtils";
import * as C from "../../constants/calendar";

const initialState = {
  shiftListFullData: [],
  shiftListTrimmedData: [],
  requestShiftDetails: [],
  shiftData: [],
  isShiftRequest: false,
  isShowShiftNotAvailableModal: false,
  requestedHospOfShiftNotAvailable: "",
  shiftRequestResponseMessage: "",
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  if (
    action.type === C.GET_MY_SHIFTS_SUCCESS ||
    action.type === C.PASS_ALL_SHIFTS_SEARCH_TO_CALENDAR
  ) {
    let oShiftsDB = action.payload;
    const vetId = parseInt(localStorage.getItem("vetId"));
    const fullShiftListData = [];
    const trimmedShiftListData = [];
    let favorites;
    let startTime;
    let endTime;
    let oEventsDB;
    if (action.eventListForVet && action.eventListForVet.length) {
      oEventsDB = action.eventListForVet;
      oEventsDB.map((event) => {
        const eventData = {
          ...event,
          isEvent: true,
          end: event.event_unix_date * 1000 + new Date().getTimezoneOffset(),
          endTime: JSON.parse(event.event_end_time).label,
          hospitalTimezone: event.hospital_timezone,
          shiftTime:
            JSON.parse(event.event_start_time).label +
            " -" +
            JSON.parse(event.event_end_time).label,
          start: event.event_unix_date * 1000 + new Date().getTimezoneOffset(),
          startTime: JSON.parse(event.event_start_time).label,
          title: event.event_title,
        };

        trimmedShiftListData.push(eventData);
      });
    }
    if (action.payload.searchShiftResult) {
      oShiftsDB = action.payload.searchShiftResult;
      favorites = action.payload.favorites;

      for (const shift in oShiftsDB) {
        fullShiftListData.push(oShiftsDB[shift]);
      }
    } else {
      for (const shift in oShiftsDB) {
        fullShiftListData.push(oShiftsDB[shift][0]);
      }
    }

    for (const i in fullShiftListData) {
      const oShift = fullShiftListData[i];
      if (oShift.confirmedVetId !== null && oShift.confirmedVetId !== vetId) {
        continue;
      }

      let requested = false;
      let shiftRequestedOn = "";
      let shiftRequestEndTimestamp = null;
      startTime = "";
      endTime = "";
      if (oShift.shiftRequestDetails !== undefined) {
        const requestShiftDetails = JSON.parse("[" + oShift.shiftRequestDetails + "]")[0];

        requestShiftDetails.map((element) => {
          if (element.vetId === vetId) {
            requested = true;
            shiftRequestedOn = element.shiftRequestedOn;
            shiftRequestEndTimestamp = element.shiftRequestEndTimestamp;
          }
        });
      }
      const oShiftTrimmed = {
        title: oShift.hospitalName,
        shiftTime: oShift.startTime + "-" + oShift.endTime,
        shiftId: oShift.shiftId,
        shiftDate: oShift.shiftDate,
        startTime: oShift.startTime,
        endTime: oShift.endTime,
        shiftStartDatetime: oShift.shiftStartDatetime,
        shiftEndDatetime: oShift.shiftEndDatetime,
        shiftCreatedOn: oShift.shiftCreatedOn,
        start: oShift.shiftStartDatetime * 1000 + new Date().getTimezoneOffset(),
        end: oShift.shiftStartDatetime * 1000 + new Date().getTimezoneOffset(),
        confirmedVetId: oShift.confirmedVetId,
        shiftGroupId: oShift.shiftGroupId,
        shiftRequestedOn: shiftRequestedOn,
        shiftRequestEndTimestamp,
        requested: requested || !!oShift.shiftRequestedOn,
        hospitalId: oShift.hospitalId,
        hospitalTimezone: oShift.hospitalTimezone,
      };
      if (favorites && favorites.length) {
        // if favorites, then give all shifts for the date the same "start" and "end"
        // for the sake of the calendar view so that they are ordered with favorites
        // at the top (already sorted list by date and favorites)
        const aDateParts = oShift.shiftDate.split("-");
        const hr = 60 * 60 * 1000;
        oShiftTrimmed.start =
          new Date(aDateParts[0], aDateParts[1] - 1, aDateParts[2]).getTime() + hr; // generate same bogus start for date
        oShiftTrimmed.end = oShiftTrimmed.start + 2 * hr; // generate same bogus end for date
      }
      trimmedShiftListData.push(oShiftTrimmed);

      shiftRequestedOn = "";
    }
    return {
      ...state,
      shiftListFullData: fullShiftListData,
      shiftListTrimmedData: trimmedShiftListData,
      isShiftRequest: false,
    };
  }

  if (action.type === C.GET_MY_SHIFTS_DETAILS_SUCCESS) {
    const shiftData1 = [];
    const requested = false;
    shiftData1.push({
      ...action.payload,
      hospitalProfileImage: action.payload?.hospitalProfileImage
        ? action.payload?.hospitalProfileImage
        : action.payload?.hospitalImage,
    });
    return {
      ...state,
      shiftData: shiftData1,
      isShiftRequest: false,
    };
  }

  if (action.type === C.REQUEST_VET_SHIFT_SUCCESS) {
    let updateIndex = null;

    const updatedShift = action.payload.data;

    state.shiftData.map((element, index) => {
      if (element.shiftGroupId === updatedShift.shiftGroupId) {
        updateIndex = index;
      }
    });
    const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
    const updateSearchShift = [...state.shiftData];
    updateSearchShift[updateIndex].shiftRequestedOn = currentTimeStamp;

    if (updatedShift.flexStartTimeRange || updatedShift.isFlexibleSurgery) {
      updateSearchShift[updateIndex].requestedVetShiftAmount = updatedShift.requestedVetShiftAmount;
      updateSearchShift[updateIndex].requestedHospitalShiftAmount =
        updatedShift.requestedHospitalShiftAmount;
    }
    if (updatedShift.flexStartTimeRange) {
      updateSearchShift[updateIndex].requestedShiftStartTime = updatedShift.requestedShiftStartTime;
      updateSearchShift[updateIndex].requestedShiftEndTime = updatedShift.requestedShiftEndTime;
      updateSearchShift[updateIndex].isDeductBoostAmount = updatedShift.isDeductBoostAmount;
    }
    if (updatedShift.isFlexibleSurgery) {
      updateSearchShift[updateIndex].isRequestWithoutSurgery = updatedShift.isRequestWithoutSurgery;
    }
    updateSearchShift[updateIndex].shiftRequestEndTimestamp = updatedShift.expiresTimeStamp;

    return {
      ...state,
      shiftData: [...updateSearchShift],
      isShiftRequest: true,
    };
  }

  if (action.type === C.UPDATE_SHIFT_REQUEST_END_TIMESTAMP) {
    const updatedShiftData = action.payload;
    const stateShiftData = [...state.shiftData];
    const shiftToBeUpdated = find(stateShiftData, { shiftGroupId: updatedShiftData.shiftGroupId });
    if (shiftToBeUpdated)
      shiftToBeUpdated.shiftRequestEndTimestamp = updatedShiftData.expiresTimeStamp;
    return {
      ...state,
      shiftData: [...stateShiftData],
    };
  }

  if (action.type === C.REQUEST_VET_SHIFT_NOT_AVAILABLE) {
    return {
      ...state,
      requestedHospOfShiftNotAvailable: action.payload.hospitalName,
      isShowShiftNotAvailableModal: true,
      shiftRequestResponseMessage: action.payload.message,
      requestShiftDetails: [],
      shiftData: [],
      isShiftRequest: false,
    };
  }

  if (action.type === C.CLOSE_SHIFT_NOT_AVAILABLE_MODAL) {
    return {
      ...state,
      isShowShiftNotAvailableModal: false,
      requestedHospOfShiftNotAvailable: "",
      shiftRequestResponseMessage: "",
    };
  }

  if (action.type === C.INITIALIZE_STATE) {
    return {
      ...state,
      shiftData: [],
      requestShiftDetails: [],
    };
  }
  return state;
};

// eslint-disable-next-line no-restricted-exports
export default reducer;
