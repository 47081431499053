import { Icon } from "@RooBeta/types";
import { ReactNode } from "react";
import { create } from "zustand";

export type ToastVariant = "success" | "error" | "info";
export type ToastRegion = "modal" | "default" | "panel";

export const TOAST_DURATION = 3000;

interface ToastState {
  isVisible: boolean;
  variant: ToastVariant;
  icon?: Icon;
  title: string;
  message?: ReactNode;
  priority: number;
  region: ToastRegion;
  duration?: number;
  timeoutId?: any;
  show: (params: ShowToastParams) => void;
  hide: () => void;
}

interface ShowToastParams {
  variant: ToastVariant;
  icon?: Icon;
  title?: string;
  message?: ReactNode;
  priority?: number;
  region?: ToastRegion;
  duration?: number;
}

export const TOAST_PRIORITIES = {
  SHIFT_PREVIEW: 2,
  DEA_LICENSE: 1,
  DEFAULT: 0,
} as const;

export const useToastStore = create<ToastState>((set, get) => ({
  isVisible: false,
  variant: "info",
  icon: undefined,
  title: "",
  message: "",
  priority: TOAST_PRIORITIES.DEFAULT,
  region: "default",
  timeoutId: undefined,

  show: ({
    variant,
    icon,
    title,
    message,
    priority = TOAST_PRIORITIES.DEFAULT,
    region = "default",
    duration,
  }) => {
    const currentPriority = get().priority;
    const currentTimeoutId = get().timeoutId;

    if (priority >= currentPriority) {
      if (currentTimeoutId) {
        clearTimeout(currentTimeoutId);
      }

      set({
        isVisible: true,
        variant,
        icon,
        title,
        message,
        priority,
        region,
        duration,
      });

      if (variant === "success" || duration) {
        const newTimeoutId = setTimeout(() => {
          set({
            isVisible: false,
            timeoutId: undefined,
          });
        }, duration ?? TOAST_DURATION);

        set({
          timeoutId: newTimeoutId,
        });
      }
    }
  },

  hide: () => {
    const currentTimeoutId = get().timeoutId;

    if (currentTimeoutId) {
      clearTimeout(currentTimeoutId);
    }

    set({
      isVisible: false,
      priority: TOAST_PRIORITIES.DEFAULT,
      title: "",
      message: "",
      region: "default",
      timeoutId: undefined,
    });
  },
}));
