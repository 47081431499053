import * as Sentry from "@sentry/react";
import { Cache } from "aws-amplify";

import { ROO_SUPER_USER_TYPE } from "../../constants/UserTypeConstants";
import { AuthUtility } from "../../helpers/AuthUtility";

const METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
} as const;

export const HEADER_KEYS = {
  ACCEPT: "accept",
  ACCESS_TOKEN: "x-access-token",
  CONTENT_TYPE: "content-type",
  OFFSET: "offset",
  IMPERSONATED_USER_ID: "x-impersonated-userid",
  CLIENT_TYPE: "x-client-type",
} as const;

export const CLIENT_TYPE = "web_app";

type FetchMethod = (typeof METHODS)[keyof typeof METHODS];

type FetchHeaders = Record<string, string>;

export class APIError<T> extends Error {
  constructor(message: string, public status: number, public data?: T) {
    super(message);
    this.name = "APIError";
  }
}

type RooFetchRequest<RequestBody> = {
  path: string;
  method?: FetchMethod;
  headers?: FetchHeaders;
  body?: RequestBody;
  suppressErrors?: boolean;
  parseJson?: boolean;
};

type FetchOptions = Parameters<typeof fetch>[1];

/**
 * A wrapper around the native `fetch` function that authenticates the request
 * if the URL is recognized as an API URL.
 */
export const rooFetch = async (path: string, init?: FetchOptions) => {
  const method = init?.method || METHODS.GET;

  if (!isFetchMethod(method)) {
    throw new Error("[rooFetch] Invalid method: must be GET, POST, PATCH, PUT, or DELETE");
  }

  let headers = init?.headers;

  if (typeof headers !== "undefined" && !isFetchHeaders(headers)) {
    throw new Error("[rooFetch] Invalid headers: must be Record<string, string>");
  }

  const body = init?.body;

  // If the path is a relative URL then we assume it's a Roo API URL.
  // API_URL will have a trailing slash, so `path` should not.
  const url = path.startsWith("http") ? path : `${window.RooConfig.API_URL}${path}`;

  // if (method === "GET" || !body) {
  //   console.log(`[rooFetch] ${method} ${url}`);
  // } else {
  //   console.log(`[rooFetch] ${method} ${url}`, body);
  // }

  if (isRooApiUrl(url)) {
    headers = Object.fromEntries(
      Object.entries(headers || {}).map(([key, value]) => [key.toLowerCase(), value])
    );

    if (!headers[HEADER_KEYS.ACCESS_TOKEN]) {
      let token: string | undefined = undefined;
      const session = await AuthUtility.getCurrentSession();

      if (session) {
        token = session.getIdToken().getJwtToken();
      } else if (localStorage.getItem("aws-amplify-cachefederatedInfo")) {
        // This was in the original fetch interceptor, but I'm not sure if it's
        // needed anymore.
        token = Cache.getItem("federatedInfo")?.token;
      }

      if (token) {
        try {
          const userId = localStorage.getItem("userId");

          if (userId) {
            const authContext = AuthUtility.getAuthContextFromToken(token);

            if (authContext.userTypeId === ROO_SUPER_USER_TYPE) {
              headers[HEADER_KEYS.IMPERSONATED_USER_ID] = userId;
            }
          }
        } catch {
          /* We can safely ignore this error. */
        }

        headers[HEADER_KEYS.ACCESS_TOKEN] = `Bearer ${token}`;
      }
    }

    headers[HEADER_KEYS.CLIENT_TYPE] = CLIENT_TYPE;

    if (
      !headers[HEADER_KEYS.CONTENT_TYPE] &&
      !headers[HEADER_KEYS.ACCEPT] &&
      !(body instanceof FormData)
    ) {
      headers[HEADER_KEYS.CONTENT_TYPE] = "application/json";
    }

    if (!headers[HEADER_KEYS.OFFSET]) {
      headers[HEADER_KEYS.OFFSET] = new Date().getTimezoneOffset().toString();
    }

    // For Roo API requests we set some defaults, including
    // `credentials: "include"`, the "content-type" header, and we stringify
    // the body if it's an object.
    init = {
      ...init,
      method,
      headers,
      credentials: "include",
    };
  }

  // eslint-disable-next-line roo/no-restricted-functions
  return fetch(url, init);
};

/**
 * A wrapper around `rooFetch` that assumes the response is JSON and
 * deserializes it as such. It also logs errors to Sentry and honors the
 * `suppressErrors` flag.
 * This is for internal use only, and therefore not exported.
 */
const rooFetchJson = async <RequestBody, ResponseBody>({
  path,
  method = METHODS.GET,
  headers = {},
  body,
  suppressErrors = false,
  parseJson = true,
}: RooFetchRequest<RequestBody>) => {
  let status = 0;

  try {
    const response = await rooFetch(path, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    });

    status = response.status;

    if (status === 204) {
      return null as ResponseBody;
    }

    if (!parseJson) {
      return response as unknown as ResponseBody;
    }

    const data = await response.json();

    if (!response.ok && !suppressErrors) {
      throw new APIError(response.statusText, status, data);
    }

    return data as ResponseBody;
  } catch (error) {
    Sentry.captureException(error, { extra: { path, method, body } });

    if (!suppressErrors) {
      throw new APIError(error.message, status, error.data);
    }

    return null as ResponseBody;
  }
};

export const get = async <ResponseBody>(
  path: string,
  suppressErrors = false,
  headers: Record<string, string> | undefined = undefined
) => rooFetchJson<void, ResponseBody>({ path, method: METHODS.GET, suppressErrors, headers });

export const post = async <RequestBody, ResponseBody>(
  path: string,
  body: RequestBody,
  suppressErrors = false,
  headers: Record<string, string> | undefined = undefined
) =>
  rooFetchJson<RequestBody, ResponseBody>({
    path,
    method: METHODS.POST,
    body,
    suppressErrors,
    headers,
  });

export const put = async <RequestBody, ResponseBody>(
  path: string,
  body: RequestBody,
  suppressErrors = false,
  headers: Record<string, string> | undefined = undefined,
  parseJson = true
) =>
  rooFetchJson<RequestBody, ResponseBody>({
    path,
    method: METHODS.PUT,
    body,
    suppressErrors,
    headers,
    parseJson,
  });

export const patch = async <RequestBody, ResponseBody>(
  path: string,
  body: RequestBody,
  suppressErrors = false,
  headers: Record<string, string> | undefined = undefined
) =>
  rooFetchJson<RequestBody, ResponseBody>({
    path,
    method: METHODS.PATCH,
    body,
    suppressErrors,
    headers,
  });

export const del = async <ReturnType>(
  path: string,
  suppressErrors = false,
  headers: Record<string, string> | undefined = undefined
) =>
  rooFetchJson<ReturnType, void>({
    path,
    method: METHODS.DELETE,
    suppressErrors,
    headers,
  });

export const isRooApiUrl = (url: string) =>
  url.indexOf(window.RooConfig.API_URL) > -1 ||
  url.indexOf(window.RooConfig.FEATURE_FLAG_URL) > -1 ||
  url.indexOf(window.RooConfig.MESSAGING_API_URL) > -1 ||
  url.indexOf(window.RooConfig.MESSAGING_SOCKET_URL) > -1 ||
  url.indexOf(window.RooConfig.NOTIFICATION_SETTINGS_API_URL) > -1 ||
  url.indexOf(window.RooConfig.SMS_OUTREACH_URL) > -1;

function isFetchMethod(method: string): method is FetchMethod {
  return Object.values(METHODS).includes(method as FetchMethod);
}

function isFetchHeaders(obj: unknown): obj is FetchHeaders {
  return (
    typeof obj === "object" &&
    obj !== null &&
    Object.values(obj).every((value) => typeof value === "string")
  );
}
