import { useQuery } from "@tanstack/react-query";

import { get } from "@RooBeta/api";
import { CacheKey, CustomQueryOptions, ShiftDetails } from "@RooBeta/types";

type UseShiftDetailsOptions = CustomQueryOptions<ShiftDetails> & {
  shiftGroupId?: number;
};

export const useShiftDetailsQuery = ({ shiftGroupId, ...options }: UseShiftDetailsOptions) =>
  useQuery({
    queryKey: [CacheKey.ShiftDetails, { shiftGroupId }],
    queryFn: () => get<ShiftDetails>(`api/shifts/${shiftGroupId}`),
    enabled: !!shiftGroupId,
    staleTime: 1000 * 60 * 2,
    retry: false,
    ...options,
  });
