import {
  Heading,
  SmallBodyText,
  grayBackground,
  grayBase,
  grayWhite,
  purpleBase,
  spacingL,
  spacingM,
  spacingS,
  spacingXS,
  spacingXXL,
} from "@RooUI";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { RooIcon, VStack } from "@RooBeta/components";
import { NAVIGATION_HEIGHT } from "@RooBeta/constants";
import { ViewportSize } from "@RooBeta/types";

export const NavigationContainer = styled.div`
  height: ${NAVIGATION_HEIGHT}px;
  background-color: ${grayWhite};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 2px solid ${grayBackground};
  padding: 0 ${spacingXS} 0 ${spacingM};
`;

export const NavigationItems = styled.div<{ $viewport: ViewportSize }>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ $viewport }) => ($viewport === ViewportSize.s ? 0 : spacingS)};
  height: 100%;
`;

export const NavigationItem = styled(VStack)<{ $active?: boolean; $icon?: boolean }>`
  justify-content: ${({ $icon }) => ($icon ? "center" : "flex-end")};
  align-items: center;
  gap: ${spacingXS};
  width: ${({ $icon }) => ($icon ? "auto" : "80px")};
  height: 52px;
  ${SmallBodyText}
  font-weight: 500;
  color: ${({ $active }) => ($active ? purpleBase : grayBase)};
  border-bottom: 2px solid ${({ $active }) => ($active ? purpleBase : "transparent")};
  padding: 0 ${spacingM} ${spacingXS} ${spacingM};
  margin-bottom: -2px;
  cursor: pointer;
`;

export const ListItemStyled = styled.li`
  list-style-type: none;
`;

export const UnorderedListStyled = styled.ul`
  padding-left: 0;
`;

export const LinkStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${grayBase};
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${grayBase};
  }
`;

export const DrawerNavigationItem = styled.div<{ $active?: boolean }>`
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  border-left: ${({ $active }) => ($active ? `4px solid ${purpleBase}` : "none")};
  gap: ${spacingS};
  padding-left: ${spacingXXL};
`;

export const DrawerHeaderGroup = styled(Heading)`
  padding-left: ${spacingXXL};
  padding-top: ${spacingL};
  padding-bottom: ${spacingL};
`;

export const ToggleIcon = styled(RooIcon)<{ $open: boolean }>`
  transform: rotate(${({ $open }) => ($open ? "180deg" : "0deg")});
  transition: transform 0.2s ease;
`;

export const AccountDropdownContainer = styled.div`
  .ant-dropdown-menu-item {
    padding: ${spacingM} ${spacingL} !important;
  }
`;
