import { useQuery } from "@tanstack/react-query";

import { APIResponse, get } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { AvailableDay, CacheKey, CustomQueryOptions } from "@RooBeta/types";

type UseAvailableDaysOptions = CustomQueryOptions<AvailableDay[]>;

export const useAvailableDaysQuery = (options: UseAvailableDaysOptions = {}) => {
  const {
    provider: { providerType, providerId },
  } = useRequiredAuthorizedUser();

  return useQuery({
    queryKey: [CacheKey.AvailableDays],
    queryFn: async () => {
      const { data } = await get<APIResponse<AvailableDay[]>>(
        `api/${providerType.toLowerCase()}/${providerId}/availableDays`
      );

      return data;
    },
    retry: false,
    ...options,
  });
};
