import React from "react";

import { HStack } from "@RooBeta/components";

import { PreviousPrice, Price } from "./styles";

interface ShiftPriceProps {
  price?: string;
  previousPrice?: string | null;
}

export const ShiftPrice = ({ price, previousPrice }: ShiftPriceProps) => (
  <HStack $justifyContent="center" $alignItems="center" $gap="s">
    <div>Total pay:</div>
    {price && <Price>{price}</Price>}
    {previousPrice && <PreviousPrice>{previousPrice}</PreviousPrice>}
  </HStack>
);
