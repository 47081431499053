import { FlexLayout, SmallText, Link } from "@RooUI";
import { useToastStore } from "@RooBeta/store";
import React, { useEffect } from "react";
import { TOAST_PRIORITIES } from "@RooBeta/store/useToastStore";
import { useHistory } from "react-router-dom";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

export const useShiftCTAToasts = ({
  needsDEALicense,
  isStateLicensedRequired,
  hospitalStateCode,
  shiftGroupId,
}: {
  needsDEALicense?: boolean | null;
  isStateLicensedRequired?: boolean | null;
  hospitalStateCode?: string | null;
  shiftGroupId: number | null;
}) => {
  const { show } = useToastStore();
  const {
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  const history = useHistory();
  const path = providerType.toLowerCase();

  useEffect(() => {
    if (needsDEALicense) {
      show({
        message: (
          <FlexLayout gap="s">
            <SmallText>This shift requires a DEA license</SmallText>
            <Link
              linkSize="small"
              onClick={() => {
                history.push(`/${path}/editprofile`);
              }}
            >
              (Update profile)
            </Link>
          </FlexLayout>
        ),
        variant: "info",
        region: "panel",
        priority: TOAST_PRIORITIES.DEA_LICENSE,
      });
    }
  }, [needsDEALicense, shiftGroupId, show, history, path]);

  useEffect(() => {
    if (isStateLicensedRequired) {
      show({
        message: (
          <FlexLayout gap="s">
            <SmallText>This shift requires a {hospitalStateCode ?? "state"} license</SmallText>
            <Link
              linkSize="small"
              onClick={() => {
                history.push(`/${path}/editprofile`);
              }}
            >
              (Update profile)
            </Link>
          </FlexLayout>
        ),
        variant: "info",
        region: "panel",
        priority: TOAST_PRIORITIES.DEA_LICENSE,
      });
    }
  }, [isStateLicensedRequired, hospitalStateCode, shiftGroupId, show, history, path]);
};
