import { useMutation } from "@tanstack/react-query";

import { APIError, put } from "../../api/common/rooFetch";
import { queryClient } from "../../api/reactQueryClient";
import { CACHE } from "../../api/utility/constants";

interface UpdateNotificationSettingsRequest {
  notification_type_code: number;
  email_enabled: number;
  sms_enabled: number;
  push_enabled: number;
}

interface BulkUpdateNotificationSettingsRequest {
  notificationSettingsList: UpdateNotificationSettingsRequest[];
  userId: number;
}

const baseUrl = `${window.RooConfig.NOTIFICATION_SETTINGS_API_URL}/api/notification`;

export const useBulkUpdateNotificationSettings = ({
  userId,
  hospitalId,
  onSuccess,
  onError,
}: {
  userId: string;
  hospitalId?: string;
  onSuccess?: () => void;
  onError?: (error: APIError<{ message: string }>) => void;
}) => {
  return useMutation<unknown, APIError<{ message: string }>, BulkUpdateNotificationSettingsRequest>(
    async (request) => {
      const response = await put(`${baseUrl}/bulkUpdate`, request, false, undefined, false);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE.GET_NOTIFICATION_SETTINGS, userId, hospitalId],
        });
        onSuccess?.();
      },
      onError: (error: APIError<{ message: string }>) => {
        onError?.(error);
      },
    }
  );
};
