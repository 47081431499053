import { z } from "zod";

export const hospitalSignUpSchema = z.object({
  hospitalName: z.string({
    required_error: "Hospital name is required",
  }),
  hospitalFullAddress: z.string({
    required_error: "Hospital address is required",
  }),
  hospitalAddress: z.string({
    required_error: "Hospital address is required",
  }),
  hospitalCity: z.string({
    required_error: "City is required",
  }),
  hospitalState: z.number({
    required_error: "State is required",
    invalid_type_error: "State must be selected",
  }),
  hospitalZipcode: z.string({
    required_error: "Zipcode is required",
  }),
  hospitalPhoneNumber: z.string({
    required_error: "Hospital phone number is required",
  }),
  firstName: z.string({
    required_error: "First name is required",
  }),
  lastName: z.string({
    required_error: "Last name is required",
  }),
  mobilePhoneNumber: z.string({
    required_error: "Mobile phone number is required",
  }),
  emailId: z
    .string({
      required_error: "Email is required",
    })
    .email("Invalid email"),
  password: z.string().min(8, "Password must be at least 8 characters"),
  selfIdentifiedEnterpriseName: z.string().optional().nullable(),
  anticipatedReliefNeed: z.number({
    required_error: "Please select when you anticipate needing relief help",
    invalid_type_error: "Please select when you anticipate needing relief help",
  }),
  onboardingWithRooTeamMember: z.boolean({
    required_error: "Please select if you will be onboarding with a Roo team member at the booth",
  }),
  typeOfHospital: z.number({
    required_error: "Please select the type of hospital",
  }),
  isLookingToHireProvider: z.boolean().optional().nullable(),
});

export type HospitalSignUpValidationSchema = z.infer<typeof hospitalSignUpSchema>;
