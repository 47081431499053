import { useLocation } from "react-router-dom";

export const useActiveRoute = ({ baseRoute }: { baseRoute: string }) => {
  const { pathname } = useLocation();

  const isActive = (route: string) => {
    if (route === baseRoute) {
      return pathname === baseRoute || pathname === `${baseRoute}/`;
    }
    const routeParts = route.split("/").filter(Boolean);
    const currentParts = pathname.split("/").filter(Boolean);

    if (routeParts.length > currentParts.length) {
      return false;
    }

    return routeParts.every((part, index) => part === currentParts[index]);
  };

  return { isActive };
};
