import { MediumText, SmallText, grayLight300 } from "@RooUI";
import styled from "styled-components";

export const Price = styled(MediumText)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreviousPrice = styled(SmallText)`
  text-decoration: line-through;
  color: ${grayLight300};
`;
