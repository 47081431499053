import { Upload } from "antd";
import styled from "styled-components";

import {
  MediumBodyText,
  blueDark600,
  blueLighter,
  blueLightest,
  grayWhite,
  spacingS,
} from "../../styles/constants";

const { Dragger } = Upload;

const UPLOAD_SIZES = {
  m: "175px",
  l: "696px",
} as const;

export type UploadSize = keyof typeof UPLOAD_SIZES;

interface StyledUploadProps {
  $size?: UploadSize;
  $hideContainer?: boolean;
}

export const StyledUpload = styled(Upload)<StyledUploadProps>`
  &.ant-upload-wrapper {
    .ant-upload-select,
    .ant-upload-list-item-container {
      width: 100% !important;
      max-width: ${(props) => UPLOAD_SIZES[props.$size || "m"]} !important;
      height: ${(props) => (props.$size === "l" ? "200px" : "175px")} !important;
      ${(props) =>
        props.$hideContainer &&
        `
        display: none;
      `}
    }
    .ant-upload-select {
      background: ${blueLightest} !important;
      border: 2px solid ${blueLighter} !important;
    }
    .ant-upload-list-item-name {
      color: ${blueDark600} !important;
      position: relative;
      width: 100% !important;
      flex: initial !important;
      margin-top: ${spacingS} !important;
    }
    .ant-upload-list-item {
      padding: 0 !important;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &::before {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .ant-upload-list-item-file {
      height: auto !important;
    }
  }
`;

export const UploadButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-bottom: 0;
  }
`;
export const StyledDragAndDrop = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    background: ${blueLightest};
    border: 2px solid ${blueLighter};
  }
`;
export const TextStyled = styled.p`
  ${MediumBodyText};
  margin: ${spacingS} 0 !important;
`;

export const RequirementsContainer = styled.div`
  width: 70%;
`;
export const PhotoUploadContainer = styled.div`
  position: relative;
  display: flex;
  p {
    margin-bottom: 0;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  svg {
    vertical-align: initial;
  }
  > svg {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 24px);
    opacity: 0;
    transition: opacity 0.3s;
    background: ${grayWhite};
    border-radius: 100%;
    &:hover {
      opacity: 1;
    }
  }
`;

export const DocumentUploadContainer = styled.div`
  margin-top: ${spacingS};
  width: max-content;
  > div {
    display: flex;
  }
`;
