import React from "react";
import IcoMoon, { IconProps } from "react-icomoon";

import { Icon, RooIconSize, rooIconSizes } from "@RooBeta/types";

import iconSet from "./selection.json";

type RooIconProps = Omit<IconProps, "icon"> & {
  icon: Icon;
  size?: RooIconSize | number;
};

export const RooIcon = ({ size = "m", ...props }: RooIconProps) => (
  <IcoMoon
    iconSet={iconSet}
    size={typeof size === "number" ? size : rooIconSizes[size]}
    {...props}
  />
);
