import { ShiftCancellationReason } from "@RooBeta/types";

export const SHIFT_CANCELLATION_REASONS = [
  {
    value: ShiftCancellationReason.Accident,
    label: "Accidentally requested shift",
  },
  {
    value: ShiftCancellationReason.HiredFulltime,
    label: "Hired fulltime",
  },
  {
    value: ShiftCancellationReason.ScheduleChanges,
    label: "Schedule changes",
  },
  {
    value: ShiftCancellationReason.PersonalEmergency,
    label: "Personal emergency",
  },
  {
    value: ShiftCancellationReason.Covid19,
    label: "COVID-19 related",
  },
  {
    value: ShiftCancellationReason.Other,
    label: "Other",
  },
];
