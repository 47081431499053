import * as C from "../../../constants/adminShiftsList";
import * as ConfirmMessage from "../../../constants/confirmMessage";
import * as ErrorMessage from "../../../constants/errorMessage";

export interface ShiftState {
  shiftList: any[];
  shiftLogs: any[];
  calloutList: any[];
  totalCount: number;
  shiftEditedFromAdmin: boolean;
  shiftPriceChanged: boolean | null;
  isPayVetSuccess: boolean | null;
  isPayVetFailed: boolean;
  message: string | null;
  cancelPaymentRequest: boolean | null;
  shiftRequestRemoved: boolean | null;
  shiftConfirmed: boolean | null;
  errorTypeConfirmingShift: string | null;
  vetList: any[];
  techList: any[];
  isOTPaySuccess: boolean;
  isShiftListFetched: boolean | null;
  searchParam: string;
  lastActionContractorShiftGroupId: number;
  isLastActionOnVet: boolean;
  isActionCompleted: boolean;
  prevContractorConfirmed: number;
  currContractorConfirmed: number;
  lastActionShiftAmount: number;
  modalMessage: string;
  selectedShiftRatings: any | null;
  isUpdateRatingsSuccess: boolean;
  updateRatingsErrorMessage: string;
  oShiftBillingByShiftId: Record<string, any>;
  oShiftOTByShiftId: Record<string, any>;
  oShiftBonusByShiftId: Record<string, any>;
  oShiftRTTRByShiftId: Record<string, any>;
  oShiftOtherByShiftId: Record<string, any>;
  oContractorOTByShiftId: Record<string, any>;
  oContractorShiftBonusByShiftId: Record<string, any>;
  oContractorOtherByShiftId: Record<string, any>;
  showFeedbackForm: boolean | null;
  isMostRecentLimit: boolean;
  [key: string]: any;
}

export interface Action {
  type: string;
  payload?: {
    isOvertime?: boolean;
    isShiftBonus?: boolean;
    isRTTR?: boolean;
    isOther?: boolean;
    isVetBid?: boolean;
    data?: any;
    message?: string;
    [key: string]: any;
  };
}

export const initialState: ShiftState = {
  shiftList: [],
  shiftLogs: [],
  calloutList: [],
  totalCount: 0,
  shiftEditedFromAdmin: false,
  shiftPriceChanged: null,
  isPayVetSuccess: null,
  isPayVetFailed: false,
  message: null,
  cancelPaymentRequest: null,
  shiftRequestRemoved: null,
  shiftConfirmed: null,
  errorTypeConfirmingShift: null,
  vetList: [],
  techList: [],
  isOTPaySuccess: false,
  isShiftListFetched: null,
  searchParam: "",
  lastActionContractorShiftGroupId: 0,
  isLastActionOnVet: false,
  isActionCompleted: false,
  prevContractorConfirmed: 0,
  currContractorConfirmed: 0,
  lastActionShiftAmount: 0,
  modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
  selectedShiftRatings: null,
  isUpdateRatingsSuccess: false,
  updateRatingsErrorMessage: "",
  oShiftBillingByShiftId: {},
  oShiftOTByShiftId: {},
  oShiftBonusByShiftId: {},
  oShiftRTTRByShiftId: {},
  oShiftOtherByShiftId: {},
  oContractorOTByShiftId: {},
  oContractorShiftBonusByShiftId: {},
  oContractorOtherByShiftId: {},
  showFeedbackForm: null,
  isMostRecentLimit: true,
};

export const shiftReducer = (state: ShiftState | undefined, action: Action): ShiftState => {
  const currentState = state || initialState;
  const { isOvertime, isShiftBonus, isRTTR, isOther, isVetBid } = action.payload || {};
  let key: string;

  switch (action.type) {
    case C.GET_SHIFT_LIST:
      return {
        ...currentState,
        shiftEditedFromAdmin: false,
        shiftPriceChanged: null,
        isPayVetSuccess: null,
        isPayVetFailed: false,
        message: null,
        cancelPaymentRequest: null,
        shiftConfirmed: null,
        shiftRequestRemoved: null,
        errorTypeConfirmingShift: null,
        modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
        isActionCompleted: false,
        isUpdateRatingsSuccess: false,
        updateRatingsErrorMessage: "",
        isOTPaySuccess: false,
      };
    case C.GET_SHIFT_LIST_SUCCESS:
      if (!action.payload?.isVetShifts && action.payload?.data && action.payload.data.length) {
        action.payload.data.sort(
          (a: any, b: any) => Number(b.isNoReplacement) - Number(a.isNoReplacement)
        );
      }

      return {
        ...currentState,
        shiftList: action.payload?.data || [],
        totalCount: action.payload?.totalCount || 0,
        shiftPriceChanged: null,
        shiftRequestRemoved: null,
        shiftConfirmed: null,
        errorTypeConfirmingShift: null,
      };
    case C.GET_SHIFT_LOGS:
      return {
        ...currentState,
      };
    case C.GET_SHIFT_LOGS_SUCCESS:
      return {
        ...currentState,
        shiftLogs: action.payload?.data || [],
      };
    case C.GET_SHIFT_LOGS_FAIL:
      return {
        ...currentState,
      };
    case C.GET_CALLOUT_LIST_SUCCESS:
      return {
        ...currentState,
        calloutList: action.payload?.data || [],
      };
    case C.INIT_ADMIN_RATINGS:
    case C.GET_ADMIN_RATINGS:
      return {
        ...currentState,
        selectedShiftRatings: null,
        updateRatingsErrorMessage: "",
        isUpdateRatingsSuccess: false,
      };
    case C.GET_ADMIN_RATINGS_SUCCESS:
      return {
        ...currentState,
        selectedShiftRatings: action.payload?.data || null,
      };
    case C.GET_ADMIN_RATINGS_FAIL:
      return {
        ...currentState,
        updateRatingsErrorMessage: action.payload?.message || "",
      };

    case C.GET_SHIFT_BILLING_INFO_SUCCESS:
      let billingInfo = { ...currentState.oShiftBillingByShiftId };
      if (action.payload?.data) {
        billingInfo = {
          ...billingInfo,
          [action.payload.data[0].shiftId]: action.payload.data,
        };
      }
      return {
        ...currentState,
        oShiftBillingByShiftId: billingInfo,
      };

    case C.GET_SHIFT_ADDITIONAL_PAY_INFO_SUCCESS:
      if (isOvertime) {
        key = "oShiftOTByShiftId";
      } else if (isShiftBonus) {
        key = "oShiftBonusByShiftId";
      } else if (isRTTR) {
        key = "oShiftRTTRByShiftId";
      } else if (isOther) {
        key = "oShiftOtherByShiftId";
      } else {
        return currentState;
      }
      return {
        ...currentState,
        [key]: {
          ...currentState[key],
          [action.payload?.data[0].shiftId]: action.payload?.data,
        },
      };

    case C.GET_CONTRACTOR_ADDITIONAL_PAY_SUCCESS:
      if (isOvertime) {
        key = "oContractorOTByShiftId";
      } else if (isShiftBonus) {
        key = "oContractorShiftBonusByShiftId";
      } else if (isOther) {
        key = "oContractorOtherByShiftId";
      } else {
        key = "oContractorBillingByShiftId";
      }

      return {
        ...currentState,
        [key]: {
          ...currentState[key],
          [action.payload?.data.shiftId]: action.payload?.data,
        },
      };

    case C.ADMIN_UPDATE_RATINGS:
      return {
        ...currentState,
        selectedShiftRatings: null,
      };
    case C.ADMIN_UPDATE_RATINGS_SUCCESS:
      return {
        ...currentState,
        isUpdateRatingsSuccess: true,
      };
    case C.ADMIN_UPDATE_RATINGS_FAIL:
      return {
        ...currentState,
        updateRatingsErrorMessage: action.payload?.message || "",
      };
    case C.ADMIN_EDIT_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : isVetBid
          ? ConfirmMessage.EDIT_VET_BID_SHIFT_CONFIRM
          : ConfirmMessage.EDIT_SHIFT_CONFIRM,
      };
    case C.ADMIN_EDIT_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
      };
    case C.ADMIN_EDIT_SHIFT_PRICE_SUCCESS:
      return {
        ...currentState,
        shiftPriceChanged: true,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : ConfirmMessage.ADMIN_SHIFT_PRICE_UPDATE_SUCCESS,
      };
    case C.ADMIN_EDIT_SHIFT_PRICE_FAIL:
      return {
        ...currentState,
        shiftPriceChanged: false,
      };
    case C.ADMIN_ADD_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADD_SHIFT_CONFIRM,
      };
    case C.ADMIN_ADD_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: action.payload?.message || "",
      };
    case C.PAY_TO_VET_SUCCESS:
      return {
        ...currentState,
        isPayVetSuccess: true,
      };
    case C.PAY_TO_VET_FAIL:
      return {
        ...currentState,
        isPayVetFailed: true,
        message: action.payload?.message || null,
      };
    case C.CANCEL_PAYMENT_SUCCESS:
      return {
        ...currentState,
        cancelPaymentRequest: true,
      };
    case C.CANCEL_PAYMENT_FAIL:
      return {
        ...currentState,
        cancelPaymentRequest: false,
        message: action.payload?.message || null,
      };
    case C.ADMIN_REMOVE_SHIFT_REQUEST:
      return {
        ...currentState,
        lastActionContractorShiftGroupId: action.payload?.shiftGroupId || 0,
        isLastActionOnVet: action.payload?.vets || false,
        lastActionShiftAmount: action.payload?.shiftAmount || 0,
        lastShiftAction: "removeRequestedContractor",
      };
    case C.ADMIN_REMOVE_SHIFT_REQUEST_SUCCESS:
      return {
        ...currentState,
        shiftRequestRemoved: true,
      };

    case C.ADMIN_REMOVE_SHIFT_REQUEST_FAIL:
      return {
        ...currentState,
        shiftRequestRemoved: false,
      };
    case C.ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS:
      return {
        ...currentState,
        shiftConfirmed: true,
        errorTypeConfirmingShift: null,
      };
    case C.ADMIN_CONFIRM_SHIFT_REQUEST_FAIL:
      return {
        ...currentState,
        shiftConfirmed: false,
        errorTypeConfirmingShift: action.payload?.message || null,
      };
    case C.ADMIN_GET_ALL_VETS_SUCCESS:
      return {
        ...currentState,
        vetList: action.payload?.vetList || [],
        techList: [],
      };
    case C.SET_LAST_SHIFT_ACTION_DATA:
      return {
        ...currentState,
        showFeedbackForm: true,
        lastActionContractorShiftGroupId: action.payload?.shiftGroupId || 0,
        isLastActionOnVet: action.payload?.isVetShift || false,
        prevContractorConfirmed:
          action.payload?.shiftAction === "switchContractor"
            ? action.payload.oldContractorId
            : action.payload?.contractorId || 0,
        currContractorConfirmed:
          action.payload?.shiftAction === "switchContractor" ? action.payload.contractorId : null,
        lastActionShiftAmount: action.payload?.shiftAmount || 0,
        lastActionShiftDate: action.payload?.shiftDate || null,
        lastActionHospitalId: action.payload?.hospitalId || 0,
        lastShiftActionId: action.payload?.shiftId || 0,
        lastActionShiftEndtime: action.payload?.shiftEndDatetime || null,
        lastShiftAction: action.payload?.shiftAction || null,
      };
    case C.RESET_LAST_SHIFT_ACTION_DATA:
      return {
        ...currentState,
        showFeedbackForm: false,
        lastActionContractorShiftGroupId: 0,
        isLastActionOnVet: false,
        prevContractorConfirmed: 0,
        currContractorConfirmed: 0,
        lastActionShiftAmount: 0,
        lastActionHospitalId: 0,
        lastShiftActionId: 0,
        lastActionShiftEndtime: null,
        lastShiftAction: null,
      };
    case C.ADMIN_REQUEST_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_VET_SHIFT_SUCCESS,
      };
    case C.ADMIN_REQUEST_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage:
          ErrorMessage[action.payload?.message as keyof typeof ErrorMessage] ||
          action.payload?.message ||
          "",
      };
    case C.ADMIN_GET_ALL_TECHS_SUCCESS:
      return {
        ...currentState,
        techList: action.payload?.techList || [],
        vetList: [],
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_VET_SHIFT_SUCCESS,
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ErrorMessage[action.payload?.message as keyof typeof ErrorMessage] || "",
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_SHIFT_OVERLAPS,
      };
    case C.ADMIN_OVERTIME_ALREADY_EXISTS:
      return {
        ...currentState,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_OVERTIME_ALREADY_EXISTS,
      };
    case C.ADMIN_VET_ADDITIONAL_PAYOUT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_PAYMENT_TO_VET_SUCCESS,
      };
    case C.ADMIN_VET_ADDITIONAL_PAYOUT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
        isOTPaySuccess: false,
        modalMessage: action.payload?.message || "",
      };

    case C.ADMIN_TECH_ADDITIONAL_PAYOUT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_PAYMENT_TO_VET_SUCCESS,
      };
    case C.ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
        isOTPaySuccess: false,
        modalMessage: action.payload?.message || "",
      };

    case C.ADMIN_REQUEST_AND_CONFIRM_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_SHIFT_PROCESSED,
      };
    case C.ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
      };
    case C.ADMIN_EDIT_TECH_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : ConfirmMessage.EDIT_SHIFT_CONFIRM,
      };
    case C.ADMIN_EDIT_TECH_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
      };
    case C.ADMIN_ADD_TECH_SHIFT_SUCCESS:
      return {
        ...currentState,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADD_SHIFT_CONFIRM,
      };
    case C.ADMIN_ADD_TECH_SHIFT_FAIL:
      return {
        ...currentState,
        isActionCompleted: true,
      };
    case C.INITIALIZE_ADMIN_STATE:
      return {
        ...currentState,
        lastActionContractorShiftGroupId: 0,
        isLastActionOnVet: false,
        prevContractorConfirmed: 0,
        currContractorConfirmed: 0,
        lastActionShiftAmount: 0,
      };
    default:
      return currentState;
  }
};
