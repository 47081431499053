import moment from "moment";
import React from "react";

import { useShiftDetailsStore } from "@RooBeta/store";

import { ConfirmedShiftCTA } from "./ConfirmedShiftCTA";
import { RequestedShiftCTA } from "./RequestedShiftCTA";
import { UnfilledShiftCTA } from "./UnfilledShiftCTA";

export const ShiftCTA = () => {
  const { shiftDetails } = useShiftDetailsStore();
  const { shiftStatus, shiftDate } = shiftDetails || {};
  const now = moment();
  const isShiftInPast = shiftDate ? moment(shiftDate).isBefore(now, "day") : false;

  return (
    <>
      {shiftStatus === "REQUESTED" ? (
        <RequestedShiftCTA />
      ) : shiftStatus === "UNFILLED" ? (
        <UnfilledShiftCTA />
      ) : shiftStatus === "CONFIRMED" && !isShiftInPast ? (
        <ConfirmedShiftCTA />
      ) : null}
    </>
  );
};
