import {
  BodyText,
  FlexLayout,
  Heading,
  Icon,
  SmallBodyText,
  Toggle,
  grayBackground,
  grayLightest,
  mediumMediaBreakpoint,
  purpleBase,
  spacingL,
  spacingS,
  spacingXL,
  spacingXS,
} from "@RooUI";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useBulkUpdateNotificationSettings } from "@Roo/hooks/api/useBulkUpdateNotificationSettings";
import { RootState } from "@Roo/store";

import { ToastContext } from "../../../Common/Toasts/context/ToastContext";
import { NotificationCategory as NotificationCategoryType } from "../../../constants/notificationConstants";

import { NotificationSettingRow } from "./NotificationSettingRow";

const NOTIFICATION_CHANNELS = [
  { icon: "MailOutline", label: "Email", key: "email_enabled" },
  { icon: "OutlineSms", label: "SMS", key: "sms_enabled" },
  { icon: "AdUnits", label: "Push", key: "push_enabled" },
] as const;

interface NotificationCategoryProps {
  notificationCategory: NotificationCategoryType;
}

const Container = styled.div`
  width: 100%;
`;

const Header = styled(FlexLayout)`
  padding: ${spacingL};
  gap: ${spacingXS};
  flex-direction: column;
  background-color: ${grayLightest};
`;

const ChannelHeader = styled(FlexLayout)`
  flex-direction: row;
  padding: ${spacingS} ${spacingXL} ${spacingS} ${spacingS};
  justify-content: space-between;
  justify-items: center;
  border-bottom: 1px solid ${grayBackground};
`;

const ChannelContainer = styled(FlexLayout)`
  max-width: 348px;
  width: 100%;
  flex-direction: row;
`;

const ChannelColumn = styled(FlexLayout)`
  width: 100%;
  justify-items: center;
`;

const ChannelContent = styled(FlexLayout)`
  flex-direction: column;
  height: 52px;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${spacingXS};
`;

const CategoryLabel = styled.div`
  ${BodyText}
  @media (max-width: ${mediumMediaBreakpoint}) {
    ${SmallBodyText}
  }
`;

const SmallBody = styled.div`
  ${SmallBodyText}
`;

const Spacer = styled.div`
  width: 100%;
  min-width: 151px;
  max-width: 623px;
`;

export function NotificationCategory({ notificationCategory }: NotificationCategoryProps) {
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { userId } = useSelector((state: RootState) => state.login);
  const { mutate: updateSettings } = useBulkUpdateNotificationSettings({
    userId,
    onSuccess: () => {
      showSuccessToast({
        description: "Notification settings were successfully updated.",
        message: "Settings updated",
      });
    },
    onError: (error) => {
      showErrorToast({
        description: error?.message || "Error updating notification settings",
      });
    },
  });
  const [settings, setSettings] = useState(notificationCategory.notificationSettings ?? []);

  const isAnyEnabled = settings.some((setting) => setting.email || setting.sms || setting.push);

  const handleToggleAll = (newValue: boolean) => {
    const updatedSettings = settings.map((setting) => ({
      ...setting,
      email: newValue,
      sms: newValue,
      push: newValue,
    }));

    setSettings(updatedSettings);

    updateSettings({
      notificationSettingsList: updatedSettings.map((setting) => ({
        notification_type_code: setting.notiticationTypeId,
        email_enabled: newValue ? 1 : 0,
        sms_enabled: newValue ? 1 : 0,
        push_enabled: newValue ? 1 : 0,
      })),
      userId: Number(userId),
    });
  };

  useEffect(() => {
    setSettings(notificationCategory.notificationSettings ?? []);
  }, [notificationCategory]);

  return (
    <Container>
      <Header>
        <FlexLayout
          style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        >
          <FlexLayout
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Heading level={4} color={purpleBase}>
              {notificationCategory.title}
            </Heading>
            <CategoryLabel>{notificationCategory.label}</CategoryLabel>
          </FlexLayout>

          {notificationCategory.toggleAllSettings && (
            <Toggle
              checked={isAnyEnabled}
              onChange={() => handleToggleAll(!isAnyEnabled)}
              data-testid={`notifications${notificationCategory.title}ToggleAll`}
            />
          )}
        </FlexLayout>
      </Header>

      <ChannelHeader>
        <Spacer />
        <ChannelContainer>
          {NOTIFICATION_CHANNELS.map(({ icon, label }) => (
            <ChannelColumn key={label}>
              <ChannelContent>
                <Icon
                  name={icon}
                  data-testid={`notifications${notificationCategory.title}${icon}Icon`}
                />
                <SmallBody>{label}</SmallBody>
              </ChannelContent>
            </ChannelColumn>
          ))}
        </ChannelContainer>
      </ChannelHeader>

      {settings.map((setting) => (
        <NotificationSettingRow key={setting.label} notificationSetting={setting} />
      ))}
    </Container>
  );
}
