import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { RadioGroupProps } from "@RooUI";

import { UserTypeRadioGroup } from "../Form/UserTypeRadioGroup";

import { ControlledComponentProps } from "./types";

interface ControlledUserTypeRadioGroupProps<T extends FieldValues>
  extends Omit<RadioGroupProps, "value" | "name" | "defaultValue" | "radios">,
    ControlledComponentProps<T> {}

export const ControlledUserTypeRadioGroup = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  onChange,
  ...radioGroupProps
}: ControlledUserTypeRadioGroupProps<T>) => (
  <Controller<T>
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <UserTypeRadioGroup
        name={field.name}
        value={field.value}
        onChange={(value) => {
          field.onChange(value);
          onChange(value);
        }}
        {...radioGroupProps}
      />
    )}
  />
);
